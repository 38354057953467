import styled from "styled-components";

export const FormFieldsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    .text-field-input { 
        margin-bottom: 15px;
    }
`;

export const FieldsGroup = styled.div`
    display: flex;
    grid-gap: 15px;
    align-items: baseline;
`;

export const ButtonsContainer = styled(FieldsGroup)`
    justify-content: flex-end;
    margin-top: 10px;
`;

export const LoadingContainer = styled.div`
    text-align: center;
    padding: 1px;
`;

export const AlertContainer = styled.div`
    text-align: center;
    padding: 22px 24px;
    align-items: center;
    align-content: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    .icon {
        span {
            font-size: 3rem;
            color: ${(props) => props.theme.palette.text};
        }
    }
    h2 {
        margin: 0px;
        margin-top: 2rem;
    }
    p {
        margin: 0px;
    }
    button {
        margin-top: 2rem;
    }
`;

export const SuccessContainer = styled(AlertContainer)`
    .icon {
        span {
            font-size: 3rem;
            color: ${(props) => props.theme.palette.primary};
        }
    }
`;
export const ErrorContainer = styled(AlertContainer)`
    .icon {
        span {
            font-size: 3rem;
            color: #f44336;
        }
    }
`;
