import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { calculateTotal, insertProductInOrderList } from "../../components/CreateSaleForm/helpers";
import CustomerLoginForm from "../../components/CustomerLoginForm";
import { IProductOption } from "../../components/ProductCard";
import ProductSearchList from "../../components/ProductSearchList";
import { CurrentCustomerContext } from "../../store/contexts/CurrentCustomerContext";
import { CurrentProductCatalogContext } from "../../store/contexts/CurrentProductCatalogContext";
import { CurrentSaleContext } from "../../store/contexts/CurrentSaleContext";
import { LoginContainer, SubContainer } from "./styled";

interface IParamCatalog {
    catalogId: string;
}

export default function Home() {
    const { currentCustomerState, currentCustomerActions } = useContext(CurrentCustomerContext);
    const { currentSaleState, currentSaleActions } = useContext(CurrentSaleContext);
    const { currentProductCatalogState } = useContext(CurrentProductCatalogContext);

    const { catalogId } = useParams<IParamCatalog>();

    useEffect(() => {
        currentCustomerActions.update({ ...currentCustomerState, catalogId });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [catalogId]);

    if (!currentProductCatalogState.productCatalog) {
        return (
            <LoginContainer>
                <SubContainer>
                    <h1>
                        <span className="wahioicon-lock"></span> No hay catálogos para mostrar
                    </h1>
                    <p>No se puede mostrar la página porque no se ha seleccionado ningún catálogo.</p>
                    <hr />
                    <p>Es posible que el enlace no sea correcto o esté roto.</p>
                </SubContainer>
            </LoginContainer>
        );
    }

    if (!currentProductCatalogState.productCatalog.allowPublicAccess && !currentCustomerState.customer) {
        return (
            <LoginContainer>
                <SubContainer>
                    <h2>
                        <span className="wahioicon-swatchbook"></span> {currentProductCatalogState.productCatalog?.name}
                    </h2>
                    <hr />
                    <p>
                        Para ingresar al catálogo y cargar las ordenes debes estar registrado como cliente en{" "}
                        {currentCustomerState.account?.company?.name}
                    </p>
                    <h2>Ingresa correo y número de identificación</h2>
                    <CustomerLoginForm />
                </SubContainer>
            </LoginContainer>
        );
    }

    const onClickProduct = (value: IProductOption) => {
        const { productCatalog } = currentProductCatalogState;
        const { currentSale } = currentSaleState;
        const defaultPrice = productCatalog ? productCatalog.price : "price";
        let items = insertProductInOrderList({
            items: currentSale.items,
            value: value.product,
            defaultPrice: defaultPrice,
            warehouse: value.warehouse,
        });

        const totalAmountItems = calculateTotal(items);
        const percentageDiscount = currentSale.percentageDiscount ?? 0;
        const totalDiscount = totalAmountItems * (percentageDiscount / 100);
        currentSaleActions.update({
            ...currentSale,
            items,
            totalAmountItems,
            percentageDiscount,
            totalDiscount,
        });
    };

    return <ProductSearchList onClickViewProduct={() => {}} onClickAddProduct={onClickProduct} />;
}
