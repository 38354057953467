import { IOrganization } from "./organizationTypes";
import { ProductPriceTypes, ProductSortOption } from "./product";
import { IProductCategory } from "./productCategory";
import { IWarehouse } from "./warehouse";

export const ADD_PRODUCT_CATALOG = "ADD_PRODUCT_CATALOG";
export const DELETE_PRODUCT_CATALOG = "DELETE_PRODUCT_CATALOG";
export const UPDATE_PRODUCT_CATALOG = "UPDATE_PRODUCT_CATALOG";
export const REQUEST_PRODUCT_CATALOGS = "REQUEST_PRODUCT_CATALOGS";
export const RECEIVE_PRODUCT_CATALOGS = "RECEIVE_PRODUCT_CATALOGS";
export const INVALID_REQUEST_PRODUCT_CATALOGS = "INVALID_REQUEST_PRODUCT_CATALOGS";
export const UPDATE_CURRENT_PRODUCT_CATALOG = "UPDATE_CURRENT_PRODUCT_CATALOG";

export interface IProductCatalog {
    id?: string;
    accountId: string;
    showStock?: boolean;
    showOutOfStock?: boolean;
    showPrice?: boolean;
    warehouseId: string;
    warehouse?: IWarehouse;
    price: ProductPriceTypes;
    dateCreated?: Date;
    accountUserId?: string;
    allowOrderWithWhatsapp: boolean;
    phoneNumber: string;
    isRestaurant: boolean;
    allowPublicAccess: boolean;
    organizationId?: string;
    organization?: IOrganization;
    name: string;
    customCategoryIds?: string[];
    customCategories?: IProductCategory[];
    minPrice: number;
    maxPrice: number;
    onlyProductWithImage: boolean;
    onlyProductWithoutImage: boolean;
    sortDesc: boolean;
    sort: ProductSortOption;
}

export interface IProductCatalogState {
    items: IProductCatalog[];
    error: any;
    isFetching: boolean;
    isError: boolean;
}

export interface ICurrentProductCatalogState {
    productCatalog?: IProductCatalog;
}

export interface RequestProductCatalogs {
    type: typeof REQUEST_PRODUCT_CATALOGS;
}
export interface ReceiveProductCatalogs {
    type: typeof RECEIVE_PRODUCT_CATALOGS;
    items: Array<IProductCatalog>;
}
export interface InvalidRequest {
    type: typeof INVALID_REQUEST_PRODUCT_CATALOGS;
    value: any;
}
export interface UpdateProductCatalog {
    type: typeof UPDATE_PRODUCT_CATALOG;
    value: IProductCatalog;
}

export interface AddProductCatalog {
    type: typeof ADD_PRODUCT_CATALOG;
    value: IProductCatalog;
}

interface DeleteProductCatalog {
    type: typeof DELETE_PRODUCT_CATALOG;
    id: string;
}

export interface IUpdateCurrentProductCatalog {
    type: typeof UPDATE_CURRENT_PRODUCT_CATALOG;
    value: IProductCatalog;
}

export type ProductCatalogTypes =
    | RequestProductCatalogs
    | ReceiveProductCatalogs
    | InvalidRequest
    | UpdateProductCatalog
    | AddProductCatalog
    | DeleteProductCatalog
    | IUpdateCurrentProductCatalog;
