import React, { useContext, useEffect, useState } from "react";
import AppHorizontalMenu from "./AppHorizontalMenu";
import { AppSubContainer, AppChildrenContainer } from "./styled";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import { AppSettingsContext } from "../../appSettings/AppSettingsContext";
import { CurrentCustomerContext } from "../../store/contexts/CurrentCustomerContext";
import { CurrentSaleContext } from "../../store/contexts/CurrentSaleContext";
import { CurrentProductCatalogContext } from "../../store/contexts/CurrentProductCatalogContext";
import { wahioFetch } from "../../services";
import { accountEndpoint, localorderEndpoint, productEndpoint } from "../../services/restApiEndpoint";
import LoadingDualRing from "../../components/LoadingDualRing";
import { IProductCatalog } from "../../store/types/productCatalog";
import { IAccount } from "../../store/types/accountUser";
import { ICustomer } from "../../store/types/customer";
import ReactGA from "react-ga";
import { IOrganization } from "../../store/types/organizationTypes";

ReactGA.initialize("UA-212804509-2");

interface AppProps extends RouteComponentProps {
    children: React.ReactNode;
}

interface ICatalogFetchResult {
    isFetching: boolean;
    notFound?: boolean;
    catalog?: IProductCatalog;
}
function AppContainer(props: AppProps) {
    const [menuOpen, setMenuOpen] = useState(false);

    const history = useHistory();
    const changeMenuStatus = (status: boolean) => {
        setMenuOpen(status);
    };

    const { appSettingsState } = useContext(AppSettingsContext);
    const { currentCustomerState, currentCustomerActions } = useContext(CurrentCustomerContext);
    const { currentSaleState, currentSaleActions } = useContext(CurrentSaleContext);
    const { currentProductCatalogState, currentProductCatalogActions } = useContext(CurrentProductCatalogContext);
    const [catalogFetchResult, setCatalogFetchResult] = useState<ICatalogFetchResult>({
        isFetching: false,
    });

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        history.listen((location, action) => {
            ReactGA.set({ page: location.pathname });
            ReactGA.pageview(location.pathname);
            //ReactGA.pageview(location.pathname);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const { productCatalog } = currentProductCatalogState;
        if (productCatalog && productCatalog.id === currentCustomerState.catalogId) return;
        if (currentCustomerState.catalogId) {
            fetchCatalog(currentCustomerState.catalogId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCustomerState.catalogId]);

    useEffect(() => {
        if (currentCustomerState.catalogId) return;
        const { productCatalog } = currentProductCatalogState;
        if (productCatalog && productCatalog.id) {
            fetchCatalog(productCatalog.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCustomer = () => {
        if (currentCustomerState.customer?.id) {
            wahioFetch.get(
                localorderEndpoint.get.customersById(currentCustomerState.customer.id ?? ""),
                (resultAccount) => {
                    const customer: ICustomer = resultAccount.data;
                    currentCustomerActions.update({ ...currentCustomerState, customer });
                },
                (error) => {}
            );
        }
    };

    const fetchCatalog = (catalogId: string) => {
        setCatalogFetchResult({ ...catalogFetchResult, isFetching: true });
        wahioFetch.get(
            productEndpoint.get.productCatalogById(catalogId),
            (result) => {
                const catalog: IProductCatalog = result.data;
                if (currentProductCatalogState.productCatalog && currentProductCatalogState.productCatalog.id !== catalogId) {
                    currentSaleActions.update({ ...currentSaleState.currentSale, items: [] });
                }

                if (catalog.organizationId) {
                    wahioFetch.get(
                        accountEndpoint.get.organizationsById(catalog.organizationId),
                        (resultAccount) => {
                            const organization: IOrganization = resultAccount.data;
                            const { account } = organization;
                            catalog.organization = organization;
                            currentProductCatalogActions.update(catalog);
                            currentCustomerActions.update({ ...currentCustomerState, account });
                            setCatalogFetchResult({ ...catalogFetchResult, isFetching: false, notFound: false });
                            getCustomer();
                        },
                        (error) => {
                            setCatalogFetchResult({ ...catalogFetchResult, isFetching: false, notFound: true });
                        }
                    );
                } else {
                    wahioFetch.get(
                        accountEndpoint.get.accountById(catalog.accountId),
                        (resultAccount) => {
                            const account: IAccount = resultAccount.data;
                            currentProductCatalogActions.update(catalog);
                            currentCustomerActions.update({ ...currentCustomerState, account });
                            setCatalogFetchResult({ ...catalogFetchResult, isFetching: false, notFound: false });
                            getCustomer();
                        },
                        (error) => {
                            setCatalogFetchResult({ ...catalogFetchResult, isFetching: false, notFound: true });
                        }
                    );
                }
            },
            (error) => {
                setCatalogFetchResult({ ...catalogFetchResult, isFetching: false, notFound: true });
            }
        );
    };

    if (catalogFetchResult.isFetching) {
        return (
            <div>
                <LoadingDualRing center={true} />
            </div>
        );
    }

    return (
        <div>
            <AppHorizontalMenu menuOpen={menuOpen} onClickOpenMenu={changeMenuStatus} />
            <AppSubContainer>
                <AppChildrenContainer className={appSettingsState.menuSmall ? "small-menu" : ""}>{props.children}</AppChildrenContainer>
            </AppSubContainer>
        </div>
    );
}

export default withRouter(AppContainer);
