import styled from "styled-components";
import { TableCustom } from "../controls/tables/styled";
import { breakpoint } from "../../theme/themeProvider";

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 900px;
    width: 100%;
    margin: auto;
    padding: 20px;
    box-sizing: border-box;
`;

export const CloseContainer = styled.div`
    position: absolute;
    right: 8px;
    top: 9px;
    z-index: 1;
`;

export const SubContainer = styled.div`
    position: relative;
    display: flex;
    .display-block {
        display: block;
    }
    .display-none {
        display: none;
    }
`;
interface IMobileProps {
    isMobile: boolean;
}
export interface IHideProps extends IMobileProps {
    hide: boolean;
}
export const ProductListContainer = styled.div`
    ${(props: IHideProps) => (props.isMobile ? "" : " max-width: 333px;")}
    width: 100%;
    border-right: 1px solid transparent;

    ${(props: IHideProps) => (props.hide ? "display: none;" : "")}
    ${breakpoint("xl")`
    max-width: 400px;
    `}
`;

export const OrderContainerParent = styled.div`
    box-sizing: border-box;
    padding-top: 15px;
    padding-top: 0px;
    width: 100%;
`;

export const OrderContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 1rem;
    border: 0px;
    border-radius: 0px;

    position: relative;

    box-sizing: border-box;
    height: auto;
    width: 100%;
    max-height: calc(100vh - 100px);
`;

export const FlexContainer = styled.div`
    display: flex;
`;

export const TopView = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    box-sizing: border-box;
    flex-wrap: wrap;

    .space {
        margin-right: 15px;
    }
    button {
        border-radius: 50px;
    }
`;

export const TopLabelContainer = styled.div`
    display: flex;
    align-items: center;
    box-sizing: border-box;
    grid-gap: 10px;
    flex-wrap: wrap;
`;

export const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0rem 0.5rem;
    margin-right: 8px;
    .label-title {
        font-size: 0.9rem;
        color: ${(props) => props.theme.palette.textLight};
    }
`;

export const TableContainer = styled.div`
    padding: 10px;
    padding-top: 0px;
    width: 100%;
    box-sizing: border-box;
`;

export const CardsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
`;

export const SummaryContainer = styled.div`
    display: flex;
    width: 100%;
`;

export const ContainerLoading = styled.div`
    margin: 3rem;
    text-align: center;
`;

export const DetailsContainer = styled.div`
    padding: 20px 0px;
`;

export const DetailsBody = styled.div`
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    border-radius: 8px;
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px 0px;
    margin-bottom: 5px;
    p {
        margin: 0px;
    }
    .light {
        color: ${(props) => props.theme.palette.textLight};
    }
    .content {
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const RowContainer = styled.div`
    display: flex;
    align-items: center;
    .space {
        margin-right: 10px;
    }
    .light {
        font-size: 0.9rem;
        color: ${(props) => props.theme.palette.textLight};
    }
    .small {
        width: 25px;
        height: 25px;
        min-width: 25px;
    }
`;

export const DeliveryOptionContainer = styled(RowContainer)`
    align-items: center;
    .separator {
        display: none;
        width: 1px;
        height: 25px;
        background: ${(props) => props.theme.palette.backgroundDark};
    }

    justify-content: space-between;

    ${breakpoint("md")`
        .separator { 
            display: block;
        }
        align-items: center;
        flex-direction: row;
    `}
`;

export const SellerDetailContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CostContainer = styled.div`
    input {
        border-radius: 40px;
        padding: 2px 9px;
        margin-top: 2px;
        font-weight: 600;
    }
`;

export const EmptyItemsMessage = styled.div`
    padding-left: 2rem;
    padding-bottom: 1rem;
    color: ${(props) => props.theme.palette.textLight};
`;

export const DraftContainer = styled.div`
    .number {
        color: ${(props) => props.theme.palette.primary};
    }
`;

export const DraftSubcontainer = styled.div`
    background: ${(props) => props.theme.palette.backgroundLight};
    border: 0px solid ${(props) => props.theme.palette.backgroundDark};
    padding: 1rem;

    border-radius: 7px;
`;

export const TableCustomCustomer = styled(TableCustom)`
    min-width: 690px;
`;

export const EditHeaderContainer = styled.div`
    border-bottom: 1px solid ${(props) => props.theme.palette.backgroundDark};
    padding: 10px 27px;
    h1,
    h2,
    h3 {
        margin: 0;
    }
`;
