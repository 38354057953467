import { getStoredData, StoreKey } from "..";
import { IAccount } from "../types/accountUser";

import { ICurrentCustomerState, ICustomer, CustomerTypes, UPDATE_CURRENT_CUSTOMER } from "../types/customer";

export class InitStateBase implements ICurrentCustomerState {
    customer?: ICustomer = undefined;
    account?: IAccount = undefined;
}

export const initState = getStoredData<ICurrentCustomerState>(new InitStateBase(), StoreKey.currentCustomer);

export const currentCustomerReducer = (state: ICurrentCustomerState, action: CustomerTypes): ICurrentCustomerState => {
    switch (action.type) {
        case UPDATE_CURRENT_CUSTOMER:
            return action.value;

        default:
            return state;
    }
};
