import React, { useContext } from "react";
import { SquareButton } from "../controls";
import { AppSettingsContext } from "../../appSettings/AppSettingsContext";
import { MainContainerSettings, ElementItem, ElementTitle } from "./styled";
import { CurrentCustomerContext } from "../../store/contexts/CurrentCustomerContext";

export default function ProfileSettingsMenu() {
    const { appSettingsState, appSettingsActions } = useContext(AppSettingsContext);
    const { currentCustomerState, currentCustomerActions } = useContext(CurrentCustomerContext);
    const { customer } = currentCustomerState;

    const clearCustomer = () => {
        currentCustomerActions.update({ ...currentCustomerState, customer: undefined });
    };

    return (
        <MainContainerSettings>
            {customer && (
                <ElementTitle>
                    <span className="title">
                        {customer.firstName} {customer.lastName}
                    </span>
                    <span className="sub">{customer.email}</span>
                </ElementTitle>
            )}
            <ElementItem onClick={() => appSettingsActions.changeTheme()}>
                <SquareButton className="icon">
                    <span className={appSettingsState.theme === "DARK" ? "wahioicon-sun" : "wahioicon-moon"}></span>
                </SquareButton>
                <span>Cambiar tema</span>
            </ElementItem>
            <ElementItem onClick={clearCustomer}>
                <SquareButton className="icon">
                    <span className="wahioicon-sign-out-alt"></span>
                </SquareButton>
                <span>Cerrar sesión</span>
            </ElementItem>
        </MainContainerSettings>
    );
}
