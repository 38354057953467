import styled from "styled-components";

export const ListAddressContainer = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 7px;
    margin-top: 1rem;
    .title {
        color: ${(props) => props.theme.palette.textLight};
        font-size: 0.9rem;
    }
`;

export const AddressLine = styled.div`
    display: flex;
    align-items: center;
    grid-gap: 13px;
    background: ${(props) => props.theme.palette.backgroundDark};
    border-radius: 8px;
    line-height: 1;
    padding: 5px 14px;
    &:hover {
        cursor: pointer;
    }
    p {
        margin: 0;
    }
    .label {
        color: ${(props) => props.theme.palette.textLight};
        font-size: 0.9rem;
    }
`;

export const AddressContainerFormView = styled.form`
    box-sizing: border-box;
    input {
        width: 100%;
    }
`;

export const PhoneDropdownList = styled.div`
    display: flex;
    .dropbtn {
        border: 0px;
        height: 100%;
        border-radius: 3px 0px 0px 3px;
        border-right: 1px solid ${(props) => props.theme.palette.backgroundDark};
    }
`;

export const PhoneRowItem = styled.li`
    display: flex;
    .dialcode {
        width: 39px;
        margin-left: auto;
    }
`;

export const AddressLineContainer = styled.div`
    display: flex;
    flex-direction: column;

    .addressmodalcontainer {
        padding: 15px;
    }

    .actions {
        display: flex;
    }
    .linecard {
        padding: 10px;
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        display: flex;
        align-items: center;
        width: 100%;
        border-radius: 50px;
        cursor: pointer;
        box-sizing: border-box;

        margin-right: 10px;

        .linetitle {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: middle;
        }

        .helpertext {
            font-size: 0.9rem;
            color: ${(props) => props.theme.palette.textLight};
        }

        .icon {
            margin-right: 10px;
            color: ${(props) => props.theme.palette.textLight};
        }
    }

    .close-modal-panel {
        display: flex;
        justify-content: flex-end;
    }
`;

export const InputGroup = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 15px;

    .label {
        font-size: 0.9rem;
        margin-bottom: 4px;

        color: ${(props) => props.theme.palette.textLight};
    }
`;

export const FlexRow = styled.div`
    display: flex;
    width: 100%;
    align-items: center;

    .dropdownview {
        width: 100%;
    }
    .dropbtn {
        padding: 11px;
        margin-bottom: 10px;
        margin-top: 5px;
        border-radius: 4px;
        width: 100%;
        justify-content: space-between;
    }
`;

export const FlexRight = styled(FlexRow)`
    justify-content: flex-end;
    margin-top: 20px;
`;

export const OptionContainer = styled.div`
    margin-top: 15px;
    display: flex;
    flex-direction: column;
`;

export const LabelOption = styled.label`
    color: ${(props) => props.theme.palette.textLight};
    font-size: 0.9rem;
    margin-bottom: 3px;
`;

export const FinalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 6px;
    .icon {
        font-size: 2rem;
        color: ${(props) => props.theme.palette.textLight};
    }
`;
