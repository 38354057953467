import styled from "styled-components";

export const TableCustom = styled.table`
    width: 100%;
    tr:last-child {
        th,
        td,
        tr {
            border: 0px;
        }
    }
`;

export const TableTr = styled.tr`
    padding: 10px 17px;
    border-bottom: 1px solid ${(props) => props.theme.palette.backgroundDark};
    table {
        border-collapse: collapse;
    }
    .min-width {
        width: 1%;
        white-space: nowrap;
    }
    th {
        color: ${(props) => props.theme.palette.textLight};
        font-size: 0.9rem;
    }

    &.hover {
        &:hover {
            th,
            td {
                background: ${(props) => props.theme.palette.backgroundLight};
                cursor: pointer;
            }
        }
    }

    th,
    td {
        border-bottom: 1px solid ${(props) => props.theme.palette.backgroundDark};
        text-align: left;
        line-height: 1.2;
        padding: 15px 4px;
        font-weight: 400;
        a {
            display: block;
            color: ${(props) => props.theme.palette.text};
        }
    }
`;
