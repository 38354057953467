export const StoreKey = {
    appSettings: "appSettings",
    todo: "todos",
    accountUser: "accountUser",
    product: "product",
    warehouseProduct: "warehouseProduct",
    organization: "organization",
    currentSale: "currentSale",
    currentPurchase: "currentPurchase",
    productCategory: "productCategory",
    seller: "seller",
    customer: "customer",
    warehouse: "warehouse",
    sale: "sale",
    lounge: "lounge",
    deliveryOrder: "deliveryOrder",
    purchase: "purchase",
    supplier: "supplier",
    customCategory: "customCategory",
    productCompany: "productCompany",
    currentCustomer: "currentCustomer",
    currentProductCatalog: "currentProductCatalog",
};

export const persistOptions = [StoreKey.appSettings, StoreKey.currentSale, StoreKey.currentCustomer];

const PREFIX = "wahiocatalog";

const checkKey = (key: string) => {
    return persistOptions.find((x) => x === key);
};

const getKeyName = (keyName: string) => {
    return PREFIX + keyName;
};

export function getStoredData<T>(defaultState: T, keyName: string): T {
    if (!checkKey(keyName)) return defaultState;
    const storeItem = localStorage.getItem(getKeyName(keyName)) as string;
    if (storeItem) {
        try {
            const localData: T = JSON.parse(storeItem);
            return localData;
        } catch (e) {
            console.log(`Invalid storage value for ${keyName}`);
        }
    }
    return defaultState;
}

export function saveData<T>(keyName: string, obj: T) {
    if (checkKey(keyName)) localStorage.setItem(getKeyName(keyName), JSON.stringify(obj));
}
