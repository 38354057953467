import { IBusinessException } from "../store/types";
import fetchApi, { getFetchError } from "./fetchApi";

export const getBussinessErrorMessage = (data: any) => {
    const list: IBusinessException[] = data;
    const single: IBusinessException = data;
    if (list && list.length > 0 && typeof list.map === "function") {
        const values = list.map((item) => (item.message ? item.message : "Error desconicido"));
        return values.join(", ");
    } else if (single && single.message) {
        return single.message;
    } else {
        return data;
    }
};

export const wahioFetch = {
    get: (url: string, onSuccess: (value: any) => void, onError: (value: any) => void) => {
        return fetchApi
            .get(url)
            .then((res: any) => onSuccess(res))
            .catch((error: any) => {
                onError(getFetchError(error));
            });
    },
    put: (url: string, data: any, onSuccess: (value: any) => void, onError: (value: any) => void) => {
        return fetchApi
            .put(url, data)
            .then((response: any) => onSuccess(response))
            .catch((error: any) => onError(getFetchError(error)));
    },
    post: (url: string, data: any, onSuccess: (value: any) => void, onError: (value: any) => void) => {
        return fetchApi
            .post(url, data)
            .then((response: any) => onSuccess(response))
            .catch((error: any) => onError(getFetchError(error)));
    },
    delete: (url: string, onSuccess: (value: any) => void, onError: (value: any) => void) => {
        return fetchApi
            .delete(url)
            .then((res: any) => onSuccess(res))
            .catch((error: any) => onError(getFetchError(error)));
    },
};
