import styled from "styled-components";
import { SquareButton } from "../controls";
import { TableTr } from "../controls/tables/styled";

export const RowContainer = styled(TableTr)`
    .input-price {
        min-width: 80px;
    }
    &.refresh {
        th,
        td {
            background: blue;
        }
    }
    td {
        padding: 8px 4px;
    }
    input {
        border: 0px;
    }

    &.fade-in-background {
        background: transparent;
        animation: backgroundIn ease 1s;
    }
    .light {
        font-size: 0.9rem;
        color: ${(props) => props.theme.palette.textLight};
    }
    @keyframes backgroundIn {
        0% {
            background: transparent;
        }
        20% {
            background: ${(props) => props.theme.palette.backgroundLight};
        }
        80% {
            background: ${(props) => props.theme.palette.backgroundLight};
        }
        100% {
            background: transparent;
        }
    }
`;

export const IconButton = styled(SquareButton)`
    width: 35px;
    height: 35px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: 0px;
    margin: 0px 10px;
    &.delete {
        color: ${(props) => props.theme.palette.textLight};
    }
`;

export const IconQuantityButton = styled(IconButton)`
    border-radius: 50px;
    margin: 0px;
    width: 30px;
    height: 30px;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
`;

export const ImageContainer = styled.div`
    width: 40px;
    height: 40px;
    margin-right: 0.8rem;
    border-radius: 4px;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
    }
`;

export const ImageCardContainer = styled(ImageContainer)`
    width: 60px;
    height: 60px;
    min-width: 60px;
`;

export const FlexContainer = styled.div`
    display: flex;
    align-items: center;
    grid-gap: 10px;
`;

export const FlexSpaceBetween = styled(FlexContainer)`
    justify-content: space-between;
    flex-wrap: wrap;
`;

export const QuantityContainer = styled(FlexContainer)`
    grid-gap: 1px;
    input {
        width: 45px;
        text-align: center;
        font-weight: 500;
    }
`;

export const PriceContainer = styled(FlexContainer)`
    grid-gap: 1px;
`;

export const EditSpan = styled.span`
    font-size: 0.9rem;
    margin-left: 4px;
    color: ${(props) => props.theme.palette.textLight};
`;

export const CardContainer = styled.div`
    padding: 10px;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    border-radius: 8px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    .textbox-numeral { 
        padding-left: 10px;
    }
    button {
      
        margin: 0px;
    }
    input {
        border-radius: 40px;
    }
    .action { 
        border-radius: 40px;
        width
    }
    .total { 
        color: ${(props) => props.theme.palette.textLight};
        font-size: .9rem;
    }
    .light {
        font-size: 0.9rem;
        color: ${(props) => props.theme.palette.textLight};
    }
`;

export const CardBody = styled.div`
    display: flex;

    flex-direction: column;
    grid-gap: 8px;
    width: 100%;
`;
