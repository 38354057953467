import React, { useEffect, useRef, useState } from "react";
import { DropdownContainer, DropdownContent } from "./styled";
import { v4 as uuid } from "uuid";
import { ClickResult, getButtonStyle, getContentPositionStyle, getContentShowStyle, ignoreInternalClick } from "./dropdownHelper";
import { useLocation } from "react-router-dom";
import { DefaultButton } from "../controls";

export function useEventListenerWindow(eventName: string, handler: (value: any) => void) {
    // Create a ref that stores handler
    const savedHandler = useRef<any>();
    // Update ref.current value if handler changes.
    // This allows our effect below to always get latest handler ...
    // ... without us needing to pass it in effect deps array ...
    // ... and potentially cause effect to re-run every render.
    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);
    
    useEffect(
        () => {
            // Make sure element supports addEventListener
            // On
            const isSupported = window.addEventListener;
            if (!isSupported) return;
            // Create event listener that calls handler function stored in ref
            const eventListener = (event: any) => savedHandler.current(event);
            // Add event listener
            window.addEventListener(eventName, eventListener);
            // Remove event listener on cleanup
            return () => {
                window.removeEventListener(eventName, eventListener);
            };
        },
        [eventName] // Re-run if eventName or element changes
    );
}


export type DropdownPositionX = "left" | "right" | "center";
export type DropdownPositionY = "top" | "bottom" | "inline";

export interface DropdownRects {
    button: ElementRect;
    content: ElementRect;
}
export class ElementRect {
    x: number = 0;
    y: number = 0;
    width: number = 0;
    height: number = 0;
}

export interface IContentPosition {
    contentHorizontal?: DropdownPositionX;
    contentVertical?: DropdownPositionY;
    contentOutside?: boolean;
}

export interface DropdownStyleOption {
    styleContent?: React.CSSProperties;
    styleContainer?: React.CSSProperties;
    styleButton?: React.CSSProperties;
}
export interface DropdownProps extends IContentPosition, DropdownStyleOption {
    ignoreInternalClics?: boolean;
    children: React.ReactNode;
    title?: string;
    icon?: string;
    image?: string;
    hideIcon?: boolean;
    leftIcon?: string;
    prefix?: string;
}

export default function Dropdown(props: DropdownProps) {
    const { styleContent = {}, styleButton = {} } = props;
    const location = useLocation();
    const [ID] = useState(uuid());
    const BUTTON_ID: string = "button_" + ID;
    const CONTENT_ID: string = "content_" + ID;

    const [showContent, setShowContent] = useState(false);
    const [dropdownRect, setDropdownRect] = useState<DropdownRects>({
        button: new ElementRect(),
        content: new ElementRect(),
    });

    useEffect(() => {
        setShowContent(false);
    }, [location]);

    useEventListenerWindow("click", (e) => {
        const isDropdownButton = e.target.id === BUTTON_ID;
        if (!isDropdownButton) {
            if (props.ignoreInternalClics) {
                if (e.target.id === CONTENT_ID) return;
                let value = new ClickResult();
                ignoreInternalClick(e.target, value, CONTENT_ID);
                if (value.result) return;
            }
            setShowContent(false);
        }
    });

    useEventListenerWindow("resize", () => {
        updateDropdownPositions();
    });

    const updateDropdownPositions = () => {
        let btnElement = document.getElementById(BUTTON_ID);
        let contentElement = document.getElementById(CONTENT_ID);
        if (btnElement && contentElement) {
            const buttonRect = btnElement.getBoundingClientRect();
            const contentRect = contentElement.getBoundingClientRect();

            setDropdownRect({
                button: buttonRect,
                content: contentRect,
            });
        }
    };

    const onClickOpenDropdown = () => {
        updateDropdownPositions();
        setShowContent(!showContent);
    };

    const includesTitle = props.title;
    const isSquareButton = !includesTitle;
    const contentStylePosition = getContentPositionStyle(dropdownRect, props);
    const contentShowStyle = getContentShowStyle(showContent);
    const buttonStyle = getButtonStyle(isSquareButton);

    return (
        <DropdownContainer className="dropdown" style={props.styleContainer}>
            <DefaultButton
                type="button"
                id={BUTTON_ID}
                rounded
                className={`dropdown-btn`}
                style={{ ...styleButton, ...buttonStyle }}
                onClick={onClickOpenDropdown}
            >
                {props.image && (
                    <div className="dropdown-image">
                        <img id={BUTTON_ID} src={props.image} alt="" />
                    </div>
                )}

                {props.leftIcon && <span className={props.leftIcon} style={{ marginRight: "7px" }}></span>}

                {includesTitle && (
                    <>
                        {props.prefix && (
                            <span id={BUTTON_ID} style={{ marginRight: "7px" }}>
                                {props.prefix}
                            </span>
                        )}
                        <span className="dropdown-title" id={BUTTON_ID}>
                            {props.title}
                        </span>
                    </>
                )}
                {!props.hideIcon && (
                    <span
                        id={BUTTON_ID}
                        style={{ marginLeft: includesTitle ? "10px" : 0 }}
                        className={`${props.icon ?? "wahioicon-angle-down"} dropdown-right-icon`}
                    />
                )}
            </DefaultButton>

            <DropdownContent style={{ ...styleContent, ...contentStylePosition, ...contentShowStyle }} id={CONTENT_ID}>
                {props.children}
            </DropdownContent>
        </DropdownContainer>
    );
}
