import React, { createContext, useReducer, useEffect } from "react";
import { saveData, StoreKey } from "..";
import { IWarehouseActions, useActions } from "../actions/warehouse";
import { applyMiddleware } from "../middlewares/warehouse";
import { initState, warehouseReducer } from "../reducers/warehouse";
import { IWarehouseState } from "../types/warehouse";

interface IContextProps {
    warehouseState: IWarehouseState;
    warehouseActions: IWarehouseActions;
}

export const WarehouseContext = createContext({} as IContextProps);

const WarehouseContextProvider = (props: any) => {
    const [warehouseState, dispatch] = useReducer(warehouseReducer, initState);

    useEffect(() => {
        saveData(StoreKey.warehouse, warehouseState);
    }, [warehouseState]);

    const warehouseActions = useActions(warehouseState, applyMiddleware(dispatch));

    return <WarehouseContext.Provider value={{ warehouseState, warehouseActions }}>{props.children}</WarehouseContext.Provider>;
};

export default WarehouseContextProvider;
