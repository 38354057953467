import { Dispatch } from "react";
import { ICurrentCustomerState, IUpdateCurrentCustomer, CustomerTypes, UPDATE_CURRENT_CUSTOMER } from "../types/customer";

export interface ICurrentCustomerActions {
    update: (value: ICurrentCustomerState) => void;
}

export const actionUpdate = (value: ICurrentCustomerState): IUpdateCurrentCustomer => {
    return {
        type: UPDATE_CURRENT_CUSTOMER,
        value,
    };
};

export const useActions = (state: ICurrentCustomerState, dispatch: Dispatch<CustomerTypes>): ICurrentCustomerActions => ({
    update: (value: ICurrentCustomerState) => {
        dispatch(actionUpdate(value));
    },
});
