import React, { createContext, useReducer, useEffect, useContext } from "react";
import { saveData, StoreKey } from "..";
import { ICurrentPurchaseActions, useActions } from "../actions/currentPurchase";
import { applyMiddleware } from "../middlewares/currentPurchase";

import { currentPurchaseReducer, initState } from "../reducers/currentPurchase";
import { ICurrentPurchaseState } from "../types/purchase";
import { AccountUserContext } from "./AccountUserContext";

export interface ICurrentPurchaseContextProps {
    currentPurchaseState: ICurrentPurchaseState;
    currentPurchaseActions: ICurrentPurchaseActions;
}

export const CurrentPurchaseContext = createContext({} as ICurrentPurchaseContextProps);

const CurrentPurchaseContextProvider = (props: any) => {
    const [currentPurchaseState, dispatch] = useReducer(currentPurchaseReducer, initState);
    const { accountUserState } = useContext(AccountUserContext);

    useEffect(() => {
        saveData(StoreKey.currentPurchase, currentPurchaseState);
    }, [currentPurchaseState]);

    useEffect(() => {
        if (accountUserState.value) {
            currentPurchaseActions.update({ ...currentPurchaseState.currentPurchase, accountId: accountUserState.value?.accountId });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountUserState.value]);

    const currentPurchaseActions = useActions(currentPurchaseState, applyMiddleware(dispatch));

    return <CurrentPurchaseContext.Provider value={{ currentPurchaseState, currentPurchaseActions }}>{props.children}</CurrentPurchaseContext.Provider>;
};

export default CurrentPurchaseContextProvider;
