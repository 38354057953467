import React, { useContext, useState } from "react";
import WahioImage from "../../assets/images/wahio-logo-100.png";
import { CurrentCustomerContext } from "../../store/contexts/CurrentCustomerContext";
import { CurrentSaleContext } from "../../store/contexts/CurrentSaleContext";
import { getTotalItemsCounter } from "../../components/CreateSaleForm/helpers";
import ProfileSettingsMenu from "../../components/ProfileSettingsMenu";
import { HorizontalMenu, AppTitle, RightPanel, ButtonCarCustom, ContactCard, AppHeader } from "./styled";
import "../../animations/fadeEffects/fadeInOpacity.css";
import { CurrentProductCatalogContext } from "../../store/contexts/CurrentProductCatalogContext";
import CreateSaleForm from "../../components/CreateSaleForm";
import Modal from "../../components/modals/Modal";
import SocialMediaIcons from "../../components/SocialMediaIcons";
import DropdownClassic from "../../components/DropdownClassic";

export interface IPropsMenuOpen {
    onClickOpenMenu: (value: boolean) => void;
    menuOpen: boolean;
}

export default function AppHorizontalMenu({ onClickOpenMenu, menuOpen }: IPropsMenuOpen) {
    const { currentCustomerState } = useContext(CurrentCustomerContext);
    const { currentSaleState } = useContext(CurrentSaleContext);
    const { currentProductCatalogState } = useContext(CurrentProductCatalogContext);
    const [showCheckout, setShowCheckout] = useState(false);

    const productCatalog = currentProductCatalogState.productCatalog;
    const organization = productCatalog?.organization;
    const company = currentCustomerState.account?.company;

    const itemCounter = getTotalItemsCounter(currentSaleState.currentSale.items);

    const getImage = () => {
        if (productCatalog?.organization && productCatalog.organization?.image) {
            return productCatalog.organization?.image;
        } else if (company?.profileImage) {
            return company.profileImage;
        } else {
            return WahioImage;
        }
    };

    const getCatalogTitle = () => {
        if (productCatalog?.organization) {
            return productCatalog.organization.name;
        } else if (company?.name) {
            return company.name;
        } else {
            return "Wahio Catálogo";
        }
    };

    return (
        <HorizontalMenu>
            {showCheckout && (
                <Modal
                    id="order-view-modal"
                    sizeType="sm"
                    header={true}
                    scrollInside
                    show={!!showCheckout}
                    setShow={setShowCheckout}
                    title={"Mi Carrito"}
                >
                    <CreateSaleForm />
                </Modal>
            )}

            <AppHeader to={`/catalog/${productCatalog ? productCatalog.id : ""}`}>
                <div className="image">
                    <img src={getImage()} alt="WahioLogo" />
                </div>
                <AppTitle>
                    <span>{getCatalogTitle()}</span> {productCatalog ? <span className="subtitle">{productCatalog.name}</span> : null}
                </AppTitle>

                {organization && productCatalog && (
                    <div className="social-line">
                        <SocialMediaIcons productCatalog={productCatalog} organization={organization} />
                    </div>
                )}
            </AppHeader>

            <RightPanel>
                {(!productCatalog?.allowPublicAccess || productCatalog.allowOrderWithWhatsapp) && (
                    <>
                        <ButtonCarCustom onClick={() => setShowCheckout(true)} key={itemCounter} className="fade-in cart" rounded={true}>
                            <span className="wahioicon-shopping-cart"></span> {itemCounter}
                        </ButtonCarCustom>
                        <ContactCard className="mr-1 hidemobile">
                            <span className="wahioicon-user1 icon mr-1"></span>
                            <span>
                                {currentCustomerState.customer?.firstName} {currentCustomerState.customer?.lastName}
                            </span>
                        </ContactCard>
                    </>
                )}
                <DropdownClassic id="profiledro" right={true} value="">
                    <ProfileSettingsMenu />
                </DropdownClassic>
            </RightPanel>
        </HorizontalMenu>
    );
}
