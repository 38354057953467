import styled from "styled-components";
import { DefaultButton } from "../controls";

export const DropdownContainer = styled.div`
    position: relative;
    display: inline-block;

    &.rounded {
        .dropbtn {
            border-radius: 50px;
        }
    }
    .dropdown-content {
        display: none;
        position: absolute;
        background-color: ${(props) => props.theme.palette.background};
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px ${(props) => props.theme.palette.shadowDark};
        z-index: 11;
        border-radius: 4px;
        overflow: hidden;
        max-height: 420px;
        overflow-y: auto;
        min-width: 250px;
    }
    .smallvalue {
        max-width: 170px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
    }
    .show {
        display: block;
    }
    .min-width-auto {
        min-width: auto;
    }
    .white-space-nowrap {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
    }
    .right-0 {
        right: 0;
    }
`;

export const Title = styled.span`
    font-weight: 500;
    margin-right: 6px;
`;

export const DropValue = styled.span`
    font-weight: 400;
    margin-right: 6px;
`;

export const CustomCustom = styled(DefaultButton)`
    display: flex;
    align-items: center;
`;

export const UlContainer = styled.ul`
    margin: 0;
    padding: 5px 0px;

    li {
        cursor: pointer;
        padding: 6px 15px;
        display: flex;
        align-items: center;

        .icon {
            width: 30px;
            text-align: center;
            padding-right: 7px;
        }

        &:hover {
            background: ${(props) => props.theme.palette.backgroundDark};
        }
    }
`;

export const DropdownContentContainer = styled.div`
    padding: 10px 10px;
`;

export const DropdownContentButtom = styled(DefaultButton)`
    border: 0px;
    padding: 0.4rem 1rem;
    font-size: 0.9rem;
    font-weight: 400;
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 3px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;

    margin: 0;
    max-width: 200px;
`;

export const ContainerListFilter = styled.ul`
    margin: 0;
    padding: 15px;
    li {
        margin: 3px 0px;
        cursor: pointer;
        padding: 4px 14px;
        border-radius: 16px;
        &:hover {
            background: ${(props) => props.theme.palette.backgroundDark};
        }
    }
`;
