import { encrypted, decrypted } from "./crypto";
import customNumeral from "./numberFormat/moneyFormat";
import customMoment from "./momentFormat/dateFormat";
import { getFetchError } from "../services/fetchApi";
import { BusinessException } from "../store/types";

export const getUtcDate = (stringDate?: string) => {
    if (!stringDate) return new Date();
    if (stringDate[stringDate.length - 1] === "Z") return new Date(stringDate);
    return new Date(`${stringDate}Z`);
};
export const datetime7FormatUtc = `YYYY-MM-DDTHH:mm:ss:sssssssZ`;

export const getDateFromNow = (value?: Date) => {
    if (!value) return " - ";
    const dateutc = getUtcDate(value.toString());
    return customMoment(dateutc).fromNow();
};
export const getDateFormatL = (value?: Date) => {
    if (!value) return " - ";
    return customMoment(getUtcDate(value.toString())).format("LLLL");
};
export const getDateFormatllll = (value?: Date) => {
    if (!value) return " - ";
    return customMoment(getUtcDate(value.toString())).format("llll");
};
export const getDateFormat = (value: Date, format: string) => {
    if (!value) return " - ";
    return customMoment(getUtcDate(value.toString())).format(format);
};

export const getTimeIntervalMinutes = (end?: Date, start?: Date) => {
    if (!end || !start) return 0;
    const endDate = customMoment(end);
    const startDate = customMoment(start);
    var duration = customMoment.duration(endDate.diff(startDate));
    var hours = Math.abs(Math.trunc(duration.asMinutes()));
    return hours;
};

export const getTimeIntervalSeconds = (end?: Date | string, start?: Date | string) => {
    if (!end || !start) return 0;
    const endDate = customMoment(end);
    const startDate = customMoment(start);
    var duration = customMoment.duration(endDate.diff(startDate));
    var hours = Math.abs(Math.trunc(duration.asSeconds()));
    return hours;
};

export const joinUrl = (base: string, value: string) => {
    if (base && base.charAt(base.length - 1) === "/") {
        return base + value;
    } else return `${base}/${value}`;
};

export const addParamToUrl = (urlBase: string, paramName: string, paramValue: string) => {
    var url = new URL(urlBase);
    var search_params = url.searchParams;
    search_params.set(paramName, paramValue);
    url.search = search_params.toString();

    return url.toString();
};

export const isBunissesException = (data: any) => {
    return data && data.type && data.message && data.code;
};

export const setCookieAlreadyEncrypted = (name: string, value: any) => {
    if (value) {
        var cookie = name + "=" + value + "; Max-Age=2592000";
        document.cookie = cookie;
    }
};

export const setCookie = (name: string, value: any) => {
    if (value) {
        var cookie = name + "=" + encrypted(JSON.stringify(value)) + "; Max-Age=2592000";
        document.cookie = cookie;
    }
};

export const getCookie = (name: string) => {
    var cookieArr = document.cookie.split(";");

    for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");

        if (name === cookiePair[0].trim()) {
            const decryptedResult = decrypted(cookiePair[1]);
            if (decryptedResult) return JSON.parse(decryptedResult);
            return null;
        }
    }
    return null;
};
export const deleteCookie = (name: string) => {
    document.cookie = `${name}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
};

export const capitalize = (value: string) => {
    if (typeof value !== "string") return "";
    return value.charAt(0).toUpperCase() + value.slice(1);
};
export const stringIsNullOrEmpty = (value?: string) => {
    return !value || value === "" || value === null;
};

export const formatMoney = (value?: string | number) => {
    var number = customNumeral(value ? value : 0);
    return number.format("$0,0.[00]");
};

export const formatNumber = (value: string | number) => {
    var number = customNumeral(value);
    return number.format("0,0.[00]");
};

export const getRandomString = () => {
    let r = Math.random().toString(36).substring(7);
    return r;
};
export const getElementYPosition = (containerId: string, plus: number, defaultValue: number) => {
    let element = document.getElementById(containerId);
    var posi = element?.getBoundingClientRect();
    if (posi) {
        let topposition = posi?.y;
        topposition = topposition === 0 ? defaultValue - plus : topposition;
        return topposition + plus;
    }
    return defaultValue;
};

export const wahioStringFormat = (value: string, ...params: any[]) => {
    return value.replace(/{(\d+)}/g, function (match, number) {
        return typeof params[number] != "undefined" ? params[number] : match;
    });
};

export const getExceptionFromError = (error: any) => {
    var errResult = getFetchError(error);
    let data = errResult.data ? errResult.data : {};
    if (typeof data === "string") {
        data = { message: data };
    }
    return new BusinessException(data.code, data.message, data.type, errResult.status);
};

export const lisetenerContainerResize = (setContainerWidth: (value: number) => void, containerId: string) => {
    let element = document.getElementById(containerId);
    if (!element) return;
    window.addEventListener("resize", containerResize);
    function containerResize() {
        if (element) setContainerWidth(element?.clientWidth);
    }
};

export const lisetenerContainerPosition = (setContainerWidth: (value: number) => void, containerId: string) => {
    let element = document.getElementById(containerId);
    if (!element) return;
    element.addEventListener("resize", containerResize);
    function containerResize() {
        if (element) setContainerWidth(element?.getBoundingClientRect().x);
    }
};

export const getInitialWidth = (containerId: string) => {
    let element = document.getElementById(containerId);
    return element ? element.clientWidth : 1000;
};

export const getInitialPosition = (containerId: string) => {
    let element = document.getElementById(containerId);
    return element ? element?.getBoundingClientRect().x : 1000;
};
