import { Dispatch } from "react";

import { SaleTypes } from "../types/sale";

export const applyMiddleware = (dispatch: Dispatch<SaleTypes>) => (action: SaleTypes) => {
    switch (action.type) {
        default:
            dispatch(action);
    }
};
