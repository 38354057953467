import React, { useState, useEffect } from "react";
import { BannerSliderContainer } from "./styled";

import SwiperCore, { Autoplay, Scrollbar, A11y, Controller, Pagination, Navigation, Lazy } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import DefaultImageVideo from "../../assets/images/picture-video.png";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import { WahioFile } from "../../store/types";

// install Swiper modules
SwiperCore.use([Autoplay, Navigation, Pagination, Scrollbar, A11y, Controller, Lazy]);

export default function ProductImagesSlider({ images }: { images: WahioFile[] }) {
    // Swiper instance
    const [swiper, updateSwiper] = useState<SwiperCore | undefined>(undefined);
    // Swiper thumbsinstance
    const [swiperThumbs, updateSwiperThumbs] = useState<SwiperCore | undefined>(undefined);

    useEffect(() => {
        if (swiper && swiperThumbs) {
            swiper.controller.control = swiperThumbs;
            swiperThumbs.controller.control = swiper;
        }
    }, [swiper, swiperThumbs]);

    return (
        <BannerSliderContainer>
            <div className="list-images">
                <Swiper
                    preloadImages={false}
                    lazy={true}
                    controller={Controller}
                    onSwiper={updateSwiper}
                    spaceBetween={0}
                    navigation={images.length > 1}
                    centeredSlides={true}
                    slidesPerView={"auto"}
                    loop={false}
                    className="mySwiper2"
                >
                    {images.map((image, index) => (
                        <SwiperSlide key={index}>
                            {image.type === "videoLink" ? (
                                <iframe
                                    title="VideLink"
                                    width="560"
                                    height="315"
                                    src={image.url.replace("watch?v=", "embed/")}
                                    frameBorder="0"
                                    allowFullScreen
                                ></iframe>
                            ) : (
                                <>
                                    <img src={image.url} alt={image.name} />
                                </>
                            )}
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>

            {images.length > 1 && (
                <div className="thumbs">
                    <Swiper
                        onSwiper={updateSwiperThumbs}
                        spaceBetween={0}
                        slideToClickedSlide={true}
                        slidesPerView={"auto"}
                        centeredSlides={true}
                        className="mySwiper"
                    >
                        {images.map((item, index) => (
                            <SwiperSlide key={index}>
                                <img src={item.type === "videoLink" ? DefaultImageVideo : item.url} alt={item.url} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            )}
        </BannerSliderContainer>
    );
}
