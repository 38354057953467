import { defineMessages } from "react-intl";

const base = "components.addressForm";

export default defineMessages({
    required: {
        id: `${base}.required`,
        defaultMessage: "Campo requerido",
    },
    address: {
        id: `${base}.address`,
        defaultMessage: "Dirección",
    },
    updateAddress: {
        id: `${base}.updateAddress`,
        defaultMessage: "Actualizar dirección",
    },
    updateShippingAddress: {
        id: `${base}.updateShippingAddress`,
        defaultMessage: "Actualizar dirección de envío",
    },
    saveChanges: {
        id: `${base}.saveChanges`,
        defaultMessage: "Guardar cambios",
    },
    chooseCustomerAddress: {
        id: `${base}.chooseCustomerAddress`,
        defaultMessage: "Elije una de las direcciones del cliente",
    },
    saveNewAddress: {
        id: `${base}.saveNewAddress`,
        defaultMessage: "Guardar como nueva dirección",
    },
});
