import React, { createContext, useReducer, useEffect } from "react";
import { saveData, StoreKey } from "..";
import { ISaleActions, useActions } from "../actions/sale";
import { applyMiddleware } from "../middlewares/sale";
import { saleReducer, initState } from "../reducers/sale";
import { ISaleState } from "../types/sale";

interface IContextProps {
    saleState: ISaleState;
    saleActions: ISaleActions;
}

export const SaleContext = createContext({} as IContextProps);

const SaleContextProvider = (props: any) => {
    const [saleState, dispatch] = useReducer(saleReducer, initState);

    useEffect(() => {
        saveData(StoreKey.sale, saleState);
    }, [saleState]);

    const saleActions = useActions(saleState, applyMiddleware(dispatch));

    return <SaleContext.Provider value={{ saleState, saleActions }}>{props.children}</SaleContext.Provider>;
};

export default SaleContextProvider;
