import React, { createContext, useReducer, useEffect } from "react";
import { saveData, StoreKey } from "..";
import { ISellerActions, useActions } from "../actions/seller";
import { applyMiddleware } from "../middlewares/seller";
import { initState, sellerReducer } from "../reducers/seller";
import { ISellerState } from "../types/seller";

interface IContextProps {
    sellerState: ISellerState;
    sellerActions: ISellerActions;
}

export const SellerContext = createContext({} as IContextProps);

const SellerContextProvider = (props: any) => {
    const [sellerState, dispatch] = useReducer(sellerReducer, initState);

    useEffect(() => {
        saveData(StoreKey.seller, sellerState);
    }, [sellerState]);

    const sellerActions = useActions(sellerState, applyMiddleware(dispatch));

    return <SellerContext.Provider value={{ sellerState, sellerActions }}>{props.children}</SellerContext.Provider>;
};

export default SellerContextProvider;
