import { Dispatch } from "react";

import { wahioFetch } from "../../services";
import { productEndpoint } from "../../services/restApiEndpoint";
import { actionFetchInvalid, actionReceiveProducts, actionRequestProducts } from "../actions/warehouseProduct";
import { REQUEST_WAREHOUSE_PRODUCTS, WarehouseProductTypes } from "../types/warehouseProduct";

export const applyMiddleware = (dispatch: Dispatch<WarehouseProductTypes>) => (action: WarehouseProductTypes) => {
    switch (action.type) {
        case REQUEST_WAREHOUSE_PRODUCTS:
            dispatch(actionRequestProducts(action.value));
            return wahioFetch.post(
                productEndpoint.post.warehouseProductSearchQuery,
                action.value,
                (success) => {
                    dispatch(actionReceiveProducts(success.data, action.combine));
                },
                (error) => {
                    dispatch(actionFetchInvalid(error));
                }
            );
        default:
            dispatch(action);
    }
};
