import { Dispatch } from "react";
import { IPaginationResponse } from "../types";

import {
    ADD_PRODUCT,
    IAddProduct,
    IFetchInvalid,
    INVALID_REQUEST_PRODUCTS,
    IProduct,
    IProductSearchModel,
    IProductState,
    IReceiveProducts,
    IRequestProduct,
    Product,
    ProductTypes,
    RECEIVE_PRODUCTS,
    REQUEST_PRODUCTS,
} from "../types/product";

export interface IProductActions {
    addProduct: (value: IProduct) => void;
    requestProductsByAccount: (search: IProductSearchModel, combine?: boolean) => void;
    receiveProducts: (pagination: IPaginationResponse<Product>, combine?: boolean) => void;
    fetchInvalid: (error: any) => void;
}

export const actionRequest = (search: IProductSearchModel, combine?: boolean): IRequestProduct => {
    return {
        type: REQUEST_PRODUCTS,
        value: search,
        combine,
    };
};

export const actionReceive = (pagination: IPaginationResponse<Product>, combine?: boolean): IReceiveProducts => {
    return {
        type: RECEIVE_PRODUCTS,
        value: pagination,
        combine,
    };
};

export const actionAddProduct = (value: IProduct): IAddProduct => {
    return {
        type: ADD_PRODUCT,
        value,
    };
};

export const actionFetchInvalid = (er: any): IFetchInvalid => {
    return {
        type: INVALID_REQUEST_PRODUCTS,
        value: er,
    };
};

export const useActions = (state: IProductState, dispatch: Dispatch<ProductTypes>): IProductActions => ({
    requestProductsByAccount: (search: IProductSearchModel, combine?: boolean) => {
        dispatch(actionRequest(search, combine));
    },
    receiveProducts: (pagination: IPaginationResponse<Product>) => {
        dispatch(actionReceive(pagination));
    },
    fetchInvalid: (error: any) => {
        dispatch(actionFetchInvalid(error));
    },
    addProduct: (value: IProduct) => {
        dispatch(actionAddProduct(value));
    },
});
