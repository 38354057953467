import styled from "styled-components";

export const Container = styled.div`
    position: relative;
`;
export const ToolTip = styled.div`
    position: absolute;
    text-align: center;
    left: 0;
    color: var(--text-primary-2);
    font-size: 1rem;
    font-weight: 500;
    top: -64%;
    background: var(--bg-primary);
    border-radius: 40px;
    padding: 2px 9px;
    box-shadow: 0px 2px 10px var(--color-primary-shadow);
`;

export const InputCustom = styled.input`
    background: transparent;
    color: ${(props) => props.theme.palette.text};
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    outline: none;
    padding: 0.6rem;
    width: 80px;
    border-radius: 4px;
    &:hover {
        background: ${(props) => props.theme.palette.backgroundLight};
    }
    &:focus {
        border-color: ${(props) => props.theme.palette.backgroundDark};
    }
`;
