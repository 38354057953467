import React, { createContext, useReducer, useEffect } from "react";
import { saveData, StoreKey } from "..";
import { IDeliveryOrderActions, useActions } from "../actions/deliveryOrder";
import { applyMiddleware } from "../middlewares/deliveryOrder";
import { deliveryOrderReducer, initState } from "../reducers/deliveryOrder";
import { IDeliveryOrderState } from "../types/deliveryOrder";

interface IContextProps {
    deliveryOrderState: IDeliveryOrderState;
    deliveryOrderActions: IDeliveryOrderActions;
}

export const DeliveryOrderContext = createContext({} as IContextProps);

const DeliveryOrderContextProvider = (props: any) => {
    const [deliveryOrderState, dispatch] = useReducer(deliveryOrderReducer, initState);

    useEffect(() => {
        saveData(StoreKey.deliveryOrder, deliveryOrderState);
    }, [deliveryOrderState]);

    const deliveryOrderActions = useActions(deliveryOrderState, applyMiddleware(dispatch));

    return (
        <DeliveryOrderContext.Provider value={{ deliveryOrderState, deliveryOrderActions }}>{props.children}</DeliveryOrderContext.Provider>
    );
};

export default DeliveryOrderContextProvider;
