import React from "react";
import { useAlert } from "react-alert";
import EmptyImage from "../../assets/images/picture-image.png";

import { IProduct, ProductPriceTypes } from "../../store/types/product";
import { IProductCategory } from "../../store/types/productCategory";
import { IWarehouse } from "../../store/types/warehouse";
import { formatMoney } from "../../utils";
import { SquareButton } from "../controls";
import { ProductContainer, ImageContainer, CustomImage, ProductTitle, TextContainer, ProductSunTitle } from "./styled";

export interface IProductOption {
    product: IProduct;
    warehouse?: IWarehouse;
}
export interface ProductClickProps {
    onClickAddProduct: (value: IProductOption) => void;
    onClickViewProduct: (value: IProductOption) => void;
    warehouse?: IWarehouse;
}
export interface ProductCardProps extends ProductClickProps {
    product: IProduct;
    stock?: number;
    showStock?: boolean;
    showPrice?: boolean;
    defaultPrice: ProductPriceTypes;
    allowAddToCart: boolean;
    onClickCategory: (category: IProductCategory) => void;
}

export default function ProductCard(props: ProductCardProps) {
    const { product, stock } = props;
    const alert = useAlert();
    if(!product) return null;
    const image = product?.profilePicture ? product.profilePicture : EmptyImage;
    const outclass = product.controlInventory && stock === 0 ? "out" : "";
    const onClick = () => {
        if (stock === 0) return;
        else props.onClickAddProduct({ product, warehouse: props.warehouse });
        alert.success(`${product.name} Agregado`);
    };

    const LinkView = ({ children }: any) => {
        return <div onClick={() => props.onClickViewProduct({ product, warehouse: props.warehouse })}>{children}</div>;
    };

    return (
        <ProductContainer className={outclass}>
            {props.allowAddToCart && (
                <SquareButton className="plus" onClick={onClick}>
                    <span className="wahioicon-plus"></span>
                </SquareButton>
            )}
            <LinkView>
                <ImageContainer>
                    <CustomImage src={image} alt="productimage" />
                </ImageContainer>
            </LinkView>
            <TextContainer>
                <LinkView>
                    <ProductTitle>{product.name}</ProductTitle>
                </LinkView>
                {product.customCategory && (
                    <span className="category" onClick={() => props.onClickCategory(product.customCategory)}>
                        {product.customCategory.name}
                    </span>
                )}
                <ProductSunTitle>
                    <span>{props.showPrice ? formatMoney(product[props.defaultPrice]) : ""}</span>
                    {props.showStock && (
                        <div className={`stock`}>
                            <span>{stock}</span>
                        </div>
                    )}
                </ProductSunTitle>
            </TextContainer>
        </ProductContainer>
    );
}
