import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { DefaultButton, PrimaryButton } from "../../controls";
import LoadingDualRing from "../../LoadingDualRing";
import { getBussinessErrorMessage } from "../../../services";
import { ISale } from "../../../store/types/sale";
import { formatMoney } from "../../../utils";
import { getAbsoluteAmount } from "../helpers";
import messages from "../messages";
import { FinalizarContainer, AmountContainer, FlexContainer, ActionsButtonsContainer, SuccessContainer } from "./styled";

export type PaymentChangeType = "method" | "value";

export interface ISaveResponse {
    isFetching: boolean;
    isError: boolean;
    error?: any;
    value?: ISale;
    isSave?: boolean;
}

export interface FinalizeOrderFormProps {
    onCancel: () => void;
    onFinalize: () => void;
    onFinalizeClose: () => void;
    responseResult: ISaveResponse;
    setResponseResult: (value: ISaveResponse) => void;
    updateSale: (value: ISale) => void;
    currentSale: ISale;
}

export default function FinalizeOrderForm(props: FinalizeOrderFormProps) {
    const intl = useIntl();
    //const { currentSaleState, currentSaleActions } = useContext(CurrentSaleContext);
    const { currentSale } = props;

    const { responseResult, setResponseResult } = props;

    const { payments } = currentSale;

    const getSummatoryPayments = () => {
        let sum = 0;
        payments.forEach((element) => (sum += element.amount));
        return sum;
    };

    const summatoryPrices = getSummatoryPayments();
    const absoluteAmount = getAbsoluteAmount(currentSale);
    const diferencePrices = absoluteAmount - summatoryPrices;

    const onCloseFinalize = () => {
        setResponseResult({ isError: false, isFetching: false });
        props.onFinalizeClose();
    };

    if (responseResult.isSave && responseResult.value) {
        return (
            <SuccessContainer>
                <span className="icon wahioicon-check-circle"></span>
                <h2>Bien!</h2>
                <p>La venta fue creada con éxito: #{responseResult.value.number}</p>
                <PrimaryButton onClick={onCloseFinalize}>Aceptar</PrimaryButton>
            </SuccessContainer>
        );
    }

    if (responseResult.isError) {
        return (
            <SuccessContainer>
                <span className="icon wahioicon-exclamation-circle"></span>
                <h2>Ops!</h2>
                {responseResult.error?.data ? (
                    <p>{getBussinessErrorMessage(responseResult.error.data)}</p>
                ) : (
                    <p>No se pudo crear la orden correctamente.</p>
                )}

                <PrimaryButton onClick={() => setResponseResult({ isFetching: false, isError: false })}>Aceptar</PrimaryButton>
            </SuccessContainer>
        );
    }

    if (responseResult?.isFetching) {
        return <LoadingDualRing center={true} />;
    }
    return (
        <FinalizarContainer>
            {currentSale.isDelivery && currentSale.deliveryOrder && (
                <FlexContainer>
                    <div>
                        <span className="light wahioicon-map-marker-alt"></span>
                        <span className="light">{currentSale.deliveryOrder.description}</span>
                    </div>
                </FlexContainer>
            )}

            <table>
                <tbody>
                    {currentSale.customer && (
                        <tr>
                            <td className="light small min-width">{<FormattedMessage {...messages.customer} />}</td>
                            <td>
                                {currentSale.customer.firstName} {currentSale.customer.lastName}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            <div>
                <span className="subtotal">
                    <FormattedMessage {...messages.subTotal} /> {formatMoney(currentSale.totalAmountItems)}
                </span>
            </div>

            <AmountContainer>
                <span className="light">
                    <FormattedMessage {...messages.amount} />
                </span>
                <FlexContainer>
                    {diferencePrices !== 0 && <span className="light">{formatMoney(diferencePrices)}</span>}
                    <span className="total">
                        <strong>{formatMoney(absoluteAmount)}</strong>
                    </span>
                </FlexContainer>
            </AmountContainer>

            {diferencePrices < 0 ? (
                <p className="font-md text-warning">
                    <span className="wahioicon-exclamation-triangle"></span> {intl.formatMessage(messages.paymentExceedsOrderValue)}{" "}
                    <strong>{formatMoney(diferencePrices * -1)}</strong>
                </p>
            ) : (
                <ActionsButtonsContainer>
                    <DefaultButton onClick={props.onCancel} className="cancel">
                        {intl.formatMessage(messages.cancelModal)}
                    </DefaultButton>
                    <PrimaryButton onClick={props.onFinalize}>Enviar Orden</PrimaryButton>
                </ActionsButtonsContainer>
            )}
        </FinalizarContainer>
    );
}
