import { getStoredData, StoreKey } from "..";
import {
    INVALID_REQUEST_PRODUCT_CATEGORIES,
    IProductCategory,
    IProductCategoryState,
    ProductCategoryTypes,
    RECEIVE_PRODUCT_CATEGORIES,
    REQUEST_PRODUCT_CATEGORIES,
} from "../types/productCategory";

export class InitStateBase implements IProductCategoryState {
    items: IProductCategory[] = [];
    error: any = undefined;
    isFetching: boolean = false;
    isError: boolean = false;
}

export const initState = getStoredData<IProductCategoryState>(new InitStateBase(), StoreKey.organization);

export const productCategoryReducer = (state: IProductCategoryState, action: ProductCategoryTypes): IProductCategoryState => {
    switch (action.type) {
        case RECEIVE_PRODUCT_CATEGORIES:
            return {
                ...state,
                isFetching: false,
                isError: false,
                items: action.items,
            };
        case REQUEST_PRODUCT_CATEGORIES:
            return {
                ...state,
                isFetching: true,
                isError: false,
            };
        case INVALID_REQUEST_PRODUCT_CATEGORIES:
            return {
                ...state,
                isFetching: false,
                isError: true,
                error: action.value,
            };
        default:
            return state;
    }
};
