import React, { useEffect, useContext, useState } from "react";
import { SearchCustom, SearchContainerView } from "./styled";
import { useIntl } from "react-intl";
import messages from "./messages";
import { IProductCategory } from "../../store/types/productCategory";
import { CurrentProductCatalogContext } from "../../store/contexts/CurrentProductCatalogContext";

interface SearchWithCategoriesProps {
    selectedCategory?: IProductCategory;
    setSelectedCategory: (value?: IProductCategory) => void;
    setSearchValue: (value: string) => void;
    searchValue?: string;
    containerClass?: string;
    fixedCategory?: boolean;
}

export default function SearchWithCategories(props: SearchWithCategoriesProps) {
    const { currentProductCatalogState } = useContext(CurrentProductCatalogContext);
    const { productCatalog } = currentProductCatalogState;
    const { setSearchValue } = props;
    const [currentValue, setCurrentValue] = useState(props.searchValue ? props.searchValue : "");
    const intl = useIntl();

    useEffect(() => {
        if (!productCatalog) return;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productCatalog]);

    const onChange = (e: any) => {
        setCurrentValue(e.target.value);
        setSearchValue(e.target.value);
    };

    return (
        <SearchContainerView className={props.containerClass ?? ""}>
            <SearchCustom
                miliseconds={500}
                onChange={onChange}
                type="search"
                initialValue={currentValue}
                placeholder={intl.formatMessage(messages.searchProduct)}
            />
        </SearchContainerView>
    );
}
