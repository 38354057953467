import React, { createContext, useReducer, useEffect } from "react";
import { saveData, StoreKey } from "..";
import { IProductState } from "../types/product";
import { IProductActions, useActions } from "../actions/product";
import { initState, productReducer } from "../reducers/product";
import { applyMiddleware } from "../middlewares/product";

interface IContextProps {
    productState: IProductState;
    productActions: IProductActions;
}

export const ProductContext = createContext({} as IContextProps);

const ProductContextProvider = (props: any) => {
    const [productState, dispatch] = useReducer(productReducer, initState);

    useEffect(() => {
        saveData(StoreKey.product, productState);
    }, [productState]);

    const productActions = useActions(productState, applyMiddleware(dispatch));

    return <ProductContext.Provider value={{ productState, productActions }}>{props.children}</ProductContext.Provider>;
};

export default ProductContextProvider;
