import { IProductCategory } from "./productCategory";

export const REQUEST_CUSTOM_CATEGORIES = "REQUEST_CUSTOM_CATEGORIES";
export const RECEIVE_CUSTOM_CATEGORIES = "RECEIVE_CUSTOM_CATEGORIES";
export const INVALID_REQUEST_CUSTOM_CATEGORIES = "INVALID_REQUEST_CUSTOM_CATEGORIES";
export const ADD_CUSTOM_CATEGORY = "ADD_CUSTOM_CATEGORY";

export interface ICustomCategoryState {
    items: IProductCategory[];
    error: any;
    isFetching: boolean;
    isError: boolean;
}

export interface AddCustomCategory {
    type: typeof ADD_CUSTOM_CATEGORY;
    value: IProductCategory;
}

export interface RequestCustomCategories {
    type: typeof REQUEST_CUSTOM_CATEGORIES;
    accountId: string;
}
export interface ReceiveCustomCategories {
    type: typeof RECEIVE_CUSTOM_CATEGORIES;
    items: Array<IProductCategory>;
}
export interface InvalidRequest {
    type: typeof INVALID_REQUEST_CUSTOM_CATEGORIES;
    value: any;
}

export type CustomCategoryTypes = AddCustomCategory | RequestCustomCategories | ReceiveCustomCategories | InvalidRequest;
