import { getStoredData, StoreKey } from "..";

import {
    ADD_SUPPLIER,
    INVALID_REQUEST_SUPPLIERS,
    ISupplier,
    ISupplierState,
    RECEIVE_SUPPLIERS,
    REQUEST_SUPPLIERS,
    SupplierTypes,
    UPDATE_SUPPLIER,
} from "../types/supplier";

export class InitStateBase implements ISupplierState {
    items: ISupplier[] = [];
    error: any = undefined;
    isFetching: boolean = false;
    isError: boolean = false;
}

export const initState = getStoredData<ISupplierState>(new InitStateBase(), StoreKey.supplier);

export const supplierReducer = (state: ISupplierState, action: SupplierTypes): ISupplierState => {
    switch (action.type) {
        case ADD_SUPPLIER:
            return {
                ...state,
                items: [...state.items, action.value],
            };
        case UPDATE_SUPPLIER:
            let newlist: ISupplier[] = [];

            state.items.forEach((item) => {
                if (item.id === action.value.id) {
                    newlist.push(action.value);
                } else {
                    newlist.push(item);
                }
            });

            return {
                ...state,
                items: newlist,
            };
        case RECEIVE_SUPPLIERS:
            return {
                ...state,
                isFetching: false,
                isError: false,
                items: action.items,
            };
        case REQUEST_SUPPLIERS:
            return {
                ...state,
                isFetching: true,
                isError: false,
            };
        case INVALID_REQUEST_SUPPLIERS:
            return {
                ...state,
                isFetching: false,
                isError: true,
                error: action.value,
            };
        default:
            return state;
    }
};
