import React, { useContext } from "react";
import { useAlert } from "react-alert";
import { useIntl } from "react-intl";
import { IProductProfile } from ".";
import { CurrentProductCatalogContext } from "../../store/contexts/CurrentProductCatalogContext";
import { CurrentSaleContext } from "../../store/contexts/CurrentSaleContext";
import { ProductPriceTypes } from "../../store/types/product";
import { formatMoney } from "../../utils";
import { PrimaryButton } from "../controls";
import { calculateTotal, insertProductInOrderList } from "../CreateSaleForm/helpers";
import messages from "./messages";
import { DescriptionContainer, DetailContainer } from "./styled";

var parse = require("html-react-parser");

interface IDetailsProps extends IProductProfile {}

export default function ProductDetails(props: IDetailsProps) {
    const { product } = props;
    const { customCategory } = product;
    const { currentSaleState, currentSaleActions } = useContext(CurrentSaleContext);
    const { currentProductCatalogState } = useContext(CurrentProductCatalogContext);
    const { productCatalog } = currentProductCatalogState;
    const intl = useIntl();
    const alert = useAlert();
    const priceOption: ProductPriceTypes = currentProductCatalogState.productCatalog
        ? currentProductCatalogState.productCatalog.price
        : "basePrice";

    const categoryName = customCategory ? customCategory.name : "---";
    //moneyFormat(product[priceOption])

    const getPriceValue = () => {
        const value = (product as any)[priceOption];

        if (value) return value;
        return "0";
    };

    const onClickProduct = () => {
        const { productCatalog } = currentProductCatalogState;
        const { currentSale } = currentSaleState;
        const defaultPrice = productCatalog ? productCatalog.price : "basePrice";
        let items = insertProductInOrderList({
            items: currentSale.items,
            value: product,
            defaultPrice: defaultPrice,
            warehouse: productCatalog?.warehouse,
        });

        const totalAmountItems = calculateTotal(items);
        const percentageDiscount = currentSale.percentageDiscount ?? 0;
        const totalDiscount = totalAmountItems * (percentageDiscount / 100);
        currentSaleActions.update({
            ...currentSale,
            items,
            totalAmountItems,
            percentageDiscount,
            totalDiscount,
        });

        alert.success(`${product.name} Agregado`);
    };

    return (
        <DetailContainer>
            <div className="header">
                <div className="header-title">
                    <h2 className="title-container">{product.name}</h2>
                    <span>Categoría: {categoryName}</span>
                    {product.sku && <span>Sku: {product.sku}</span>}
                </div>
            </div>

            <div>
                {productCatalog?.showPrice && priceOption && <h1>{formatMoney(getPriceValue())}</h1>}

                <div>
                    <br />
                    <PrimaryButton rounded={true} onClick={onClickProduct}>
                        <span className="wahioicon-cart-plus" />
                        Agregar el carrito
                    </PrimaryButton>
                    <br />
                </div>

                {product.description && (
                    <DescriptionContainer className="mt-2">
                        <span className="title">{intl.formatMessage(messages.description)}</span>
                        <p className="content">{parse(product.description)}</p>
                    </DescriptionContainer>
                )}
            </div>
            <br />
            <div></div>
        </DetailContainer>
    );
}
