import React from "react";
import { FormattedMessage } from "react-intl";
import { SaleItemActionsProps, SaleItemCard } from "../SaleItemRow";
import { ISaleItem } from "../../store/types/sale";
import messages from "./messages";
import { CardsContainer, EmptyItemsMessage } from "./styled";

export interface OrderSummaryProps extends SaleItemActionsProps {
    items: ISaleItem[];
    isMobile: boolean;
}

export default function OrderSummary(props: OrderSummaryProps) {
    if (props.items.length === 0) {
        return (
            <EmptyItemsMessage>
                <p>
                    <FormattedMessage {...messages.noProductSelected} />
                </p>
            </EmptyItemsMessage>
        );
    }

    return (
        <CardsContainer>
            {props.items.map((item, index) => (
                <SaleItemCard {...props} saleItem={item} key={index} />
            ))}
        </CardsContainer>
    );
}
