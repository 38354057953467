import { IPaginationRequest, IPaginationResponse } from ".";
import { IAccountUser } from "./accountUser";
import { ISale } from "./sale";

export const ADD_DELIVERY_ORDER = "ADD_DELIVERY_ORDER";
export const DELETE_DELIVERY_ORDER = "DELETE_DELIVERY_ORDER";
export const UPDATE_DELIVERY_ORDER = "UPDATE_DELIVERY_ORDER";
export const REQUEST_DELIVERY_ORDERS = "REQUEST_DELIVERY_ORDERS";
export const RECEIVE_DELIVERY_ORDERS = "RECEIVE_DELIVERY_ORDERS";
export const INVALID_REQUEST_DELIVERY_ORDERS = "INVALID_REQUEST_DELIVERY_ORDERS";

export type DeliveryStatus = "pending" | "processing" | "picking" | "packing" | "shipped" | "delivered";

export interface IDeliverySummary {
    total: number;
    delivered: number;
    pending: number;
}
export interface IDeliveryHistory {
    id: string;
    deliveryOrderId: string;
    comments: string;
    status: DeliveryStatus;
    dateCreated: Date;
}
export interface IDeliveryOrder {
    id?: string;
    dateCreated?: Date;
    dateUpdated?: Date;
    accountId: string;
    userDeliveryManId?: string;
    userDeliveryMan?: IAccountUser;
    status: DeliveryStatus;
    orderId?: string;
    order?: ISale;
    amount: number;
    description?: string;

    country: string;
    state: string;
    city: string;

    contactName: string;
    phoneNumber: string;
    additionalInformation: string;
    neighborhood: string;

    address: string;
    latitude?: string;
    longitude?: string;
}

export class DeliveryOrder implements IDeliveryOrder {
    id?: string | undefined;
    dateCreated?: Date | undefined;
    dateUpdated?: Date | undefined;
    accountId: string;
    userDeliveryManId?: string;
    userDeliveryMan?: IAccountUser;
    status: DeliveryStatus = "pending";
    orderId?: string | undefined;
    order?: ISale;
    amount: number = 0;

    country: string = "";
    state: string = "";
    city: string = "";
    contactName: string = "";
    phoneNumber: string = "";
    additionalInformation: string = "";
    neighborhood: string = "";

    address: string;
    latitude?: string;
    longitude?: string;

    constructor(accountId: string, address: string) {
        this.accountId = accountId;
        this.address = address;
    }
}

export interface IDeliveryOrderState {
    pagination: IPaginationResponse<IDeliveryOrder>;
    error: any;
    isFetching: boolean;
    isError: boolean;
}

export interface IDeliveryOrderSearchModel extends IPaginationRequest {
    accountId: string;
    searchValue?: string;
    status?: string;
    organizationId: string;
}

export interface RequestDeliveryOrders {
    type: typeof REQUEST_DELIVERY_ORDERS;
    value: IDeliveryOrderSearchModel;
}
export interface ReceiveDeliveryOrders {
    type: typeof RECEIVE_DELIVERY_ORDERS;
    value: IPaginationResponse<IDeliveryOrder>;
}
export interface InvalidRequest {
    type: typeof INVALID_REQUEST_DELIVERY_ORDERS;
    value: any;
}
export interface UpdateDeliveryOrder {
    type: typeof UPDATE_DELIVERY_ORDER;
    value: IDeliveryOrder;
}

export interface AddDeliveryOrder {
    type: typeof ADD_DELIVERY_ORDER;
    value: IDeliveryOrder;
}

interface DeleteDeliveryOrder {
    type: typeof DELETE_DELIVERY_ORDER;
    id: string;
}

export type DeliveryOrderTypes =
    | RequestDeliveryOrders
    | ReceiveDeliveryOrders
    | InvalidRequest
    | UpdateDeliveryOrder
    | AddDeliveryOrder
    | DeleteDeliveryOrder;
