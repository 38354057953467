import { getStoredData, StoreKey } from "..";
import {
    ADD_PRODUCT_COMPANY,
    ProductCompanyTypes,
    IProductCompany,
    IProductCompanyState,
    INVALID_REQUEST_PRODUCT_COMPANIES,
    RECEIVE_PRODUCT_COMPANIES,
    REQUEST_PRODUCT_COMPANIES,
    UPDATE_PRODUCT_COMPANY,
} from "../types/productCompany";

export class InitStateBase implements IProductCompanyState {
    items: IProductCompany[] = [];
    error: any = undefined;
    isFetching: boolean = false;
    isError: boolean = false;
}

export const initState = getStoredData<IProductCompanyState>(new InitStateBase(), StoreKey.productCompany);

export const productCompanyReducer = (state: IProductCompanyState, action: ProductCompanyTypes): IProductCompanyState => {
    switch (action.type) {
        case ADD_PRODUCT_COMPANY:
            return {
                ...state,
                items: [...state.items, action.value],
            };
        case UPDATE_PRODUCT_COMPANY:
            let newlist: IProductCompany[] = [];

            state.items.forEach((item) => {
                if (item.id === action.value.id) {
                    newlist.push(action.value);
                } else {
                    newlist.push(item);
                }
            });

            return {
                ...state,
                items: newlist,
            };
        case RECEIVE_PRODUCT_COMPANIES:
            return {
                ...state,
                isFetching: false,
                isError: false,
                items: action.items,
            };
        case REQUEST_PRODUCT_COMPANIES:
            return {
                ...state,
                isFetching: true,
                isError: false,
            };
        case INVALID_REQUEST_PRODUCT_COMPANIES:
            return {
                ...state,
                isFetching: false,
                isError: true,
                error: action.value,
            };
        default:
            return state;
    }
};
