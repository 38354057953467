import styled from "styled-components";

export const MainContainerSettings = styled.div`
    width: 100%;
    max-width: 335px;
    padding: 15px;
    box-sizing: border-box;
`;

export const ElementItem = styled.div`
    display: flex;
    align-items: center;
    padding: 4px 9px;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 4px;
    font-weight: 500;
    &:hover {
        background: ${(props) => props.theme.palette.backgroundLight};
    }
    .icon {
        margin-right: 15px;
    }
`;

export const ElementTitle = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    .title {
        font-weight: 500;
        font-size: 1.1rem;
    }
    .sub {
        font-size: 0.9rem;
        color: ${(props) => props.theme.palette.textLight};
    }
`;
