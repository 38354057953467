import { IPaginationRequest, IPaginationResponse } from ".";
import { getRandomString } from "../../utils";
import { IAccount } from "./accountUser";
import { ICustomer } from "./customer";
import { IDeliveryOrder } from "./deliveryOrder";
import { IOrderItem, IOrder, OrderStatusOptionsType, OrderItemStatus, OrderSourceType } from "./order";
import { IOrderPayment, IOrderRefund } from "./orderPayment";
import { IProduct, ProductPriceTypes } from "./product";
import { ISeller } from "./seller";
import { IWarehouse } from "./warehouse";

export const UPDATE_CURRENT_SALE = "UPDATE_CURRENT_SALE";

export const ADD_SALE = "ADD_SALE";
export const DELETE_SALE = "DELETE_SALE";
export const UPDATE_SALE = "UPDATE_SALE";
export const REQUEST_SALES = "REQUEST_SALES";
export const RECEIVE_SALES = "RECEIVE_SALES";
export const INVALID_REQUEST_SALES = "INVALID_REQUEST_SALES";

export interface ICurrentSaleState {
    currentSale: ISale;
}

export interface ISaleState {
    pagination: IPaginationResponse<ISale>;
    error: any;
    isFetching: boolean;
    isError: boolean;
}
export interface ISaleItem extends IOrderItem {
    discount: number;
    saleId?: string;
    defaultPrice: ProductPriceTypes;
    warehouse?: IWarehouse;
}

export interface IStatusHistory {
    id: string;
    orderId: string;
    status: OrderStatusOptionsType;
    dateCreated: Date;
}

export interface ISale extends IOrder {
    auxid?: string;
    sellerId?: string;
    seller?: ISeller;
    customerId?: string;
    customer?: ICustomer;
    items: ISaleItem[];
    payments: IOrderPayment[];
    refunds: IOrderRefund[];
    statusHistory: IStatusHistory[];
    deliveryOrder?: IDeliveryOrder;
    isDelivery?: boolean;
    isDeliveryClose?: boolean;
    isRestaurant?: boolean;
    isRestaurantClose?: boolean;
    allowLogisticsService: boolean;
    source: OrderSourceType;
}

export class SaleItem implements ISaleItem {
    id?: string | undefined;
    discount: number = 0;
    productId: string;
    product: IProduct;
    warehouseId: string = "";
    warehouse?: IWarehouse;
    quantity: number;
    unitPrice: number;
    comment?: string | undefined;
    position: number = 0;
    status: OrderItemStatus = "empty";
    defaultPrice: ProductPriceTypes = "price";
    deliveryOrder?: IDeliveryOrder;

    constructor(product: IProduct, quantity: number, defaultPrice: ProductPriceTypes, warehouse?: IWarehouse) {
        this.productId = product.id;
        this.product = product;
        this.quantity = quantity;
        this.unitPrice = product[defaultPrice];
        this.defaultPrice = defaultPrice;

        if (warehouse && warehouse.id) {
            this.warehouseId = warehouse.id;
            this.warehouse = warehouse;
        }
    }
}

export class Sale implements ISale {
    auxid?: string;
    sellerId?: string;
    seller?: ISeller;
    customerId?: string;
    customer?: ICustomer;
    items: ISaleItem[] = [];
    payments: IOrderPayment[] = [];
    refunds: IOrderRefund[] = [];
    statusHistory: IStatusHistory[] = [];
    id?: string | undefined;
    accountId: string;
    account?: IAccount;
    dateCreated?: Date;
    dateUpdated?: Date;
    number: number = 0;
    accountUserId: string;
    totalAmount: number = 0;
    totalAmountItems: number = 0;
    totalItemsDiscount: number = 0;
    totalPaid: number = 0;
    totalPayment: number = 0;
    totalDiscount: number = 0;
    totalTip: number = 0;
    percentageDiscount: number = 0;
    percentageTip: number = 0;
    totalRefund: number = 0;
    organizationId: string = "";
    costCenterId: string = "";
    comment: string = "";
    status: OrderStatusOptionsType = "draft";
    isDelivery?: boolean;
    isDeliveryClose?: boolean;
    isRestaurant?: boolean;
    isRestaurantClose?: boolean;
    deliveryOrder?: IDeliveryOrder;
    moneyReceived: number = 0;
    moneyReturned: number = 0;
    allowLogisticsService: boolean = true;
    source: OrderSourceType = "catalog";

    constructor(accountId: string, accountUserId: string, organizationId: string) {
        this.accountId = accountId;
        this.accountUserId = accountUserId;
        this.organizationId = organizationId;
        this.auxid = getRandomString();
        this.isDelivery = true;
    }
}

export interface ISaleSearchModel extends IPaginationRequest {
    accountId: string;
    searchValue?: string;
    status?: string;
    organizationId: string;
}

export interface IUpdateCurrentSale {
    type: typeof UPDATE_CURRENT_SALE;
    value: ISale;
}

export interface RequestSales {
    type: typeof REQUEST_SALES;
    value: ISaleSearchModel;
}
export interface ReceiveSales {
    type: typeof RECEIVE_SALES;
    value: IPaginationResponse<ISale>;
}
export interface InvalidRequest {
    type: typeof INVALID_REQUEST_SALES;
    value: any;
}
export interface UpdateSale {
    type: typeof UPDATE_SALE;
    value: ISale;
}

export interface AddSale {
    type: typeof ADD_SALE;
    value: ISale;
}

interface DeleteSale {
    type: typeof DELETE_SALE;
    id: string;
}

export type SaleTypes = IUpdateCurrentSale | RequestSales | ReceiveSales | InvalidRequest | UpdateSale | AddSale | DeleteSale;
