import { defineMessages } from "react-intl";

const base = "components.productList";

export default defineMessages({
    searchProduct: {
        id: `${base}.searchProduct`,
        defaultMessage: "Buscar productos",
    },
    results: {
        id: `${base}.results`,
        defaultMessage: "Resultados",
    },
    moreItems: {
        id: `${base}.moreItems`,
        defaultMessage: "Más items",
    },
});
