import { getStoredData, StoreKey } from "..";

import {
    ADD_SELLER,
    INVALID_REQUEST_SELLERS,
    ISeller,
    ISellerState,
    RECEIVE_SELLERS,
    REQUEST_SELLERS,
    SellerTypes,
    UPDATE_SELLER,
} from "../types/seller";

export class InitStateBase implements ISellerState {
    items: ISeller[] = [];
    error: any = undefined;
    isFetching: boolean = false;
    isError: boolean = false;
}

export const initState = getStoredData<ISellerState>(new InitStateBase(), StoreKey.organization);

export const sellerReducer = (state: ISellerState, action: SellerTypes): ISellerState => {
    switch (action.type) {
        case ADD_SELLER:
            return {
                ...state,
                items: [...state.items, action.value],
            };
        case UPDATE_SELLER:
            let newlist: ISeller[] = [];

            state.items.forEach((item) => {
                if (item.id === action.value.id) {
                    newlist.push(action.value);
                } else {
                    newlist.push(item);
                }
            });

            return {
                ...state,
                items: newlist,
            };
        case RECEIVE_SELLERS:
            return {
                ...state,
                isFetching: false,
                isError: false,
                items: action.items,
            };
        case REQUEST_SELLERS:
            return {
                ...state,
                isFetching: true,
                isError: false,
            };
        case INVALID_REQUEST_SELLERS:
            return {
                ...state,
                isFetching: false,
                isError: true,
                error: action.value,
            };
        default:
            return state;
    }
};
