import { Dispatch } from "react";
import { IAccountUserState } from "../reducers/accountUser";
import {
    AccountUserTypes,
    FETCH_INVALID,
    IAccountUser,
    IFetchInvalid,
    IReceiveAccountUser,
    RECEIVE_ACCOUNT_USER,
    REQUEST_ACCOUNT_USER,
} from "../types/accountUser";

export interface IAccountUserActions {
    requestAccountUser: (accountUserId: string) => void;
    receiveAccountUser: (user: IAccountUser) => void;
    fetchInvalid: (error: any) => void;
}

export const actionReceiveAccountUser = (user: IAccountUser): IReceiveAccountUser => {
    return {
        type: RECEIVE_ACCOUNT_USER,
        value: user,
    };
};

export const actionFetchInvalid = (error: any): IFetchInvalid => {
    return {
        type: FETCH_INVALID,
        error,
    };
};

export const useActions = (state: IAccountUserState, dispatch: Dispatch<AccountUserTypes>): IAccountUserActions => ({
    requestAccountUser: (id: string) => {
        dispatch({
            type: REQUEST_ACCOUNT_USER,
            accountUserId: id,
        });
    },
    receiveAccountUser: (user: IAccountUser) => {
        dispatch(actionReceiveAccountUser(user));
    },
    fetchInvalid: (error: any) => {
        dispatch(actionFetchInvalid(error));
    },
});
