import React from "react";
import { LabelContainer } from "./styled";

interface LabelProps {
    title: string;
    value?: string | number;
}

export default function TotalLabel({ title, value }: LabelProps) {
    return (
        <LabelContainer>
            <span className="label-title">{title}</span>
            <span>{value ? value : " - "}</span>
        </LabelContainer>
    );
}
