export const REQUEST_ACCOUNT_USER = "REQUEST_ACCOUNT_USER";
export const RECEIVE_ACCOUNT_USER = "RECEIVE_ACCOUNT_USER";
export const FETCH_INVALID = "FETCH_INVALID";

export interface IAccount {
    id: string;
    dateCreated: string;
    dateUpdateBalance: string;
    balance: number;
    userLicenses: number;
    activeUsers: number;
    defaultPaymentInformationId: string;
    defaultPaymentInformation: string;
    allowLogisticsService: boolean;
    companyId: string;
    company: IAccountCompany;
    accountOwnerId: string;
    status: string;
    plan: string;
    organizations: any[];
    users: Array<IAccountUser>;
}

export interface IAccountCompany {
    id: string;
    name: string;
    profileImage: string;
    coverImage: string;
    category: string;
    companyEmails: string;
    companyPhones: string;
}

export interface IAccountUser {
    id: string;
    accountId: string;
    account?: IAccount;
    image: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
}

export interface IReceiveAccountUser {
    type: typeof RECEIVE_ACCOUNT_USER;
    value: IAccountUser;
}

export interface IRequestAccountUser {
    type: typeof REQUEST_ACCOUNT_USER;
    accountUserId: string;
}

export interface IFetchInvalid {
    type: typeof FETCH_INVALID;
    error: any;
}

export type AccountUserTypes = IReceiveAccountUser | IRequestAccountUser | IFetchInvalid;
