import styled from "styled-components";
import { IThemePalette } from "../../theme/themePalette";
import { SquareButtonOutline } from "../controls";

interface IWahioModalProps {
    show: boolean;
    theme: {
        palette: IThemePalette;
    };
}
export const WahioModal = styled.div`
    display: ${(props: IWahioModalProps) => (props.show ? "grid" : "none")};
    position: fixed;
    z-index: 90;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: auto;
    background: ${(props) => props.theme.palette.backgroundModal};

    .wahio-modal-sm {
        max-width: 500px;
    }
    .wahio-modal-md {
        max-width: 700px;
    }
    .wahio-modal-auto {
        max-width: fit-content;
    }
    .wahio-modal-lg {
        max-width: 100%;
        max-width: 1200px;
        overflow: auto;
    }

    .wahio-modal-content-auto-scroll {
        overflow-y: auto;
        max-height: calc(100vh - 180px);
    }

    .m-auto {
        margin: auto;
        box-sizing: border-box;
    }

    .overflow-auto {
        overflow: auto !important;
    }
`;

export const WahioModalBodyParent = styled.div`
    margin: 0px auto;
    width: 100%;
    animation-name: modalopen;
    animation-duration: 0.2s;
    display: flow-root;
    padding: 1rem;
    box-sizing: border-box;
    height: fit-content;
`;

export const WahioModalBody = styled.div`
    background: ${(props) => props.theme.palette.background};
    box-shadow: 1px 2px 12px ${(props) => props.theme.palette.shadowDark};
    border-radius: ${(props: { borderRadius?: number }) => (props.borderRadius ? props.borderRadius + "px" : "20px")};
    ${(props: { borderRadius?: number }) => (props.borderRadius ? "overflow: hidden;" : "")}
    box-sizing: border-box;
`;

export const ModalHeader = styled.div`
    position: relative;
    border-bottom: 1px solid ${(props) => props.theme.palette.backgroundDark};

    button {
        border-color: transparent;
    }
`;

export const CloseContainer = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    padding-right: 0.9rem;
`;

export const ButtonClose = styled(SquareButtonOutline)`
    height: 35px;
    width: 35px;
    top: 50%;
    position: relative;
    transform: translateY(-50%);
`;

export const ModalTitle = styled.div`
    box-sizing: border-box;
    font-size: 1.2rem;
    font-weight: 500;
    width: 100%;
    text-align: center;
    padding: 0.9rem;
`;

export const SingleInputContainer = styled.form`
    display: flex;
    padding: 15px;
    width: 100%;
    flex-direction: column;
    box-sizing: border-box;
    input {
        width: 100%;
        margin-bottom: 10px;
    }
    .button-option {
        display: flex;
        justify-content: flex-end;
    }
`;

export const ModalConfirmationBody = styled.div`
    padding: 30px 24px;
    text-align: center;
    .title {
        margin: 0;
        margin-bottom: 4px;
    }
    .description {
        margin: 0;
    }
    .btns {
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }
`;
