import React from "react";
import Modal, { IModalShow } from "../modals/Modal";
import FinalizeOrderForm, { FinalizeOrderFormProps } from "./FinalizeOrderForm";

interface ModalFinalizeOrderProps extends IModalShow, FinalizeOrderFormProps {}

export default function ModalFinalizeOrder(props: ModalFinalizeOrderProps) {
    return (
        <Modal {...props} id="modal-order" scrollInside title={"Finalizar"} header>
            <FinalizeOrderForm {...props} />
        </Modal>
    );
}
