import { Dispatch } from "react";

import { CustomerTypes } from "../types/customer";

export const applyMiddleware = (dispatch: Dispatch<CustomerTypes>) => (action: CustomerTypes) => {
    switch (action.type) {
       
        default:
            dispatch(action);
    }
};
