import { Dispatch } from "react";
import {
    AddProductCompany,
    ADD_PRODUCT_COMPANY,
    ProductCompanyTypes,
    IProductCompany,
    IProductCompanyState,
    InvalidRequest,
    INVALID_REQUEST_PRODUCT_COMPANIES,
    ReceiveProductCompanies,
    RECEIVE_PRODUCT_COMPANIES,
    RequestProductCompanies,
    REQUEST_PRODUCT_COMPANIES,
    UpdateProductCompany,
    UPDATE_PRODUCT_COMPANY,
} from "../types/productCompany";

export interface IProductCompanyActions {
    request: () => void;
    receive: (items: IProductCompany[]) => void;
    fetchInvalid: (error: any) => void;
    add: (productCompany: IProductCompany) => void;
    update: (productCompany: IProductCompany) => void;
}

export const actionRequest = (): RequestProductCompanies => {
    return {
        type: REQUEST_PRODUCT_COMPANIES,
    };
};

export const actionReceive = (items: IProductCompany[]): ReceiveProductCompanies => {
    return {
        type: RECEIVE_PRODUCT_COMPANIES,
        items,
    };
};

export const actionFetchInvalid = (er: any): InvalidRequest => {
    return {
        type: INVALID_REQUEST_PRODUCT_COMPANIES,
        value: er,
    };
};

export const ActionAdd = (productCompany: IProductCompany): AddProductCompany => {
    return {
        type: ADD_PRODUCT_COMPANY,
        value: productCompany,
    };
};

export const ActionUpdate = (productCompany: IProductCompany): UpdateProductCompany => {
    return {
        type: UPDATE_PRODUCT_COMPANY,
        value: productCompany,
    };
};

export const useActions = (state: IProductCompanyState, dispatch: Dispatch<ProductCompanyTypes>): IProductCompanyActions => ({
    request: () => {
        dispatch(actionRequest());
    },
    receive: (items: IProductCompany[]) => {
        dispatch(actionReceive(items));
    },
    fetchInvalid: (error: any) => {
        dispatch(actionFetchInvalid(error));
    },
    add: (value: IProductCompany) => {
        dispatch(ActionAdd(value));
    },
    update: (value: IProductCompany) => {
        dispatch(ActionUpdate(value));
    },
});
