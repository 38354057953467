import { getRandomString } from "../../utils";
import { WahioCloudItemType } from "./wahioCloud";

export type PaymentMethodType =
    | "invalid"
    | "cash"
    | "transfer"
    | "creditCard"
    | "debitCard"
    | "giftCard"
    | "deposit"
    | "online"
    | "orderReturn";

export const paymentMethodList: PaymentMethodType[] = ["cash", "transfer", "creditCard", "debitCard", "giftCard", "deposit", "online"];

export const paymentMethodCashList: PaymentMethodType[] = ["cash"];
export const paymentMethodBankList: PaymentMethodType[] = ["debitCard", "creditCard", "transfer", "deposit", "online"];

export interface SelectBaseOption {
    id: string;
    label: string;
    value: string;
}

export interface IUniqueAttribute {
    id: string;
    value: string;
}

export interface WahioFile {
    id: string; //GENERATE IN THE UI
    url: string;
    type?: WahioCloudItemType;
    dateCreated?: Date;
    index: number;
    name: string;
}

export class WahioFileImpl implements WahioFile {
    id: string;
    url: string;
    type?: WahioCloudItemType;
    dateCreated?: Date | undefined;
    index: number;
    name: string = "";

    constructor(url: string, type?: WahioCloudItemType) {
        this.id = getRandomString();
        this.url = url;
        this.type = type;
        this.index = 0;
    }
}

export interface SelectBaseOption {
    id: string;
    label: string;
    value: string;
}

export interface SelectBaseOptionColor extends SelectBaseOption {
    color: string;
}

export interface IPaginationRequest {
    currentPage: number;
    pageSize: number;
}

export interface IPageRequestAccount extends IPaginationRequest {
    accountId: string;
    valueId: string;
}
export class PageRequestAccount implements IPageRequestAccount {
    accountId: string;
    valueId: string;
    currentPage: number = 1;
    pageSize: number = 20;

    constructor(accountId?: string, valueId?: string, currentPage?: number, pageSize?: number) {
        this.accountId = accountId ? accountId : "";
        this.valueId = valueId ? valueId : "";
        this.currentPage = currentPage ? currentPage : 1;
        this.pageSize = pageSize ? pageSize : 20;
    }
}
export interface IPaginationList extends IPaginationRequest {
    totalPages: number;
    totalItems: number;
}
export class PaginationRequest implements IPaginationRequest {
    currentPage: number = 1;
    pageSize: number = 20;
}

export interface IPaginationResponse<T> extends IPaginationList {
    items: T[];
}

export class PaginationResponse<T> implements IPaginationResponse<T> {
    items: T[] = [];
    currentPage: number = 1;
    pageSize: number = 20;
    totalPages: number = 0;
    totalItems: number = 0;
    constructor(pagSize?: number) {
        if (pagSize) {
            this.pageSize = pagSize;
        }
    }
}

export class UniqueAttribute implements IUniqueAttribute {
    id: string;
    value: string;
    constructor(id: string, value: string) {
        this.id = id;
        this.value = value;
    }
}
export interface IImageSaveResult {
    status: number;
    success: Boolean;
    url: string;
    name?: string;
}
export interface IBusinessException {
    status: number;
    code: string;
    message: string;
    type: string;
}

export interface IGenericState<T> {
    items: T[];
    error: any;
    isFetching: boolean;
    isError: boolean;
}

export class BusinessException implements IBusinessException {
    code: string = "";
    message: string = "";
    type: string = "";
    status: number;

    constructor(code: string = "", message: string = "", type: string = "", status?: number) {
        this.code = code;
        this.message = message;
        this.type = type;
        this.status = status ? status : 0;
    }
}
export const createBusinessException = (errorObject: any) => {
    const data = errorObject.data ? errorObject.data : {};
    return new BusinessException(data.code, data.message, data.type, errorObject.statusCode);
};
export const ERROR_UPLOAD_IMAGE = "ERROR_UPLOAD_IMAGE";

export interface DateBaseFilter {
    accountId: string;
    organizationId: string;
    minutesOffset: number;
}

export interface DateFilter extends DateBaseFilter {
    startDate: Date;
    endDate: Date;
    limit: number;
}
export interface DateFilterYear extends DateBaseFilter {
    year: number;
}
