import React, { createContext, useReducer, useEffect } from "react";
import { saveData, StoreKey } from "..";
import { ICustomCategoryActions, useActions } from "../actions/customCategory";
import { applyMiddleware } from "../middlewares/customCategory";
import { initState, customCategoryReducer } from "../reducers/customCategory";
import { ICustomCategoryState } from "../types/customCategory";

interface IContextProps {
    customCategoryState: ICustomCategoryState;
    customCategoryActions: ICustomCategoryActions;
}

export const CustomCategoryContext = createContext({} as IContextProps);

const CustomCategoryContextProvider = (props: any) => {
    const [customCategoryState, dispatch] = useReducer(customCategoryReducer, initState);

    useEffect(() => {
        saveData(StoreKey.customCategory, customCategoryState);
    }, [customCategoryState]);

    const customCategoryActions = useActions(customCategoryState, applyMiddleware(dispatch));

    return (
        <CustomCategoryContext.Provider value={{ customCategoryState, customCategoryActions }}>
            {props.children}
        </CustomCategoryContext.Provider>
    );
};

export default CustomCategoryContextProvider;
