import { Dispatch } from "react";
import {
    IWarehouse,
    IWarehouseState,
    WarehouseTypes,
    ReceiveWarehouses,
    RECEIVE_WAREHOUSES,
    RequestWarehouses,
    REQUEST_WAREHOUSES,
    InvalidRequest,
    INVALID_REQUEST_WAREHOUSES,
} from "../types/warehouse";

export interface IWarehouseActions {
    request: () => void;
    receive: (items: IWarehouse[]) => void;
    fetchInvalid: (error: any) => void;
}

export const actionRequest = (): RequestWarehouses => {
    return {
        type: REQUEST_WAREHOUSES,
    };
};

export const actionReceive = (items: IWarehouse[]): ReceiveWarehouses => {
    return {
        type: RECEIVE_WAREHOUSES,
        items,
    };
};

export const actionFetchInvalid = (er: any): InvalidRequest => {
    return {
        type: INVALID_REQUEST_WAREHOUSES,
        value: er,
    };
};

export const useActions = (state: IWarehouseState, dispatch: Dispatch<WarehouseTypes>): IWarehouseActions => ({
    request: () => {
        dispatch(actionRequest());
    },
    receive: (items: IWarehouse[]) => {
        dispatch(actionReceive(items));
    },
    fetchInvalid: (error: any) => {
        dispatch(actionFetchInvalid(error));
    },
});
