import styled from "styled-components";

export const DropdownContainer = styled.div`
    display: flex;
    position: relative;
    width: fit-content;
    .dropdown-title {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .dropdown-image {
        min-width: 35px;
        width: 35px;
        height: 35px;
        margin-right: 10px;
        border-radius: 40px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .dropdown-btn {
        justify-content: flex-start;
        width: 100%;
    }

    .dropdown-right-icon {
        margin: 0px;
    }
`;

export const DropdownContent = styled.div`
    background: ${(props) => props.theme.palette.background};
    border-radius: 15px;
    position: fixed;
    overflow: auto;
    max-width: 320px;
    max-height: 550px;
    width: max-content;
    z-index: 10;
    box-shadow: 2px 2px 10px 0px ${(props) => props.theme.palette.shadowDark};

    height: 0;
    opacity: 0;
    transition: height 0ms 400ms, opacity 400ms 0ms;

    .dropdown-ul {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 10px;

        li {
            padding: 6px 12px;
            margin-bottom: 2px;
            list-style: none;
            border-radius: 10px;
            display: flex;
            align-items: center;
            cursor: pointer;
            &:hover {
                background: ${(props) => props.theme.palette.backgroundLight};
            }

            .item-image {
                min-width: 35px;
                width: 35px;
                height: 35px;
                margin-right: 10px;
                border-radius: 40px;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .icon {
                width: 36px;
                font-size: 14px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                background: ${(props) => props.theme.palette.backgroundLight};
                color: ${(props) => props.theme.palette.textLight};
                height: 25px;
                width: 25px;
                font-size: 13px;
                border-radius: 31px;
                margin-right: 10px;
            }
        }
    }
`;

export const DropdownBackground = styled.div`
    background: pink;
    opacity: 0.4;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
`;
