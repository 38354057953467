import styled from "styled-components";

const CardMarginDesign = styled.div`
    &.margin {
        justify-content: center;

        padding: 15px 15px;
        background: ${(props) => props.theme.palette.backgroundLight};
        border-radius: 10px;
        margin: 6px 10px;

        .btn-address {
            background-color: transparent;
            border-color: ${(props) => props.theme.palette.text}30;
            &:hover {
                background-color: ${(props) => props.theme.palette.background};
            }
        }
        .select__control {
            background-color: transparent;
            border-color: ${(props) => props.theme.palette.text}30;

            &.select__control--menu-is-open {
                border-color: ${(props) => props.theme.palette.backgroundDark};
                border-color: ${(props) => props.theme.palette.primary};
                background-color: ${(props) => props.theme.palette.background};
            }
            &:hover {
                background-color: ${(props) => props.theme.palette.background};
            }
        }

        .textbox-numeral {
            background-color: transparent;
            border-color: ${(props) => props.theme.palette.text}30;
            &:focus {
                background-color: ${(props) => props.theme.palette.background};
                border-color: ${(props) => props.theme.palette.primary};
            }
            &:hover {
                background-color: ${(props) => props.theme.palette.background};
            }
        }
    }
    .label {
        font-size: 0.9rem;
        color: ${(props) => props.theme.palette.textLight};
        margin-bottom: 5px;
    }
`;
export const DetailCard = styled(CardMarginDesign)`
    display: flex;
    flex-direction: column;

    .width-limit {
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
    }
    .textbox-numeral {
        padding: 6px;
        border-radius: 20px;
    }
    .card-input {
        border-radius: 20px;
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        background: ${(props) => props.theme.palette.background};
    }
    .card-btn {
        background: transparent;
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        color: ${(props) => props.theme.palette.textLight};
        &:hover {
            background: ${(props) => props.theme.palette.background};
        }
    }
`;

export const SelectBody = styled(CardMarginDesign)`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    .light {
        font-size: 0.9rem;
        color: ${(props) => props.theme.palette.textLight};
    }

    &.fit-content {
        width: fit-content;
        max-width: 500px;
    }

    .select__multi-value {
        border-radius: 30px;
    }

    .select__multi-value__label {
        font-weight: 500;
        font-size: 1rem;
    }

    &.multi-value-theme-color {
        .select__multi-value {
            background: ${(props) => props.theme.palette.backgroundDark};
        }
        .select__multi-value__label {
            color: ${(props) => props.theme.palette.text};
            font-size: 1rem;
        }
    }
    .select__menu {
        top: 100%;
        z-index: 200;
        border-radius: 8px;
        box-shadow: 1px 2px 9px ${(props) => props.theme.palette.shadowDark};
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        overflow: hidden;
    }
    .select__indicator {
        padding: 4px 8px;
    }
    .select__control {
        border-radius: 16px;
        background-color: ${(props) => props.theme.palette.background};
        border-color: ${(props) => props.theme.palette.backgroundDark};
        color: ${(props) => props.theme.palette.text};
        min-height: 32px;
        &.select__control--menu-is-open {
            border-color: ${(props) => props.theme.palette.backgroundDark};
        }
    }

    &.border-none {
        .select__control {
            border-radius: 5px;
            border-color: transparent;
            background-color: transparent;
        }
    }

    .select__single-value {
        color: ${(props) => props.theme.palette.text};
    }
    .select__input-container {
        color: ${(props) => props.theme.palette.textLight};
    }
    .select__option--is-focused {
        background-color: ${(props) => props.theme.palette.primary}40;
        color: ${(props) => props.theme.palette.text};
        &.select__option--is-selected {
            background-color: ${(props) => props.theme.palette.primaryDark};
        }
    }
    .select__option--is-selected {
        background-color: ${(props) => props.theme.palette.primary};
        color: white;
    }
    .select__menu-list {
        background: ${(props) => props.theme.palette.background};
        padding: 0px;
    }
    .select__indicator-separator {
        background: ${(props) => props.theme.palette.backgroundDark};
    }

    .react-select-basic {
        min-width: 200px;
    }

    .select-min-len {
        min-width: 100px;
    }
    .select-flex {
        .select__control {
            display: flex;
        }
    }
    &.bg-light {
        .select__control {
            background: ${(props) => props.theme.palette.backgroundDark};
        }
    }
    .select-small {
        .select__control {
            min-height: 20px;
        }
        .select__value-container {
            padding: 0px;
            padding-left: 4px;
            margin: 0px;
        }
        .select__input-container {
            padding: 0px;
            margin: 0px;
        }
        .select__indicators {
            height: 20px;
        }
    }
`;
