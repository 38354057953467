import { getStoredData, StoreKey } from "..";
import { IPaginationResponse, PaginationResponse } from "../types";
import { ADD_PURCHASE, PurchaseTypes, IPurchase, IPurchaseState, INVALID_REQUEST_PURCHASES, RECEIVE_PURCHASES, REQUEST_PURCHASES, UPDATE_PURCHASE } from "../types/purchase";

export class InitStateBase implements IPurchaseState {
    pagination: IPaginationResponse<IPurchase> = new PaginationResponse<IPurchase>();
    error: any = undefined;
    isFetching: boolean = false;
    isError: boolean = false;
}

export const initState = getStoredData<IPurchaseState>(new InitStateBase(), StoreKey.purchase);

export const purchaseReducer = (state: IPurchaseState, action: PurchaseTypes): IPurchaseState => {
    switch (action.type) {
        case ADD_PURCHASE:
            const newitems = [...state.pagination.items, action.value];
            return {
                ...state,
                pagination: { ...state.pagination, items: newitems },
            };
        case UPDATE_PURCHASE:
            let newlist: IPurchase[] = [];

            state.pagination.items.forEach((item) => {
                if (item.id === action.value.id) {
                    newlist.push(action.value);
                } else {
                    newlist.push(item);
                }
            });

            return {
                ...state,
                pagination: { ...state.pagination, items: newlist },
            };
        case RECEIVE_PURCHASES:
            return {
                ...state,
                isFetching: false,
                isError: false,
                pagination: action.value,
            };
        case REQUEST_PURCHASES:
            return {
                ...state,
                isFetching: true,
                isError: false,
            };
        case INVALID_REQUEST_PURCHASES:
            return {
                ...state,
                isFetching: false,
                isError: true,
                error: action.value,
            };
        default:
            return state;
    }
};
