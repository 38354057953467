import React, { useContext, useEffect, useState } from "react";
import AddressFormModal from "../AddressFormModal";
import { SquareButton } from "../controls";
import { useAlert } from "react-alert";
import { DeliveryOrder } from "../../store/types/deliveryOrder";
import { DetailsBody, IHideProps, RowContainer, DeliveryOptionContainer } from "./styled";
import { ISale } from "../../store/types/sale";
import { ICustomerAddress, ICustomer } from "../../store/types/customer";
import { CurrentCustomerContext } from "../../store/contexts/CurrentCustomerContext";
import { CurrentProductCatalogContext } from "../../store/contexts/CurrentProductCatalogContext";
import DropdownList from "../Dropdown/DropdownList";
import { getContactAddressText, getServiceMessageError } from "./helpers";
import LoadingDualRing from "../LoadingDualRing";
import { useMutation } from "react-query";
import { updateContact } from "../../api/contactApi/index";

export interface OrderDetailsProps extends IHideProps {
    onUpdateSale: (value: ISale) => void;
    currentSale: ISale;
    hideDelivery?: boolean;
}

export default function OrderDetails(props: OrderDetailsProps) {
    const alert = useAlert();
    const [showAddressModal, setShowAddressModal] = useState(false);
    const { currentCustomerState } = useContext(CurrentCustomerContext);
    const { currentProductCatalogState } = useContext(CurrentProductCatalogContext);
    const { currentSale } = props;
    const customer = currentCustomerState.customer;

    const contactMutation = useMutation((contact: ICustomer) => updateContact(contact), {
        onSuccess: (contact) => {
            alert.success("Dirección creada Correctamente");
            props.onUpdateSale({ ...currentSale, customer: contact });
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });

    useEffect(() => {
        if (customer && customer.addreses && customer.addreses.length > 0) {
            onAddressChange(customer.addreses[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDeliveryOrder = () =>
        currentSale.deliveryOrder
            ? currentSale.deliveryOrder
            : new DeliveryOrder(currentProductCatalogState.productCatalog?.accountId ?? "", "");

    const onAddressChange = (value: ICustomerAddress) => {
        let deliveryOrder = getDeliveryOrder();
        deliveryOrder = { ...deliveryOrder, ...(value as any), id: deliveryOrder.id, address: value.description };
        props.onUpdateSale({ ...currentSale, deliveryOrder });
    };

    const handleCreateNewContactAddress = async (newAddress: ICustomerAddress) => {
        let customer = currentSale.customer;
        if (customer) {
            customer = { ...customer, addreses: [...(customer.addreses ?? []), newAddress] };
            await contactMutation.mutateAsync(customer);
            onAddressChange(newAddress);
        }
    };

    const getAddress = () => {
        let deli = currentSale.deliveryOrder;
        if (deli) {
            return `${deli.description}, ${deli.state}, ${deli.city}`;
        }
        return "";
    };

    const currentAddress = getAddress();
    return (
        <>
            <DetailsBody>
                {showAddressModal && (
                    <AddressFormModal
                        {...{
                            show: showAddressModal,
                            setShow: setShowAddressModal,
                            onChange: onAddressChange,
                            address: { ...(currentSale.deliveryOrder as any) },
                            onChangeAndCreate: handleCreateNewContactAddress,
                        }}
                    />
                )}
                <DeliveryOptionContainer>
                    <RowContainer>
                        <span className="light wahioicon-map-marker-alt icon mr-1"></span>
                        <div>
                            <p className={`${currentAddress ? "" : "light"}`}>{currentAddress}</p>
                        </div>
                    </RowContainer>
                    {contactMutation.isLoading && <LoadingDualRing className="ml-1 mr-1" small />}
                    <SquareButton onClick={() => setShowAddressModal(true)} className="small">
                        <span className="wahioicon-pen"></span>
                    </SquareButton>
                </DeliveryOptionContainer>
            </DetailsBody>
            <div>
                {customer?.addreses && customer.addreses.length > 0 && (
                    <DropdownList
                        title="Elegir una dirección existente"
                        onClickItem={(value) => {
                            if (value.object) onAddressChange(value.object);
                        }}
                        onRemoveItem={() => {}}
                        items={customer.addreses.map((address) => {
                            return { id: address.id ?? " ", title: getContactAddressText(address), object: address };
                        })}
                    />
                )}
            </div>
        </>
    );
}
