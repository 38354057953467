import React, { useState } from "react";
import { useIntl } from "react-intl";
import { ICustomerAddress } from "../../store/types/customer";
import { PrimaryButton } from "../controls";
import messages from "./messages";
import { ButtonsContainer, FormFieldsContainer } from "../controls/formStyled";

import SimpleModalFixed, { IModalShow } from "../modals/Modal";
import CustomerAddressForm from "./CustomerAddressForm";

export interface AddressFormProps extends IModalShow {
    onChange: (value: ICustomerAddress) => void;
    address: ICustomerAddress;
    addressList?: ICustomerAddress[];
    onChangeAndCreate: (value: ICustomerAddress) => void;
}

export default function AddressFormModal(props: AddressFormProps) {
    const intl = useIntl();
    const [currentAddress, setCurrentAddress] = useState(props.address);
    const [showCreateNew, setShowCreateNew] = useState(false);

    const onAddressChange = (value: ICustomerAddress) => {
        setShowCreateNew(true);
        setCurrentAddress(value);
    };

    const onSaveChanges = () => {
        props.setShow(false);
        props.onChange(currentAddress);
    };

    const createNewAddress = () => {
        props.setShow(false);
        props.onChangeAndCreate(currentAddress);
        onAddressChange(currentAddress);
    };

    return (
        <SimpleModalFixed
            ignoreOutsideClick
            id="modaladdressform"
            title={intl.formatMessage(messages.updateShippingAddress)}
            header={true}
            {...props}
        >
            <FormFieldsContainer>
                <div>
                    <CustomerAddressForm item={currentAddress} onChange={onAddressChange} />
                </div>

                <ButtonsContainer>
                    {showCreateNew && (
                        <PrimaryButton onClick={createNewAddress}>
                            <span className="wahioicon-save"></span> {intl.formatMessage(messages.saveNewAddress)}
                        </PrimaryButton>
                    )}
                    <PrimaryButton onClick={onSaveChanges}>{intl.formatMessage(messages.saveChanges)}</PrimaryButton>
                </ButtonsContainer>
            </FormFieldsContainer>
        </SimpleModalFixed>
    );
}
