import React, { useEffect } from "react";
import { CustomCustom, DropdownContainer, Title, DropValue } from "./styled";

const ignoreInternalClick = (element: any, result: Result) => {
    let child = element.parentElement;
    if (child) {
        if (child.matches(".dropdown-content")) {
            result.status = true;
            return;
        } else ignoreInternalClick(child, result);
    }
};
class Result {
    status: boolean = false;
}
interface IProps {
    id: string;
    right?: boolean;
    ignoreInputClick?: boolean;
    ignoreInternalClicks?: boolean;
    dropdownClass?: string;
    btnClass?: string;
    children: React.ReactNode;
    startIcon?: string;
    mainIcon?: string;
    title?: string;
    minWidthAuto?: boolean;
    value: any;
    iconName?: string;
    nameNowrap?: boolean;
    smallValueWidth?: boolean;
    rounded?: boolean;
}
const dropdownClickEvent = (options: IProps) => {
    window.onclick = function (event: any) {
        if (!event.target.matches(".dropbtn") && !event.target.matches(".dropbtnspan")) {
            if (options.ignoreInputClick && event.target.tagName === "INPUT") {
                return;
            }
            if (options.ignoreInternalClicks) {
                let value: Result = new Result();
                ignoreInternalClick(event.target, value);
                if (value.status) return;
            }

            var dropdowns = document.getElementsByClassName("dropdown-content");
            for (let i = 0; i < dropdowns.length; i++) {
                var openDropdown = dropdowns[i];
                if (openDropdown.classList.contains("show")) {
                    openDropdown.classList.remove("show");
                }
            }
        }
    };
};

export default function DropdownClassic(props: IProps) {
    useEffect(() => {
        dropdownClickEvent(props);
    }, [props]);
    const dropdownId = `dropdown-${props.id}`;
    const onClickShow = () => {
        const resultDocument = window.document.getElementById(dropdownId);
        if (resultDocument) {
            resultDocument.classList.toggle("show");
        }
    };
    const showToRight = props.right ? "right-0" : "";
    const nowrapname = props.nameNowrap ? "white-space-nowrap" : "";
    const minWidthAuto = props.minWidthAuto ? "min-width-auto" : "";
    const smallValue = props.smallValueWidth ? "smallvalue" : "";
    return (
        <DropdownContainer className={`dropdown ${props.dropdownClass} ${props.rounded ? "rounded" : ""}`}>
            <CustomCustom type="button" className={`dropbtn`} onClick={onClickShow}>
                {props.startIcon && <Title className={props.startIcon}></Title>}
                {props.title && <Title className="dropbtnspan title">{props.title}</Title>}
                {props.value && <DropValue className={`dropbtnspan ${nowrapname} ${smallValue} dropdownvalue`}>{props.value}</DropValue>}
                <span className={`dropbtnspan icon-right ${props.mainIcon ?? "wahioicon-angle-down"}`}></span>
            </CustomCustom>
            <div id={dropdownId} className={`dropdown-content ${showToRight} ${minWidthAuto}`}>
                {props.children}
            </div>
        </DropdownContainer>
    );
}
