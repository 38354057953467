import React, { createContext, useReducer, useEffect } from "react";
import { saveData, StoreKey } from "..";
import { ICustomerActions, useActions } from "../actions/customer";
import { applyMiddleware } from "../middlewares/customer";
import { customerReducer, initState } from "../reducers/customer";
import { ICustomerState } from "../types/customer";

interface IContextProps {
    customerState: ICustomerState;
    customerActions: ICustomerActions;
}

export const CustomerContext = createContext({} as IContextProps);

const CustomerContextProvider = (props: any) => {
    const [customerState, dispatch] = useReducer(customerReducer, initState);

    useEffect(() => {
        saveData(StoreKey.customer, customerState);
    }, [customerState]);

    const customerActions = useActions(customerState, applyMiddleware(dispatch));

    return <CustomerContext.Provider value={{ customerState, customerActions }}>{props.children}</CustomerContext.Provider>;
};

export default CustomerContextProvider;
