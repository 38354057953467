import appconfig from "./appconfig";
import { joinUrl } from "../utils";
import { IPaginationRequest } from "../store/types";

const api = appconfig.API_GATEWAY_ENDPOINT;

const getPageRoute = (req: IPaginationRequest) => {
    return `page/${req.currentPage}/limit/${req.pageSize}`;
};

export const loginEndpoint = {
    login: joinUrl(api, `login/api/login`),
};
export const customerEndpoint = {
    get: {
        customersByAccount: joinUrl(api, "customer/api/customers/account/"),
        customer: joinUrl(api, "customer/api/customers/"),
    },
    post: {
        customer: joinUrl(api, `customer/api/customers`),
    },
};
export const localorderEndpoint = {
    get: {
        orderById: (orderId: string) => joinUrl(api, `localorder/orders/${orderId}`),
        deliverySummaryByAccount: (accountId: string) => joinUrl(api, `localorder/orderdeliveries/summary/${accountId}`),
        customersById: (id: string) => joinUrl(api, `localorder/customers/${id}`),
    },
    post: {
        orderTable: joinUrl(api, "localorder/ordertables"),
        seller: joinUrl(api, "localorder/sellers"),
        loungeAll: joinUrl(api, "localorder/lounges/update-all"),
        customer: joinUrl(api, "localorder/customers"),
        customerSearch: joinUrl(api, "localorder/customers/search"),
        singlePayment: joinUrl(api, "localorder/payments"),
        orderUpdateCreateAll: joinUrl(api, "localorder/orders/updateall"),
        getOrderQuerySearch: joinUrl(api, "localorder/orders/querysearch"),
        getDeliveryOrderQuerySearch: joinUrl(api, "localorder/orderdeliveries/querysearch"),
    },
    put: {
        seller: joinUrl(api, "localorder/sellers"),
        customerAll: joinUrl(api, "localorder/customers/update-all"),
        markOrderAsDelivered: (orderDeliveryId: string) => joinUrl(api, `localorder/orderdeliveries/delivered/${orderDeliveryId}`),
    },
};
export const productEndpoint = {
    get: {
        product: (id: string) => joinUrl(api, `product/products/${id}/`),
        productCatalogById: (id: string) => joinUrl(api, `product/productcatalog/${id}/`),
        warehousesByAccount: (accountId: string) => joinUrl(api, `product/warehouses/account/${accountId}`),
        companiesByAccount: (accountId: string) => joinUrl(api, `product/companies/account/${accountId}`),
        warehouse: (id: string) => joinUrl(api, `product/warehouses/${id}`),
        categories: joinUrl(api, "product/categories"),
        customCategories: (accountId: string) => joinUrl(api, `product/categories/account/${accountId}`),
    },
    post: {
        productSearchQuery: joinUrl(api, "product/products/account/querysearch"),
        warehouseProductSearchQuery: joinUrl(api, "product/warehouseproducts/querysearch"),
        warehouseProductCategoriesSearchQuery: joinUrl(api, "product/warehouseproducts/querysearch/categories"),
        warehouseProductCategoryGroupSearchQuery: joinUrl(api, "product//warehouseproducts/querysearch/categorygroup"),
        product: joinUrl(api, "product/products/updateall"),
        warehouse: joinUrl(api, "product/warehouses"),
        categories: joinUrl(api, "product/categories"),
        customCategory: joinUrl(api, "product/categories/custom"),
        productImagesCreateList: joinUrl(api, "product/productimages/uploadlist"),
        deleteProductImagesList: joinUrl(api, "product/productimages/removelist"),
    },
    put: {
        categoryName: joinUrl(api, `product/api/categories/updatename`),
    },
    datele: {
        category: joinUrl(api, `product/api/categories/`),
    },
};

export const purchaseEndpoint = {
    get: {
        purchaseByidAll: (id: string) => joinUrl(api, `purchase/purchases/all/${id}`),
        suppliersByAccount: (accountId: string) => joinUrl(api, `purchase/suppliers/account/${accountId}`),
        purchasesPages: (req: IPaginationRequest) => joinUrl(api, `purchase/purchases/` + getPageRoute(req)),
    },
    post: {
        supplier: joinUrl(api, `purchase/suppliers`),
        purchaseAll: joinUrl(api, `purchase/purchases/updateall`),
        singlePayment: joinUrl(api, `purchase/payments`),
        purchaseSearchQuery: joinUrl(api, `purchase/purchases/querysearch`),
    },
};
export const accountEndpoint = {
    get: {
        accountUserWithAccount: (accountuserId: string) => joinUrl(api, `account/accountusers/useraccount/${accountuserId}`),
        accountUserById: (accountuserId: string) => joinUrl(api, `account/accountusers/${accountuserId}`),
        accounts: joinUrl(api, `account/accounts`),
        accountById: (id: string) => joinUrl(api, `account/accounts/${id}`),
        exceptions: joinUrl(api, `account/exceptions`),
        organizations: joinUrl(api, `account/organizations`),
        organizationsById: (id: string) => joinUrl(api, `account/organizations/${id}`),
        organizationsByUser: (accountuserId: string) => joinUrl(api, `account/organizations/user/${accountuserId}`),
        accountUsersByAccount: joinUrl(api, `account/accountusers/account/`),
    },
    post: {
        organizationImage: joinUrl(api, `account/organizations/uploadimage`),
        organization: joinUrl(api, `account/organizations`),
        newAccountCompany: joinUrl(api, `account/accountusers/newaccountcompany`),
    },
    put: {
        organizationName: joinUrl(api, `account/organizations/updatename`),
        organizationAddress: joinUrl(api, `account/organizations/updateaddress`),
        organizationWarehosue: joinUrl(api, `account/organizations/updatewarehouse`),
    },
};
export const permissionEndpoint = {
    get: {
        permissionsByAccount: joinUrl(api, `permission/api/permissions/account`),
    },
};
export default { accountEndpoint, loginEndpoint };
