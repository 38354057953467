import { getStoredData, StoreKey } from "..";
import { OrderStatusOptionsType } from "../types/order";

import { ICurrentSaleState, ISale, Sale, SaleTypes, UPDATE_CURRENT_SALE } from "../types/sale";

export const emptySale = (status?: OrderStatusOptionsType) => {
    let order = new Sale("", "", "");
    order.status = status ?? "draft";
    order.isDelivery = true;
    //order.deliveryOrder =
    return order;
};

export class InitStateBase implements ICurrentSaleState {
    currentSale: ISale = emptySale();
}

export const initState = getStoredData<ICurrentSaleState>(new InitStateBase(), StoreKey.currentSale);

export const currentSaleReducer = (state: ICurrentSaleState, action: SaleTypes): ICurrentSaleState => {
    switch (action.type) {
        case UPDATE_CURRENT_SALE:
            return {
                ...state,
                currentSale: action.value,
            };

        default:
            return state;
    }
};
