import { Dispatch } from "react";

import { wahioFetch } from "../../services";
import { purchaseEndpoint } from "../../services/restApiEndpoint";
import { actionFetchInvalid, actionReceive, actionRequest } from "../actions/supplier";
import { REQUEST_SUPPLIERS, SupplierTypes } from "../types/supplier";

export const applyMiddleware = (dispatch: Dispatch<SupplierTypes>) => (action: SupplierTypes) => {
    switch (action.type) {
        case REQUEST_SUPPLIERS:
            dispatch(actionRequest());
            
            return wahioFetch.get(
                purchaseEndpoint.get.suppliersByAccount(''),
                (success) => {
                    dispatch(actionReceive(success.data));
                },
                (error) => {
                    dispatch(actionFetchInvalid(error));
                }
            );
        default:
            dispatch(action);
    }
};
