import React, { createContext, useReducer, useEffect } from "react";
import { saveData, StoreKey } from "..";
import { IProductCatalogActions, useActions } from "../actions/currentProductCatalog";
import { applyMiddleware } from "../middlewares/currentProductCatalog";

import { currentProductCatalogReducer, initState } from "../reducers/currentProductCatalog";
import { ICurrentProductCatalogState } from "../types/productCatalog";

export interface ICurrentProductCatalogContextProps {
    currentProductCatalogState: ICurrentProductCatalogState;
    currentProductCatalogActions: IProductCatalogActions;
}

export const CurrentProductCatalogContext = createContext({} as ICurrentProductCatalogContextProps);

const CurrentProductCatalogContextProvider = (props: any) => {
    const [currentProductCatalogState, dispatch] = useReducer(currentProductCatalogReducer, initState);

    useEffect(() => {
        saveData(StoreKey.currentProductCatalog, currentProductCatalogState);
    }, [currentProductCatalogState]);

    const currentProductCatalogActions = useActions(currentProductCatalogState, applyMiddleware(dispatch));

    return (
        <CurrentProductCatalogContext.Provider value={{ currentProductCatalogState, currentProductCatalogActions }}>
            {props.children}
        </CurrentProductCatalogContext.Provider>
    );
};

export default CurrentProductCatalogContextProvider;
