import React, { useContext, useState } from "react";
import { ListProductProps } from ".";
import { CurrentProductCatalogContext } from "../../store/contexts/CurrentProductCatalogContext";
import { IWarehouseProduct } from "../../store/types/warehouseProduct";
import { SquareButton } from "../controls";
import ProductCard from "../ProductCard";
import { ProductSquareContainer, ProductCategoryHeader } from "./styled";

export default function ProductCardList(props: ListProductProps) {
    const { currentProductCatalogState } = useContext(CurrentProductCatalogContext);
    const { productCatalog } = currentProductCatalogState;

    const allowAddToCard = productCatalog?.allowOrderWithWhatsapp || !productCatalog?.allowPublicAccess;

    if (props.sort === "category") {
        let categories: { [key: string]: IWarehouseProduct[] } = {};

        const groupByCategory = props.warehouseProducts.reduce((group, warehouseProduct) => {
            const { customCategory } = warehouseProduct.product;
            group[customCategory.name] = group[customCategory.name] ?? [];
            group[customCategory.name].push(warehouseProduct);
            return group;
        }, categories);

        let cards = Object.keys(categories).map((key) => (
            <ProductGroupView {...props} categoryName={key} warehouseProducts={groupByCategory[key]} allowAddToCart={!!allowAddToCard} />
        ));
        return <>{cards}</>;
    }

    return (
        <div>
            <ProductSquareContainer>
                {props.warehouseProducts.map((item, index) => {
                    return (
                        <ProductCard
                            {...props}
                            product={item.product}
                            stock={item.stock - item.reserved}
                            key={index}
                            showStock={props.showStock}
                            showPrice={props.showPrice}
                            allowAddToCart={!!allowAddToCard}
                        />
                    );
                })}
            </ProductSquareContainer>
        </div>
    );
}

interface ProductGroupViewProps extends ListProductProps {
    categoryName: string;
    allowAddToCart: boolean;
}

const ProductGroupView = (props: ProductGroupViewProps) => {
    const { categoryName } = props;
    const [show, setShow] = useState(true);

    return (
        <div>
            <ProductCategoryHeader>
                <SquareButton onClick={() => setShow(!show)} className="icon">
                    <span className={!show ? "wahioicon-angle-down" : "wahioicon-angle-up"}></span>
                </SquareButton>
                <h2 className="title-category">{categoryName}</h2>
            </ProductCategoryHeader>
            {show && (
                <ProductSquareContainer>
                    {props.warehouseProducts.map((item, index) => {
                        return (
                            <ProductCard
                                {...props}
                                product={item.product}
                                stock={item.stock - item.reserved}
                                key={index}
                                showStock={props.showStock}
                                allowAddToCart={props.allowAddToCart}
                            />
                        );
                    })}
                </ProductSquareContainer>
            )}
        </div>
    );
};
