import { Dispatch } from "react";

import { wahioFetch } from "../../services";
import { productEndpoint } from "../../services/restApiEndpoint";
import { actionFetchInvalid, actionReceive, actionRequest } from "../actions/warehouse";
import { REQUEST_WAREHOUSES, WarehouseTypes } from "../types/warehouse";

export const applyMiddleware = (dispatch: Dispatch<WarehouseTypes>) => (action: WarehouseTypes) => {
    switch (action.type) {
        case REQUEST_WAREHOUSES:
            dispatch(actionRequest());
            
            return wahioFetch.get(
                productEndpoint.get.warehousesByAccount(''),

                (success) => {
                    dispatch(actionReceive(success.data));
                },
                (error) => {
                    dispatch(actionFetchInvalid(error));
                }
            );
        default:
            dispatch(action);
    }
};
