import { Dispatch } from "react";
import {
    AddSupplier,
    ADD_SUPPLIER,
    InvalidRequest,
    INVALID_REQUEST_SUPPLIERS,
    ISupplier,
    ISupplierState,
    ReceiveSuppliers,
    RECEIVE_SUPPLIERS,
    RequestSuppliers,
    REQUEST_SUPPLIERS,
    SupplierTypes,
    UpdateSupplier,
    UPDATE_SUPPLIER,
} from "../types/supplier";

export interface ISupplierActions {
    request: () => void;
    receive: (items: ISupplier[]) => void;
    fetchInvalid: (error: any) => void;
    add: (supplier: ISupplier) => void;
    update: (supplier: ISupplier) => void;
}

export const actionRequest = (): RequestSuppliers => {
    return {
        type: REQUEST_SUPPLIERS,
    };
};

export const actionReceive = (items: ISupplier[]): ReceiveSuppliers => {
    return {
        type: RECEIVE_SUPPLIERS,
        items,
    };
};

export const actionFetchInvalid = (er: any): InvalidRequest => {
    return {
        type: INVALID_REQUEST_SUPPLIERS,
        value: er,
    };
};

export const ActionAdd = (supplier: ISupplier): AddSupplier => {
    return {
        type: ADD_SUPPLIER,
        value: supplier,
    };
};

export const ActionUpdate = (supplier: ISupplier): UpdateSupplier => {
    return {
        type: UPDATE_SUPPLIER,
        value: supplier,
    };
};

export const useActions = (state: ISupplierState, dispatch: Dispatch<SupplierTypes>): ISupplierActions => ({
    request: () => {
        dispatch(actionRequest());
    },
    receive: (items: ISupplier[]) => {
        dispatch(actionReceive(items));
    },
    fetchInvalid: (error: any) => {
        dispatch(actionFetchInvalid(error));
    },
    add: (value: ISupplier) => {
        dispatch(ActionAdd(value));
    },
    update: (value: ISupplier) => {
        dispatch(ActionUpdate(value));
    },
});
