import { Dispatch } from "react";
import {
    IProductCategory,
    IProductCategoryState,
    ProductCategoryTypes,
    RECEIVE_PRODUCT_CATEGORIES,
    RequestProductCategories,
    REQUEST_PRODUCT_CATEGORIES,
    ReceiveProductCategories,
    InvalidRequest,
    INVALID_REQUEST_PRODUCT_CATEGORIES,
} from "../types/productCategory";

export interface IProductCategoryActions {
    request: () => void;
    receive: (items: IProductCategory[]) => void;
    fetchInvalid: (error: any) => void;
}

export const actionRequest = (): RequestProductCategories => {
    return {
        type: REQUEST_PRODUCT_CATEGORIES,
    };
};

export const actionReceive = (items: IProductCategory[]): ReceiveProductCategories => {
    return {
        type: RECEIVE_PRODUCT_CATEGORIES,
        items,
    };
};

export const actionFetchInvalid = (er: any): InvalidRequest => {
    return {
        type: INVALID_REQUEST_PRODUCT_CATEGORIES,
        value: er,
    };
};

export const useActions = (state: IProductCategoryState, dispatch: Dispatch<ProductCategoryTypes>): IProductCategoryActions => ({
    request: () => {
        dispatch(actionRequest());
    },
    receive: (items: IProductCategory[]) => {
        dispatch(actionReceive(items));
    },
    fetchInvalid: (error: any) => {
        dispatch(actionFetchInvalid(error));
    },
});
