import { getStoredData, StoreKey } from "..";
import { IPaginationResponse, PaginationResponse } from "../types";
import {
    INVALID_REQUEST_WAREHOUSE_PRODUCTS,
    IWarehouseProduct,
    IWarehouseProductState,
    RECEIVE_WAREHOUSE_PRODUCTS,
    REQUEST_WAREHOUSE_PRODUCTS,
    WarehouseProductTypes,
} from "../types/warehouseProduct";

export class InitStateBase implements IWarehouseProductState {
    pagination: IPaginationResponse<IWarehouseProduct> = new PaginationResponse<IWarehouseProduct>();
    error: any = undefined;
    isFetching: boolean = false;
    isError: boolean = false;
}

export const initState = getStoredData<IWarehouseProductState>(new InitStateBase(), StoreKey.warehouseProduct);

const combineProducts = (state: IWarehouseProductState, newvalue: IPaginationResponse<IWarehouseProduct>) => {
    let items = newvalue.items;
    if (state.pagination.currentPage < newvalue.currentPage) items = state.pagination.items.concat(newvalue.items);

    return {
        ...state,
        isFetching: false,
        pagination: { ...newvalue, items },
    };
};

export const warehouseProductReducer = (state: IWarehouseProductState, action: WarehouseProductTypes): IWarehouseProductState => {
    switch (action.type) {
        case RECEIVE_WAREHOUSE_PRODUCTS:
            return combineProducts(state, action.value);
        case REQUEST_WAREHOUSE_PRODUCTS:
            return {
                ...state,
                isFetching: true,
                isError: false,
            };
        case INVALID_REQUEST_WAREHOUSE_PRODUCTS:
            return {
                ...state,
                isFetching: false,
                isError: true,
                error: action.value,
            };
        default:
            return state;
    }
};
