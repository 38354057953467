import styled from "styled-components";
import { IThemePalette } from "../../../theme/themePalette";

interface IButtonRounded {
    rounded?: boolean;
}
interface DefaultButtonProps {
    rounded?: boolean;
    primaryStyle?: boolean;
    theme?: {
        pallete: IThemePalette;
    };
}

export const DefaultButton = styled.button`
    background: ${(props) => props.theme.palette.background};
    outline: transparent;
    padding: 0.5rem 1rem;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    color: ${(props) => props.theme.palette.text};
    font-weight: 500;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    white-space: nowrap;

    [class^="wahioicon"] {
        margin-right: 10px;
    }

    border-radius: ${(props: IButtonRounded) => (props.rounded ? "50px" : "3px")};
    justify-content: center;
    align-items: center;
    &:disabled {
        background: ${(props) => props.theme.palette.backgroundLight};
        color: ${(props) => props.theme.palette.backgroundDark};
        border-color: transparent;
        &:hover {
            background: ${(props) => props.theme.palette.backgroundLight};
            color: ${(props) => props.theme.palette.backgroundDark};
        }
    }
    &:hover {
        transition: background 0.3s;
        background: ${(props) => props.theme.palette.backgroundDark};
    }
`;

export const DefaultButtonNew = styled.button`
    background: ${(props) => props.theme.palette.background};
    outline: transparent;
    padding: 0.5rem 1rem;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    color: ${(props) => props.theme.palette.text};
    font-weight: 500;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    white-space: nowrap;

    [class^="wahioicon"] {
        margin-right: 10px;
    }

    &.small {
        font-size: 0.9rem;
        padding: 5px 11px;
    }
    &.lightColor {
        color: ${(props) => props.theme.palette.textLight};
    }
    border-radius: ${(props: DefaultButtonProps) => (props.rounded ? "50px" : "3px")};
    justify-content: center;
    align-items: center;
    &:disabled {
        background: ${(props) => props.theme.palette.backgroundLight};
        color: ${(props) => props.theme.palette.textLight}70;
        border-color: transparent;
        &:hover {
            background: ${(props) => props.theme.palette.backgroundLight};
            color: ${(props) => props.theme.palette.textLight}70;
        }
    }

    &:hover {
        transition: background 0.3s;
        background: ${(props) => props.theme.palette.backgroundDark};
    }

    &.filled {
        background: ${(props) => props.theme.palette.backgroundDark};

        &:hover {
            transition: background 0.3s;
            color: ${(props) => props.theme.palette.text};
        }
    }
`;







export const DefaultButtonFill = styled(DefaultButton)`
    background: ${(props) => props.theme.palette.backgroundDark};
    &:hover {
        background: ${(props) => props.theme.palette.backgroundDarker};
    }
`;

export const PrimaryButton = styled(DefaultButton)`
    color: white;
    background: ${(props) => props.theme.palette.primary};
    border-color: transparent;
    &:hover {
        background: ${(props) => props.theme.palette.primaryDark};
    }
`;

export const DangerButton = styled(DefaultButton)`
    color: white;
    background: #f44336;
    border-color: transparent;
    &:hover {
        background: #b1261b;
    }
`;

export const SquareButton = styled(DefaultButton)`
    width: 33px;
    height: 33px;
    min-width: 33px;

    &.small {
        width: 25px;
        height: 25px;
        min-width: 25px;
    }

    [class^="wahioicon"] {
        margin-right: 0px;
    }
    padding: 0px;
    border: 1px solid transparent;
    border-radius: 50px;
    outline-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: ${(props) => props.theme.palette.textLight};
    background: ${(props) => props.theme.palette.backgroundDark};
`;

export const SquareButtonOutline = styled(SquareButton)`
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    background: ${(props) => props.theme.palette.background};
    &:hover {
        background: ${(props) => props.theme.palette.backgroundDark};
    }
`;
