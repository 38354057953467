import React, { createContext, useReducer, useEffect } from "react";
import { saveData, StoreKey } from "..";
import { IWarehouseProductActions, useActions } from "../actions/warehouseProduct";
import { applyMiddleware } from "../middlewares/warehouseProduct";
import { initState, warehouseProductReducer } from "../reducers/warehouseProduct";
import { IWarehouseProductState } from "../types/warehouseProduct";

interface IContextProps {
    warehouseProductState: IWarehouseProductState;
    warehouseProductActions: IWarehouseProductActions;
}

export const WarehouseProductContext = createContext({} as IContextProps);

const WarehouseProductContextProvider = (props: any) => {
    const [warehouseProductState, dispatch] = useReducer(warehouseProductReducer, initState);

    useEffect(() => {
        saveData(StoreKey.product, warehouseProductState);
    }, [warehouseProductState]);

    const warehouseProductActions = useActions(warehouseProductState, applyMiddleware(dispatch));

    return (
        <WarehouseProductContext.Provider value={{ warehouseProductState, warehouseProductActions }}>
            {props.children}
        </WarehouseProductContext.Provider>
    );
};

export default WarehouseProductContextProvider;
