import React, { createContext, useReducer, useEffect } from "react";
import { saveData, StoreKey } from "..";
import { ICurrentCustomerActions, useActions } from "../actions/currentCustomer";
import { applyMiddleware } from "../middlewares/currentCustomer";

import { currentCustomerReducer, initState } from "../reducers/currentCustomer";
import { ICurrentCustomerState } from "../types/customer";

export interface ICurrentCustomerContextProps {
    currentCustomerState: ICurrentCustomerState;
    currentCustomerActions: ICurrentCustomerActions;
}

export const CurrentCustomerContext = createContext({} as ICurrentCustomerContextProps);

const CurrentCustomerContextProvider = (props: any) => {
    const [currentCustomerState, dispatch] = useReducer(currentCustomerReducer, initState);

    useEffect(() => {
        saveData(StoreKey.currentCustomer, currentCustomerState);
    }, [currentCustomerState]);

    const currentCustomerActions = useActions(currentCustomerState, applyMiddleware(dispatch));

    return (
        <CurrentCustomerContext.Provider value={{ currentCustomerState, currentCustomerActions }}>
            {props.children}
        </CurrentCustomerContext.Provider>
    );
};

export default CurrentCustomerContextProvider;
