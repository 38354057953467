import { getStoredData, StoreKey } from "..";
import { IPaginationResponse, PaginationResponse } from "../types";
import {
    ADD_DELIVERY_ORDER,
    DeliveryOrderTypes,
    IDeliveryOrder,
    IDeliveryOrderState,
    INVALID_REQUEST_DELIVERY_ORDERS,
    RECEIVE_DELIVERY_ORDERS,
    REQUEST_DELIVERY_ORDERS,
    UPDATE_DELIVERY_ORDER,
} from "../types/deliveryOrder";

export class InitStateBase implements IDeliveryOrderState {
    pagination: IPaginationResponse<IDeliveryOrder> = new PaginationResponse<IDeliveryOrder>();
    error: any = undefined;
    isFetching: boolean = false;
    isError: boolean = false;
}

export const initState = getStoredData<IDeliveryOrderState>(new InitStateBase(), StoreKey.sale);

export const deliveryOrderReducer = (state: IDeliveryOrderState, action: DeliveryOrderTypes): IDeliveryOrderState => {
    switch (action.type) {
        case ADD_DELIVERY_ORDER:
            const newitems = [...state.pagination.items, action.value];
            return {
                ...state,
                pagination: { ...state.pagination, items: newitems },
            };
        case UPDATE_DELIVERY_ORDER:
            let newlist: IDeliveryOrder[] = [];

            state.pagination.items.forEach((item) => {
                if (item.id === action.value.id) {
                    newlist.push(action.value);
                } else {
                    newlist.push(item);
                }
            });

            return {
                ...state,
                pagination: { ...state.pagination, items: newlist },
            };
        case RECEIVE_DELIVERY_ORDERS:
            return {
                ...state,
                isFetching: false,
                isError: false,
                pagination: action.value,
            };
        case REQUEST_DELIVERY_ORDERS:
            return {
                ...state,
                isFetching: true,
                isError: false,
            };
        case INVALID_REQUEST_DELIVERY_ORDERS:
            return {
                ...state,
                isFetching: false,
                isError: true,
                error: action.value,
            };
        default:
            return state;
    }
};
