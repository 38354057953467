export const REQUEST_SELLERS = "REQUEST_SELLERS";
export const RECEIVE_SELLERS = "RECEIVE_SELLERS";
export const INVALID_REQUEST_SELLERS = "INVALID_REQUEST_SELLERS";
export const ADD_SELLER = "ADD_SELLER";
export const UPDATE_SELLER = "UPDATE_SELLER";

export type SellerStatus = "active" | "void";

export interface ISeller {
    id?: string;
    accountId: string;
    firstName: string;
    lastName: string;
    email?: string;
    address?: string;
    phoneNumber?: string;
    phonePrefix?: string;
    dateCreated?: Date;
    status?: SellerStatus;
}
export class Seller implements ISeller {
    id?: string | undefined;
    accountId: string;
    firstName: string;
    lastName: string;
    email?: string | undefined;
    address?: string | undefined;
    phoneNumber?: string | undefined;
    phonePrefix?: string | undefined;
    dateCreated?: Date | undefined;
    status?: "active" | "void" | undefined;
    constructor(accountId: string, firstName: string, lastName: string) {
        this.accountId = accountId;
        this.firstName = firstName;
        this.lastName = lastName;
    }
}
export interface ISellerState {
    items: ISeller[];
    error: any;
    isFetching: boolean;
    isError: boolean;
}

export interface RequestSellers {
    type: typeof REQUEST_SELLERS;
}
export interface ReceiveSellers {
    type: typeof RECEIVE_SELLERS;
    items: ISeller[];
}
export interface InvalidRequest {
    type: typeof INVALID_REQUEST_SELLERS;
    value: any;
}

export interface AddSeller {
    type: typeof ADD_SELLER;
    value: ISeller;
}

export interface UpdateSeller {
    type: typeof UPDATE_SELLER;
    value: ISeller;
}

export type SellerTypes = RequestSellers | ReceiveSellers | InvalidRequest | AddSeller | UpdateSeller;
