import React from "react";
import { IContentPosition, DropdownRects } from ".";

export class ClickResult {
    result: boolean = false;
}

export const ignoreInternalClick = (element: any, value: ClickResult, elementId: string) => {
    let parentElement = element.parentElement;
    if (parentElement) {
        if (parentElement.id === elementId) {
            value.result = true;
        } else ignoreInternalClick(parentElement, value, elementId);
    }
};

export const getContentPositionStyle = (rects: DropdownRects, position: IContentPosition) => {
    const buttonRect = rects.button;
    const contentRect = rects.content;

    let left = buttonRect.x;
    let top = buttonRect.y + buttonRect.height + 10;

    if (position.contentVertical === "inline") {
        top = buttonRect.y;
    }

    if (position.contentHorizontal === "right" && position.contentOutside) {
        left = buttonRect.x + buttonRect.width + 10;
    }
    if (position.contentHorizontal === "left") {
        let widthToRemove = contentRect.width - buttonRect.width;
        left -= widthToRemove;
        if (position.contentOutside) {
            left -= buttonRect.width;
        }
    }
    if (position.contentHorizontal === "center") {
        left -= contentRect.width / 2 - buttonRect.width / 2;
    }

    let style: React.CSSProperties = {
        left,
        top,
    };

    return style;
};

export const getContentShowStyle = (show: boolean) => {
    let style: React.CSSProperties = {
        visibility: show ? "visible" : "hidden",
        opacity: show ? 1 : 0,
        height: show ? "auto" : "0px",
        transition: show ? "height 0ms 0ms, opacity 400ms 0ms" : "height 0ms 300ms, opacity 300ms 0ms",
    };
    return style;
};
export const getButtonStyle = (square?: boolean) => {
    if (!square) {
        let style: React.CSSProperties = {
            justifyContent: "flex-start",
        };
        return style;
    }
    let style: React.CSSProperties = {
        justifyContent: "center",
        width: "33px",
        minWidth: "33px",
        height: "33px",
    };
    return style;
};
