import { getStoredData, StoreKey } from "..";
import {
    ADD_CUSTOMER,
    CustomerTypes,
    ICustomer,
    ICustomerState,
    INVALID_REQUEST_CUSTOMERS,
    RECEIVE_CUSTOMERS,
    REQUEST_CUSTOMERS,
    UPDATE_CUSTOMER,
} from "../types/customer";

export class InitStateBase implements ICustomerState {
    items: ICustomer[] = [];
    error: any = undefined;
    isFetching: boolean = false;
    isError: boolean = false;
}

export const initState = getStoredData<ICustomerState>(new InitStateBase(), StoreKey.customer);

export const customerReducer = (state: ICustomerState, action: CustomerTypes): ICustomerState => {
    switch (action.type) {
        case ADD_CUSTOMER:
            return {
                ...state,
                items: [...state.items, action.value],
            };
        case UPDATE_CUSTOMER:
            let newlist: ICustomer[] = [];

            state.items.forEach((item) => {
                if (item.id === action.value.id) {
                    newlist.push(action.value);
                } else {
                    newlist.push(item);
                }
            });

            return {
                ...state,
                items: newlist,
            };
        case RECEIVE_CUSTOMERS:
            return {
                ...state,
                isFetching: false,
                isError: false,
                items: action.items,
            };
        case REQUEST_CUSTOMERS:
            return {
                ...state,
                isFetching: true,
                isError: false,
            };
        case INVALID_REQUEST_CUSTOMERS:
            return {
                ...state,
                isFetching: false,
                isError: true,
                error: action.value,
            };
        default:
            return state;
    }
};
