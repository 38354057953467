import React, { useState, useEffect, useContext } from "react";
import {
    ControlsContainer,
    MainContainer,
    LoadingContainer,
    MoreItemsContainer,
    LoadingBody,
    FlexContainer,
    CategoriesContainer,
    FilterContainerView,
} from "./styled";

import { DefaultButton, SquareButton } from "../controls";
import ProductCardList from "./ProductCardList";
import { IProduct, IProductSort, ProductPriceTypes, ProductSortOption, productSortOptions } from "../../store/types/product";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import LoadingDualRing from "../LoadingDualRing";
import { IProductOption, ProductClickProps } from "../ProductCard";
import { IWarehouseProduct, IWarehouseProductSearchModel } from "../../store/types/warehouseProduct";
import { IPaginationList, IPaginationResponse, PaginationResponse } from "../../store/types";
import { IProductCategory } from "../../store/types/productCategory";
import SearchWithCategories from "./SearchWithCategories";
import { CurrentProductCatalogContext } from "../../store/contexts/CurrentProductCatalogContext";
import SimpleModalFixed from "../modals/Modal";
import ProductProfile from "../ProductProfile";
import { wahioFetch } from "../../services";
import { productEndpoint } from "../../services/restApiEndpoint";
import { useAlert } from "react-alert";
import CategoryRow from "./CategoryRow";
import SocialMediaIcons from "../SocialMediaIcons";
import { SelectBaseOption } from "../../store/types";
import { SelectBody } from "../controls/selectStyled";
import Select from "react-select";
import TextBoxNumeral from "../TextBoxNumeral";

export type ProductListShape = "row" | "square" | undefined;

export interface ListProductProps extends ProductClickProps {
    warehouseProducts: IWarehouseProduct[];
    defaultPrice: ProductPriceTypes;
    showStock?: boolean;
    showPrice?: boolean;
    sort: ProductSortOption;
    onClickCategory: (category: IProductCategory) => void;
}

const thereIsMoreItems = (pagination: IPaginationList) => {
    return pagination.currentPage < pagination.totalPages;
};

const Loading = () => {
    return (
        <LoadingContainer>
            <LoadingBody>
                <LoadingDualRing />
            </LoadingBody>
        </LoadingContainer>
    );
};

export interface ProductListProps extends ProductClickProps {
    searchId?: string;
    showAllProducts?: boolean;
}

interface ICategoryState {
    items: IProductCategory[];
    isFetching: boolean;
}

interface ICategoryGroupState {
    pagination: IPaginationResponse<IWarehouseProduct>;
    isFetching: boolean;
}

export default function ProductSearchList(props: ProductListProps) {
    const alert = useAlert();
    const { currentProductCatalogState } = useContext(CurrentProductCatalogContext);
    const { productCatalog } = currentProductCatalogState;
    const [searchValue, setSearchValue] = useState<string | undefined>();
    const [selectedCategory, setSelectedCategory] = useState<IProductCategory | undefined>(undefined);
    const organization = productCatalog?.organization;
    const [priceRange, setPriceRange] = useState({
        minPrice: productCatalog?.minPrice ?? 0,
        maxPrice: productCatalog?.maxPrice ?? 0,
    });
    const [productSort, setProductSort] = useState<IProductSort>(
        productCatalog
            ? productSortOptions.find((x) => x.sort === productCatalog.sort && x.sortDesc === productCatalog.sortDesc) ??
                  productSortOptions[0]
            : productSortOptions[0]
    );
    const [categoryState, setCategoryState] = useState<ICategoryState>({
        items: [],
        isFetching: false,
    });
    const [categoryProductGroupState, setCategoryProductGroupState] = useState<ICategoryGroupState>({
        pagination: new PaginationResponse<IWarehouseProduct>(1),
        isFetching: false,
    });

    const [showCategories, setShowCategories] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<IProduct | undefined>(undefined);

    useEffect(() => {
        if (categoryProductGroupState.pagination.items.length === 0) searchProducts();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (productCatalog && categoryState.items.length === 0) searchCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productCatalog]);

    useEffect(() => {
        if (searchValue !== undefined) searchProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    useEffect(() => {
        searchProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.searchId, selectedCategory, productSort]);

    const searchCategories = () => {
        if (!productCatalog) return;
        const searhBody: IWarehouseProductSearchModel = {
            accountId: productCatalog.accountId,
            currentPage: 1,
            pageSize: 100,
            warehouseId: productCatalog.warehouseId,
            sort: "default",
            sortDesc: true,
            status: "active",
            inStockOnly: !productCatalog.showOutOfStock,
            customCategoryIds: productCatalog.customCategoryIds ?? [],
        };
        setCategoryState({ ...categoryState, isFetching: true });
        wahioFetch.post(
            productEndpoint.post.warehouseProductCategoriesSearchQuery,
            searhBody,
            (success) => {
                setCategoryState({ items: success.data, isFetching: false });
            },
            () => {
                alert.error(`Error al interntar buscar las categorias`);
                setCategoryState({ ...categoryState, isFetching: false });
            }
        );
    };

    const searchProducts = (page: number = 1) => {
        if (!productCatalog) return;
        const searchBody: IWarehouseProductSearchModel = {
            accountId: productCatalog.accountId,
            currentPage: page,
            pageSize: 50,
            searchValue,
            customCategoryId: selectedCategory?.id,
            warehouseId: productCatalog.warehouseId,
            sort: productSort.sort,
            sortDesc: productSort.sortDesc,
            status: "active",
            inStockOnly: !productCatalog.showOutOfStock,
            customCategoryIds: productCatalog.customCategoryIds ?? [],
            onlyProductWithImage: productCatalog.onlyProductWithImage,
            onlyProductWithoutImage: productCatalog.onlyProductWithoutImage,
            minPrice: priceRange.minPrice ?? 0,
            maxPrice: priceRange.maxPrice ?? 0,
        };
        setCategoryProductGroupState({ ...categoryProductGroupState, isFetching: true });
        wahioFetch.post(
            productEndpoint.post.warehouseProductSearchQuery,
            searchBody,
            (success) => {
                let pagination: IPaginationResponse<IWarehouseProduct> = success.data;
                if (page > 1) {
                    pagination.items = [...categoryProductGroupState.pagination.items, ...pagination.items];
                }
                setCategoryProductGroupState({ pagination: pagination, isFetching: false });
            },
            () => {
                alert.error(`Error al interntar buscar las categorias`);
                setCategoryProductGroupState({ ...categoryProductGroupState, isFetching: false });
            }
        );
    };

    const searchMoreItems = () => {
        let page = categoryProductGroupState.pagination.currentPage + 1;

        searchProducts(page);
    };

    const onClickViewProduct = (value: IProductOption) => {
        setSelectedProduct(value.product);
    };

    const onClickCategory = (category: IProductCategory) => {
        if (productCatalog?.customCategoryIds?.length === 0) {
            setSelectedCategory(category);
        } else {
            let existing = productCatalog?.customCategories?.find((x) => x.id === category.id);

            if (existing) {
                setSelectedCategory(existing);
            }
        }
    };

    const productlistProps: ListProductProps = {
        onClickAddProduct: props.onClickAddProduct,
        onClickViewProduct: onClickViewProduct,
        warehouseProducts: categoryProductGroupState.pagination.items,
        warehouse: currentProductCatalogState.productCatalog?.warehouse,
        defaultPrice: currentProductCatalogState.productCatalog ? currentProductCatalogState.productCatalog.price : "price",
        showStock: currentProductCatalogState.productCatalog?.showStock,
        showPrice: currentProductCatalogState.productCatalog?.showPrice,
        sort: productSort.sort ?? "default",
        onClickCategory: onClickCategory,
    };

    const showMoreItemsButton = () => {
        return thereIsMoreItems(categoryProductGroupState.pagination) && !categoryProductGroupState.isFetching;
    };

    const getCategories = () => {
        if (productCatalog?.customCategoryIds && productCatalog.customCategoryIds.length > 0) {
            let result = categoryState.items.filter((x) => productCatalog.customCategoryIds?.includes(x.id ?? ""));
            return result;
        }
        return categoryState.items;
    };
    const onChangeSort = (sortName?: string) => {
        let value = productSortOptions.find((x) => x.name === sortName);
        if (!value) value = productSortOptions[0];
        setProductSort(value);
    };
    const getDefaultValue = () => {
        if (productSort) return getSelectOption(productSort.name, productSort.name);
        return getSelectOption(productSortOptions[0].name, productSortOptions[0].name);
    };
    const getSelectOption = (id: string, name: string) => {
        let option: SelectBaseOption = {
            id: id,
            value: name,
            label: name,
        };
        return option;
    };

    return (
        <MainContainer id="productlistcontainer">
            {selectedProduct && (
                <SimpleModalFixed
                    id="productviewmodal"
                    sizeType="sm"
                    header={false}
                    show={!!selectedProduct}
                    setShow={() => setSelectedProduct(undefined)}
                    title={selectedProduct.name}
                >
                    <ProductProfile onCloseProduct={() => setSelectedProduct(undefined)} product={selectedProduct} />
                </SimpleModalFixed>
            )}
            <div className="menu-container">
                {organization && productCatalog && (
                    <SocialMediaIcons productCatalog={productCatalog} className={"social-line"} organization={organization} />
                )}
                <ControlsContainer>
                    <FlexContainer>
                        <SearchWithCategories
                            selectedCategory={selectedCategory}
                            setSelectedCategory={setSelectedCategory}
                            setSearchValue={setSearchValue}
                        />
                        <SquareButton className="filter" onClick={() => setShowCategories(!showCategories)}>
                            <span className="wahioicon-filter"></span>
                        </SquareButton>
                        {categoryProductGroupState.isFetching && <Loading />}
                    </FlexContainer>
                </ControlsContainer>

                <FilterContainerView className={`mt-2 ${showCategories ? "show" : ""}`}>
                    <SelectBody>
                        <span className="label">Ordenar busqueda por:</span>
                        <Select
                            placeholder="Seleccionar"
                            className="react-select-basic"
                            classNamePrefix="select"
                            key={productSort.sort}
                            defaultValue={getDefaultValue()}
                            isDisabled={false}
                            onChange={(value) => onChangeSort(value?.id)}
                            isRtl={false}
                            isSearchable={true}
                            name="seller"
                            options={productSortOptions.map((item) => getSelectOption(item.name, item.name))}
                        />
                    </SelectBody>

                    <div className="prices-container">
                        <div className="price-group mr-1">
                            <label htmlFor="minprice">Precio Minimo</label>
                            <TextBoxNumeral
                                name="minprice"
                                value={priceRange.minPrice}
                                format="money"
                                placeholder="Precio Min"
                                onNumberChange={(value) => setPriceRange({ ...priceRange, minPrice: value })}
                            />
                        </div>
                        <div className="price-group mr-1">
                            <label htmlFor="minprice">Precio Maximo</label>
                            <TextBoxNumeral
                                name="maxprice"
                                value={priceRange.maxPrice}
                                format="money"
                                placeholder="Precio Max"
                                onNumberChange={(value) => setPriceRange({ ...priceRange, maxPrice: value })}
                            />
                        </div>
                        <DefaultButton rounded onClick={() => searchProducts()}>
                            Aplicar
                        </DefaultButton>
                    </div>
                </FilterContainerView>
                <CategoriesContainer className={`categories ${showCategories ? "show" : ""}`}>
                    {getCategories().map((item, index) => {
                        const isActive = selectedCategory?.id === item.id;
                        return (
                            <CategoryRow
                                item={item}
                                key={index}
                                setSelectedCategory={setSelectedCategory}
                                setShowCategories={setShowCategories}
                                isActive={isActive}
                            />
                        );
                    })}
                </CategoriesContainer>
            </div>
            <div className={`product-container ${showCategories ? "hideproduct" : ""}`}>
                {!categoryProductGroupState.isFetching && categoryProductGroupState.pagination.items.length === 0 && (
                    <div className="center mt-1 ml-1">
                        <span>No se encontraron productos</span>
                    </div>
                )}
                <ProductCardList {...productlistProps} />
                {showMoreItemsButton() && (
                    <MoreItemsContainer>
                        <DefaultButton onClick={searchMoreItems}>
                            <span className="wahioicon-plus"></span> <FormattedMessage {...messages.moreItems} />
                        </DefaultButton>
                    </MoreItemsContainer>
                )}
            </div>
        </MainContainer>
    );
}
