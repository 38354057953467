import React, { useContext, useEffect, useState } from "react";
import { wahioFetch } from "../../services";
import { localorderEndpoint } from "../../services/restApiEndpoint";
import { CurrentCustomerContext } from "../../store/contexts/CurrentCustomerContext";
import { CurrentProductCatalogContext } from "../../store/contexts/CurrentProductCatalogContext";
import { ICustomer, ICustomerSearch } from "../../store/types/customer";
import { PrimaryButton } from "../controls";
import { ButtonsContainer, FormFieldsContainer } from "../controls/formStyled";
import { InputTextFieldSimple } from "../controls/InputTextField";
import LoadingDualRing from "../LoadingDualRing";

interface ICustomerFetchResult {
    isFetching: boolean;
    notFound?: boolean;
    customer?: ICustomer;
}

export default function CustomerLoginForm() {
    const { currentProductCatalogState } = useContext(CurrentProductCatalogContext);
    const { currentCustomerState, currentCustomerActions } = useContext(CurrentCustomerContext);
    
    const [customerSearch, setCustomerSearch] = useState<ICustomerSearch>({
        accountId: "",
        email: "",
        identificationNumber: "",
    });

    const [customerFetchResult, setCustomerFetchResult] = useState<ICustomerFetchResult>({
        isFetching: false,
    });

    useEffect(() => {
        const { productCatalog } = currentProductCatalogState;
        if (productCatalog) {
            setCustomerSearch({ ...customerSearch, accountId: productCatalog.accountId });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProductCatalogState.productCatalog]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCustomerSearch({ ...customerSearch, [e.target.name]: e.target.value });
    };

    const handleLogin = () => {
        setCustomerFetchResult({ ...customerFetchResult, isFetching: true });
        wahioFetch.post(
            localorderEndpoint.post.customerSearch,
            customerSearch,
            (result) => {
                setCustomerFetchResult({ ...customerFetchResult, isFetching: false, notFound: false });
                const customer: ICustomer = result.data;
                currentCustomerActions.update({ ...currentCustomerState, customer });
            },
            (error) => {
                setCustomerFetchResult({ ...customerFetchResult, isFetching: false, notFound: true });
            }
        );
    };

    return (
        <FormFieldsContainer>
            {customerFetchResult.notFound && (
                <div>
                    <p>El cliente no fue encontrado o no esta autorizado</p>
                </div>
            )}
            <InputTextFieldSimple
                name="email"
                value={customerSearch.email}
                onChange={handleChange}
                type="text"
                placeholder={"Correo de Cliente"}
            />
            <InputTextFieldSimple
                name="identificationNumber"
                value={customerSearch.identificationNumber}
                onChange={handleChange}
                type="text"
                placeholder="Número de identificación"
            />
            {!customerFetchResult.isFetching && (
                <ButtonsContainer>
                    <PrimaryButton onClick={handleLogin}>Continuar</PrimaryButton>
                </ButtonsContainer>
            )}
            {customerFetchResult.isFetching && <LoadingDualRing center={true} />}
        </FormFieldsContainer>
    );
}
