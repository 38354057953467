import React from "react";
import { IProductCategory } from "../../store/types/productCategory";
import DefaultImage from "../../assets/images/picture-image.png";
import { SquareButton } from "../controls";

interface CategoryRowProps {
    item: IProductCategory;
    setSelectedCategory: (value?: IProductCategory) => void;
    setShowCategories: (value: boolean) => void;
    isActive: boolean;
    hideRemove?: boolean;
}
export default function CategoryRow(props: CategoryRowProps) {
    const { item, setSelectedCategory, setShowCategories, isActive } = props;

    return (
        <div
            onClick={() => {
                setSelectedCategory(isActive ? undefined : item);
                setShowCategories(false);
            }}
            className={`card-category ${isActive ? "active" : ""}`}
        >
            <div className="image">
                <img src={item.image ? item.image : DefaultImage} alt={item.name} />
            </div>
            <span className="text">
                {item.name} {`${!props.hideRemove ? `(${item.productCount})` : ""}`}
            </span>
            {!props.hideRemove && isActive && (
                <SquareButton className="remove">
                    <span className="wahioicon-trash"></span>
                </SquareButton>
            )}
        </div>
    );
}
