export const REQUEST_SUPPLIERS = "REQUEST_SUPPLIERS";
export const RECEIVE_SUPPLIERS = "RECEIVE_SUPPLIERS";
export const INVALID_REQUEST_SUPPLIERS = "INVALID_REQUEST_SUPPLIERS";
export const ADD_SUPPLIER = "ADD_SUPPLIER";
export const UPDATE_SUPPLIER = "UPDATE_SUPPLIER";

export type SupplierStatus = "active" | "void";

export interface ISupplier {
    id?: string;
    accountId: string;
    name: string;
    email: string;
    phoneNumber: string;
    dateCreated?: Date;
    status?: SupplierStatus;
}
export class Supplier implements ISupplier {
    id?: string | undefined;
    accountId: string;
    name: string = "";
    email: string = "";
    phoneNumber: string = "";
    dateCreated?: Date | undefined;
    status?: SupplierStatus;
    constructor(accountId: string) {
        this.accountId = accountId;
    }
}

export interface ISupplierState {
    items: ISupplier[];
    error: any;
    isFetching: boolean;
    isError: boolean;
}

export interface RequestSuppliers {
    type: typeof REQUEST_SUPPLIERS;
}
export interface ReceiveSuppliers {
    type: typeof RECEIVE_SUPPLIERS;
    items: ISupplier[];
}
export interface InvalidRequest {
    type: typeof INVALID_REQUEST_SUPPLIERS;
    value: any;
}

export interface AddSupplier {
    type: typeof ADD_SUPPLIER;
    value: ISupplier;
}

export interface UpdateSupplier {
    type: typeof UPDATE_SUPPLIER;
    value: ISupplier;
}

export type SupplierTypes = RequestSuppliers | ReceiveSuppliers | InvalidRequest | AddSupplier | UpdateSupplier;

