import { getStoredData, StoreKey } from "..";
import { IPaginationResponse, PaginationResponse } from "../types";
import {
    INVALID_REQUEST_PRODUCTS,
    IProduct,
    IProductState,
    IReceiveProducts,
    ProductTypes,
    RECEIVE_PRODUCTS,
    REQUEST_PRODUCTS,
} from "../types/product";

export class InitStateBase implements IProductState {
    pagination: IPaginationResponse<IProduct> = new PaginationResponse<IProduct>();
    error: any = undefined;
    isFetching: boolean = false;
    isError: boolean = false;
}

export const initState = getStoredData<IProductState>(new InitStateBase(), StoreKey.product);

const combineProducts = (state: IProductState, action: IReceiveProducts) => {
    const newvalue = action.value;
    let items = newvalue.items;
    if (state.pagination.currentPage < newvalue.currentPage && action.combine) items = state.pagination.items.concat(newvalue.items);

    return {
        ...state,
        isFetching: false,
        pagination: { ...newvalue, items },
    };
};

export const productReducer = (state: IProductState, action: ProductTypes): IProductState => {
    switch (action.type) {
        case RECEIVE_PRODUCTS:
            return combineProducts(state, action);
        case REQUEST_PRODUCTS:
            return {
                ...state,
                isFetching: true,
                isError: false,
            };
        case INVALID_REQUEST_PRODUCTS:
            return {
                ...state,
                isFetching: false,
                isError: true,
                error: action.value,
            };
        default:
            return state;
    }
};
