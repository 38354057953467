import { Dispatch } from "react";
import { IPaginationResponse } from "../types";
import {
    AddDeliveryOrder,
    ADD_DELIVERY_ORDER,
    DeliveryOrderTypes,
    IDeliveryOrder,
    IDeliveryOrderState,
    InvalidRequest,
    INVALID_REQUEST_DELIVERY_ORDERS,
    ReceiveDeliveryOrders,
    RECEIVE_DELIVERY_ORDERS,
    RequestDeliveryOrders,
    REQUEST_DELIVERY_ORDERS,
    UpdateDeliveryOrder,
    UPDATE_DELIVERY_ORDER,
    IDeliveryOrderSearchModel,
} from "../types/deliveryOrder";

export interface IDeliveryOrderActions {
    request: (search: IDeliveryOrderSearchModel) => void;
    receive: (value: IPaginationResponse<IDeliveryOrder>) => void;
    fetchInvalid: (error: any) => void;
    add: (customer: IDeliveryOrder) => void;
    update: (customer: IDeliveryOrder) => void;
}

export const actionRequest = (search: IDeliveryOrderSearchModel): RequestDeliveryOrders => {
    return {
        type: REQUEST_DELIVERY_ORDERS,
        value: search,
    };
};

export const actionReceive = (value: IPaginationResponse<IDeliveryOrder>): ReceiveDeliveryOrders => {
    return {
        type: RECEIVE_DELIVERY_ORDERS,
        value,
    };
};

export const actionFetchInvalid = (er: any): InvalidRequest => {
    return {
        type: INVALID_REQUEST_DELIVERY_ORDERS,
        value: er,
    };
};

export const ActionAdd = (customer: IDeliveryOrder): AddDeliveryOrder => {
    return {
        type: ADD_DELIVERY_ORDER,
        value: customer,
    };
};

export const ActionUpdate = (customer: IDeliveryOrder): UpdateDeliveryOrder => {
    return {
        type: UPDATE_DELIVERY_ORDER,
        value: customer,
    };
};

export const useActions = (state: IDeliveryOrderState, dispatch: Dispatch<DeliveryOrderTypes>): IDeliveryOrderActions => ({
    request: (search: IDeliveryOrderSearchModel) => {
        dispatch(actionRequest(search));
    },
    receive: (value: IPaginationResponse<IDeliveryOrder>) => {
        dispatch(actionReceive(value));
    },
    fetchInvalid: (error: any) => {
        dispatch(actionFetchInvalid(error));
    },
    add: (value: IDeliveryOrder) => {
        dispatch(ActionAdd(value));
    },
    update: (value: IDeliveryOrder) => {
        dispatch(ActionUpdate(value));
    },
});
