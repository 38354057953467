import { Dispatch } from "react";
import { wahioFetch } from "../../services";
import { localorderEndpoint } from "../../services/restApiEndpoint";
import { actionFetchInvalid, actionReceive, actionRequest } from "../actions/sale";
import { SaleTypes, REQUEST_SALES } from "../types/sale";

export const applyMiddleware = (dispatch: Dispatch<SaleTypes>) => (action: SaleTypes) => {
    switch (action.type) {
        case REQUEST_SALES:
            dispatch(actionRequest(action.value));
            return wahioFetch.post(
                localorderEndpoint.post.getOrderQuerySearch,
                action.value,
                (success) => {
                    dispatch(actionReceive(success.data));
                },
                (error) => {
                    dispatch(actionFetchInvalid(error));
                }
            );
        default:
            dispatch(action);
    }
};
