import { getStoredData, StoreKey } from "..";
import { AccountUserTypes, FETCH_INVALID, IAccountUser, RECEIVE_ACCOUNT_USER, REQUEST_ACCOUNT_USER } from "../types/accountUser";

export interface IAccountUserState {
    isFetching: boolean;
    exception?: any;
    value?: IAccountUser;
}

const initStateBase: IAccountUserState = {
    isFetching: false,
};

export const initState = getStoredData<IAccountUserState>(initStateBase, StoreKey.accountUser);

export const accountUserReducer = (state: IAccountUserState, action: AccountUserTypes): IAccountUserState => {
    switch (action.type) {
        case REQUEST_ACCOUNT_USER:
            return {
                ...state,
                isFetching: true,
                exception: undefined,
            };
        case RECEIVE_ACCOUNT_USER:
            return {
                ...state,
                isFetching: false,
                value: action.value,
            };
        case FETCH_INVALID:
            return {
                ...state,
                isFetching: false,
                value: undefined,
                exception: action.error,
            };

        default:
            return state;
    }
};
