import { getStoredData, StoreKey } from "..";
import {
    INVALID_REQUEST_ORGANIZATIONS,
    IOrganization,
    IOrganizationState,
    OrganizationTypes,
    RECEIVE_ORGANIZATIONS,
    REQUEST_ORGANIZATIONS,
} from "../types/organizationTypes";

export class InitStateBase implements IOrganizationState {
    items: IOrganization[] = [];
    error: any = undefined;
    isFetching: boolean = false;
    isError: boolean = false;
}

export const initState = getStoredData<IOrganizationState>(new InitStateBase(), StoreKey.organization);

export const organizationReducer = (state: IOrganizationState, action: OrganizationTypes): IOrganizationState => {
    switch (action.type) {
        case RECEIVE_ORGANIZATIONS:
            return {
                ...state,
                isFetching: false,
                isError: false,
                items: action.items,
            };
        case REQUEST_ORGANIZATIONS:
            return {
                ...state,
                isFetching: true,
                isError: false,
            };
        case INVALID_REQUEST_ORGANIZATIONS:
            return {
                ...state,
                isFetching: false,
                isError: true,
                error: action.value,
            };
        default:
            return state;
    }
};
