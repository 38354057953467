import { Dispatch } from "react";
import { ICurrentSaleState, ISale, IUpdateCurrentSale, SaleTypes, UPDATE_CURRENT_SALE } from "../types/sale";

export interface ICurrentSaleActions {
    update: (sale: ISale) => void;
}

export const actionUpdate = (sale: ISale): IUpdateCurrentSale => {
    return {
        type: UPDATE_CURRENT_SALE,
        value: sale,
    };
};

export const useActions = (state: ICurrentSaleState, dispatch: Dispatch<SaleTypes>): ICurrentSaleActions => ({
    update: (sale: ISale) => {
        dispatch(actionUpdate(sale));
    },
});
