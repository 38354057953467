import React from "react";
import { DefaultInput } from "./index";

import styled from "styled-components";

const SearchCustom = styled(DefaultInput)`
    padding-left: 3rem;
    width: 100%;
`;

const Icon = styled.span`
    position: absolute;
    left: 1rem;
    color: ${(props) => props.theme.palette.textLight};
`;

const Container = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 400px;
    margin-right: 10px;
`;

export default function SearchInput({ icon, ...props }: any) {
    return (
        <Container className="search-input">
            <Icon className={icon}></Icon>
            <SearchCustom {...props} />
        </Container>
    );
}
