import { Dispatch } from "react";
import {
    IOrganization,
    IOrganizationState,
    OrganizationTypes,
    ReceiveOrganizations,
    RECEIVE_ORGANIZATIONS,
    RequestOrganizations,
    REQUEST_ORGANIZATIONS,
    InvalidRequest,
    INVALID_REQUEST_ORGANIZATIONS,
} from "../types/organizationTypes";

export interface IOrganizationActions {
    request: () => void;
    receive: (items: IOrganization[]) => void;
    fetchInvalid: (error: any) => void;
}

export const actionRequest = (): RequestOrganizations => {
    return {
        type: REQUEST_ORGANIZATIONS,
    };
};

export const actionReceive = (items: IOrganization[]): ReceiveOrganizations => {
    return {
        type: RECEIVE_ORGANIZATIONS,
        items,
    };
};

export const actionFetchInvalid = (er: any): InvalidRequest => {
    return {
        type: INVALID_REQUEST_ORGANIZATIONS,
        value: er,
    };
};

export const useActions = (state: IOrganizationState, dispatch: Dispatch<OrganizationTypes>): IOrganizationActions => ({
    request: () => {
        dispatch(actionRequest());
    },
    receive: (items: IOrganization[]) => {
        dispatch(actionReceive(items));
    },
    fetchInvalid: (error: any) => {
        dispatch(actionFetchInvalid(error));
    },
});
