import { Dispatch } from "react";
import { wahioFetch } from "../../services";
import { localorderEndpoint } from "../../services/restApiEndpoint";
import { actionFetchInvalid, actionReceive, actionRequest } from "../actions/deliveryOrder";
import { DeliveryOrderTypes, REQUEST_DELIVERY_ORDERS } from "../types/deliveryOrder";

export const applyMiddleware = (dispatch: Dispatch<DeliveryOrderTypes>) => (action: DeliveryOrderTypes) => {
    switch (action.type) {
        case REQUEST_DELIVERY_ORDERS:
            dispatch(actionRequest(action.value));
            return wahioFetch.post(
                localorderEndpoint.post.getDeliveryOrderQuerySearch,
                action.value,
                (success) => {
                    dispatch(actionReceive(success.data));
                },
                (error) => {
                    dispatch(actionFetchInvalid(error));
                }
            );
        default:
            dispatch(action);
    }
};
