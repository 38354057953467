import React from "react";
import ReactDOM from "react-dom";
import { ButtonClose, CloseContainer, ModalHeader, ModalTitle, WahioModal, WahioModalBody, WahioModalBodyParent } from "./styled";

export interface IModalShow {
    show: boolean;
    setShow: (value: boolean) => void;
}

interface IModalProps {
    id: string;
    children?: any;
    show: boolean;
    setShow: (value: boolean) => void;
    header?: boolean;
    title: string;
    removeVerticalAlign?: boolean;
    removeTopPadding?: boolean;
    sizeType?: "sm" | "md" | "lg" | "auto";
    scrollInside?: boolean;
    overflowAuto?: boolean;
    borderRadius?: number;
    ignoreOutsideClick?: boolean;
}

const WahioModalHeader = (props: IModalProps) => {
    return (
        <ModalHeader>
            <ModalTitle>{props.title}</ModalTitle>
            <CloseContainer>
                <ButtonClose type="button" className={`btn-close`} onClick={() => props.setShow(false)}>
                    <span className={`wahioicon-times`}></span>
                </ButtonClose>
            </CloseContainer>
        </ModalHeader>
    );
};

const ModalComp = (props: IModalProps) => {
    let { children, show, setShow, header, sizeType } = props;
    sizeType = sizeType ?? "sm";

    const onClickParent = (e: any) => {
        if (props.ignoreOutsideClick) return;
        if (e.target.id === props.id) {
            setShow(false);
        }
    };

    const verticalAlign = props.removeVerticalAlign ? "" : "m-auto";
    const removeTop = props.removeTopPadding ? "remove-top" : "";
    const classInsideScroll = props.scrollInside ? "wahio-modal-content-auto-scroll" : "";
    const overflowAutoClass = props.overflowAuto ? "overflow-auto" : "";

    return (
        <WahioModal onClick={onClickParent} id={`${props.id}`} show={show}>
            <WahioModalBodyParent className={`wahio-modal-${sizeType} ${overflowAutoClass} ${verticalAlign} ${removeTop}`}>
                <WahioModalBody borderRadius={props.borderRadius}>
                    {header && <WahioModalHeader {...props} />}
                    <div className={`${classInsideScroll}`}>{children}</div>
                </WahioModalBody>
            </WahioModalBodyParent>
        </WahioModal>
    );
};

const Modal = (props: IModalProps) => {
    return ReactDOM.createPortal(<ModalComp {...props} />, (document as any).querySelector("#modal"));
};

export default Modal;
