import { IWarehouseProduct } from "./warehouseProduct";

export const REQUEST_PRODUCT_CATEGORIES = "REQUEST_PRODUCT_CATEGORIES";
export const RECEIVE_PRODUCT_CATEGORIES = "RECEIVE_PRODUCT_CATEGORIES";
export const INVALID_REQUEST_PRODUCT_CATEGORIES = "INVALID_REQUEST_PRODUCT_CATEGORIES";

export interface IProductCategory {
    id?: string;
    name: string;
    dateCreated: Date;
    globalCategoryId: string;
    categoryParentId: string;
    accountId: string;
    image: string;
    verified: boolean;
    status: boolean;
    productCount: number;
    childs: IProductCategory[];
    warehouseProducts: IWarehouseProduct[];
}
export class ProductCategory implements IProductCategory {
    id?: string;
    name: string = "";
    dateCreated: Date = new Date();
    globalCategoryId: string = "";
    categoryParentId: string = "";
    accountId: string = "";
    image: string = "";
    verified: boolean = false;
    status: boolean = false;
    productCount: number = 0;
    childs: IProductCategory[] = [];
    warehouseProducts: IWarehouseProduct[] = [];

    constructor(accountId: string, name?: string) {
        this.name = name ? name : "";
        this.accountId = accountId;
    }
}
export interface IProductCategoryState {
    items: IProductCategory[];
    error: any;
    isFetching: boolean;
    isError: boolean;
}

export interface RequestProductCategories {
    type: typeof REQUEST_PRODUCT_CATEGORIES;
    custom?: boolean;
}
export interface ReceiveProductCategories {
    type: typeof RECEIVE_PRODUCT_CATEGORIES;
    items: Array<IProductCategory>;
}
export interface InvalidRequest {
    type: typeof INVALID_REQUEST_PRODUCT_CATEGORIES;
    value: any;
}

export type ProductCategoryTypes = RequestProductCategories | ReceiveProductCategories | InvalidRequest;
