import { defineMessages } from "react-intl";

const base = "components.productProfile";

export default defineMessages({
    edit: {
        id: `${base}.edit`,
        defaultMessage: "Editar",
    },
    details: {
        id: `${base}.details`,
        defaultMessage: "Detalles",
    },
    basePrice: {
        id: `${base}.basePrice`,
        defaultMessage: "Precio base",
    },
    salePrice: {
        id: `${base}.salePrice`,
        defaultMessage: "Precio",
    },
    description: {
        id: `${base}.description`,
        defaultMessage: "Descripción",
    },
    brand: {
        id: `${base}.brand`,
        defaultMessage: "Marca",
    },
    manufacturer: {
        id: `${base}.manufacturer`,
        defaultMessage: "Manufactura",
    },
    imageList: {
        id: `${base}.imageList`,
        defaultMessage: "Imagenes del producto",
    },
});
