import { Dispatch } from "react";
import {
    ICustomCategoryState,
    CustomCategoryTypes,
    RECEIVE_CUSTOM_CATEGORIES,
    RequestCustomCategories,
    REQUEST_CUSTOM_CATEGORIES,
    ReceiveCustomCategories,
    InvalidRequest,
    INVALID_REQUEST_CUSTOM_CATEGORIES,
    AddCustomCategory,
    ADD_CUSTOM_CATEGORY,
} from "../types/customCategory";
import { IProductCategory } from "../types/productCategory";

export interface ICustomCategoryActions {
    request: (accountId: string) => void;
    receive: (items: IProductCategory[]) => void;
    add: (value: IProductCategory) => void;
    fetchInvalid: (error: any) => void;
}

export const actionRequest = (accountId: string): RequestCustomCategories => {
    return {
        type: REQUEST_CUSTOM_CATEGORIES,
        accountId,
    };
};

export const actionAdd = (value: IProductCategory): AddCustomCategory => {
    return {
        type: ADD_CUSTOM_CATEGORY,
        value,
    };
};

export const actionReceive = (items: IProductCategory[]): ReceiveCustomCategories => {
    return {
        type: RECEIVE_CUSTOM_CATEGORIES,
        items,
    };
};

export const actionFetchInvalid = (er: any): InvalidRequest => {
    return {
        type: INVALID_REQUEST_CUSTOM_CATEGORIES,
        value: er,
    };
};

export const useActions = (state: ICustomCategoryState, dispatch: Dispatch<CustomCategoryTypes>): ICustomCategoryActions => ({
    request: (accountId: string) => {
        dispatch(actionRequest(accountId));
    },
    receive: (items: IProductCategory[]) => {
        dispatch(actionReceive(items));
    },
    fetchInvalid: (error: any) => {
        dispatch(actionFetchInvalid(error));
    },
    add: (value: IProductCategory) => {
        dispatch(actionAdd(value));
    },
});
