import { getStoredData, StoreKey } from "..";
import {
    INVALID_REQUEST_WAREHOUSES,
    IWarehouse,
    IWarehouseState,
    WarehouseTypes,
    RECEIVE_WAREHOUSES,
    REQUEST_WAREHOUSES,
} from "../types/warehouse";

export class InitStateBase implements IWarehouseState {
    items: IWarehouse[] = [];
    error: any = undefined;
    isFetching: boolean = false;
    isError: boolean = false;
}
export const initState = getStoredData<IWarehouseState>(new InitStateBase(), StoreKey.warehouse);

export const warehouseReducer = (state: IWarehouseState, action: WarehouseTypes): IWarehouseState => {
    switch (action.type) {
        case RECEIVE_WAREHOUSES:
            return {
                ...state,
                isFetching: false,
                isError: false,
                items: action.items,
            };
        case REQUEST_WAREHOUSES:
            return {
                ...state,
                isFetching: true,
                isError: false,
            };
        case INVALID_REQUEST_WAREHOUSES:
            return {
                ...state,
                isFetching: false,
                isError: true,
                error: action.value,
            };
        default:
            return state;
    }
};
