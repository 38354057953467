import { getStoredData, StoreKey } from "..";
import { IPaginationResponse, PaginationResponse } from "../types";
import { ADD_SALE, SaleTypes, ISale, ISaleState, INVALID_REQUEST_SALES, RECEIVE_SALES, REQUEST_SALES, UPDATE_SALE } from "../types/sale";

export class InitStateBase implements ISaleState {
    pagination: IPaginationResponse<ISale> = new PaginationResponse<ISale>();
    error: any = undefined;
    isFetching: boolean = false;
    isError: boolean = false;
}

export const initState = getStoredData<ISaleState>(new InitStateBase(), StoreKey.sale);

export const saleReducer = (state: ISaleState, action: SaleTypes): ISaleState => {
    switch (action.type) {
        case ADD_SALE:
            const newitems = [...state.pagination.items, action.value];
            return {
                ...state,
                pagination: { ...state.pagination, items: newitems },
            };
        case UPDATE_SALE:
            let newlist: ISale[] = [];

            state.pagination.items.forEach((item) => {
                if (item.id === action.value.id) {
                    newlist.push(action.value);
                } else {
                    newlist.push(item);
                }
            });

            return {
                ...state,
                pagination: { ...state.pagination, items: newlist },
            };
        case RECEIVE_SALES:
            return {
                ...state,
                isFetching: false,
                isError: false,
                pagination: action.value,
            };
        case REQUEST_SALES:
            return {
                ...state,
                isFetching: true,
                isError: false,
            };
        case INVALID_REQUEST_SALES:
            return {
                ...state,
                isFetching: false,
                isError: true,
                error: action.value,
            };
        default:
            return state;
    }
};
