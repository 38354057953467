import React, { createContext, useReducer, useEffect, useContext } from "react";
import { saveData, StoreKey } from "..";
import { ICurrentSaleActions, useActions } from "../actions/currentSale";
import { applyMiddleware } from "../middlewares/currentSale";

import { currentSaleReducer, initState } from "../reducers/currentSale";
import { ICurrentSaleState } from "../types/sale";
import { AccountUserContext } from "./AccountUserContext";

export interface ICurrentSaleContextProps {
    currentSaleState: ICurrentSaleState;
    currentSaleActions: ICurrentSaleActions;
}

export const CurrentSaleContext = createContext({} as ICurrentSaleContextProps);

const CurrentSaleContextProvider = (props: any) => {
    const [currentSaleState, dispatch] = useReducer(currentSaleReducer, initState);
    const { accountUserState } = useContext(AccountUserContext);

    useEffect(() => {
        saveData(StoreKey.currentSale, currentSaleState);
    }, [currentSaleState]);

    useEffect(() => {
        if (accountUserState.value) {
            currentSaleActions.update({ ...currentSaleState.currentSale, accountId: accountUserState.value?.accountId });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountUserState.value]);

    const currentSaleActions = useActions(currentSaleState, applyMiddleware(dispatch));

    return <CurrentSaleContext.Provider value={{ currentSaleState, currentSaleActions }}>{props.children}</CurrentSaleContext.Provider>;
};

export default CurrentSaleContextProvider;
