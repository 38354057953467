import styled from "styled-components";
import { breakpoint } from "../../theme/themeProvider";

export const ProductContainer = styled.div`
    display: flex;
    position: relative;
    border-radius: 15px;
    isolation: isolate;
    flex-direction: column;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    background: ${(props) => props.theme.palette.background};
    overflow: hidden;
    padding-bottom: 10px;
    transition: box-shadow 0.2s;
    .plus {
        position: absolute;

        right: -50px;
        transition: all 0.2s;
        top: 10px;

        background: ${(props) => props.theme.palette.primary};
        color: white;
        &:hover {
            background: ${(props) => props.theme.palette.primaryDark};
        }
    }
    &:hover {
        box-shadow: 1px 1px 11px ${(props) => props.theme.palette.shadowDark};
        .plus {
            display: flex;
            right: 10px;
        }
    }
    &:focus {
        background: blue;
    }
    &.out {
        border-color: transparent;
        opacity: 0.3;
        cursor: default;
        &:hover {
            background: transparent;
            box-shadow: 0px 0px 0px transparent;
            .image {
                background: transparent;
            }
        }
        .title {
            text-decoration: line-through;
            color: ${(props) => props.theme.palette.textLight};
        }
    }
`;

export const ImageContainer = styled.div`
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid ${(props) => props.theme.palette.backgroundDark};
    overflow: hidden;
    background: ${(props) => props.theme.palette.background};

    ${breakpoint("md")`
        height: 190px;
    `}

    ${breakpoint("xxl2")`
    height: 270px;
    `}
`;

export const CustomImage = styled.img`
    width: 100%;
    object-fit: cover;
    height: 100%;
`;

export const ProductTitle = styled.span`
    font-weight: 500;
    font-size: 1rem;

    ${breakpoint("md")`
    font-size: 1.2rem;
    `}

    color: ${(props) => props.theme.palette.text};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0px 10px;
    margin-bottom: 2px;

    text-overflow: ellipsis;
    vertical-align: middle;
    width: 100%;
    display: block;
    box-sizing: border-box;

    &.out {
        color: red;
    }
`;

export const ProductSunTitle = styled.span`
    color: ${(props) => props.theme.palette.textLight};
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
    margin-top: 3px;
    .stock {
        color: ${(props) => props.theme.palette.textLight};
        font-size: 0.9rem;
    }
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 11px;
    width: 100%;
    overflow: hidden;

    .category {
        cursor: pointer;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 0px 10px;
        margin-bottom: 2px;
        text-overflow: ellipsis;
        vertical-align: middle;
        width: 100%;
        display: block;
        box-sizing: border-box;

        &:hover {
            color: #2196f3;
        }
    }
`;

export const FlexContainer = styled.div`
    display: flex;
    button {
        margin-left: 5px;
    }
`;

export const FlexContainerCol = styled.div`
    display: flex;
    flex-direction: column;
    .icon {
        margin-right: 4px;
        color: ${(props) => props.theme.palette.textLighter};
    }
`;
