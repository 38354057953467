import React, { createContext, useReducer, useEffect } from "react";
import { saveData, StoreKey } from "..";
import { ISupplierActions, useActions } from "../actions/supplier";
import { applyMiddleware } from "../middlewares/supplier";
import { initState, supplierReducer } from "../reducers/supplier";
import { ISupplierState } from "../types/supplier";

interface IContextProps {
    supplierState: ISupplierState;
    supplierActions: ISupplierActions;
}

export const SupplierContext = createContext({} as IContextProps);

const SupplierContextProvider = (props: any) => {
    const [supplierState, dispatch] = useReducer(supplierReducer, initState);

    useEffect(() => {
        saveData(StoreKey.supplier, supplierState);
    }, [supplierState]);

    const supplierActions = useActions(supplierState, applyMiddleware(dispatch));

    return <SupplierContext.Provider value={{ supplierState, supplierActions }}>{props.children}</SupplierContext.Provider>;
};

export default SupplierContextProvider;
