import { Dispatch } from "react";

import { ProductCatalogTypes } from "../types/productCatalog";

export const applyMiddleware = (dispatch: Dispatch<ProductCatalogTypes>) => (action: ProductCatalogTypes) => {
    switch (action.type) {
        default:
            dispatch(action);
    }
};
