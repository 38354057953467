import styled from "styled-components";

export const AlerTemplateView = styled.div`
    display: flex;
    background: ${(props) => props.theme.palette.background};
    padding: 15px 20px;
    margin-bottom: 15px !important;
    border-radius: 100px;
    align-items: center;
    .message {
        max-width: 500px;
    }

    &.info {
        background: ${(props) => props.theme.palette.backgroundDark};
        box-shadow: 1px 1px 13px ${(props) => props.theme.palette.shadowDark};
        color: white;
        background: #607d8b;
        .close {
            background: #537180;
            color: white;
        }
    }
    &.success {
        background: ${(props) => props.theme.palette.primary};

        box-shadow: 1px 1px 13px ${(props) => props.theme.palette.shadowDark};
        color: white;
        .close {
            background: ${(props) => props.theme.palette.primaryDark};
            color: white;
        }
    }
    &.error {
        background: ${(props) => props.theme.palette.dangerColor};
        box-shadow: 1px 1px 13px ${(props) => props.theme.palette.shadowDark};
        color: white;
        .close {
            background: ${(props) => props.theme.palette.dangerColorDark};
            color: white;
        }
    }
    .icon {
        margin-right: 20px;
        font-size: 2rem;
    }
    .close {
        margin-left: 10px;
    }
`;
