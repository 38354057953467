import {
    AppSettingsTypes,
    CHANGE_MENU_SMALL,
    CHANGE_THEME,
    SET_DEFAULT_LOUNGE,
    SET_DEFAULT_ORGANIZATION,
    SET_DEFAULT_PRICE,
    SET_DEFAULT_WAREHOUSE,
    SET_DELIVERY_SUMMARY,
    SET_LANGUAGE,
    SET_LIST_PRODUCT_SHAPE,
} from "./types";
import { Dispatch } from "react";
import { IAppSettingsState } from "./reducer";
import { ProductListShape } from "../components/ProductSearchList";
import { ProductPriceTypes } from "../store/types/product";
import { IDeliverySummary } from "../store/types/deliveryOrder";

export const changeTheme = (dispatch: Dispatch<AppSettingsTypes>) => {
    return dispatch({ type: CHANGE_THEME });
};

export interface ISettingsActions {
    changeTheme: () => void;
    setLanguage: (value: string) => void;
    setProductShape: (shape: ProductListShape) => void;
    setDefaultPrice: (value: ProductPriceTypes) => void;
    setDefaultOrganization: (value: string) => void;
    setDefaultWarehouse: (value: string) => void;
    setDefaultLounge: (value: string) => void;
    setDeliverySummary: (value: IDeliverySummary) => void;
    changeMenuSmall: () => void;
}

export const useActions = (state: IAppSettingsState, dispatch: Dispatch<AppSettingsTypes>): ISettingsActions => ({
    changeTheme: () => {
        return dispatch({ type: CHANGE_THEME });
    },
    setLanguage: (value: string) => {
        return dispatch({ type: SET_LANGUAGE, value });
    },
    setProductShape: (shape: ProductListShape) => {
        return dispatch({
            type: SET_LIST_PRODUCT_SHAPE,
            shape,
        });
    },
    setDefaultPrice: (value: ProductPriceTypes) => {
        return dispatch({
            type: SET_DEFAULT_PRICE,
            value,
        });
    },
    setDefaultOrganization: (value: string) => {
        return dispatch({
            type: SET_DEFAULT_ORGANIZATION,
            value,
        });
    },
    setDefaultWarehouse: (value: string) => {
        return dispatch({
            type: SET_DEFAULT_WAREHOUSE,
            value,
        });
    },
    setDefaultLounge: (value: string) => {
        return dispatch({
            type: SET_DEFAULT_LOUNGE,
            value,
        });
    },
    setDeliverySummary: (value: IDeliverySummary) => {
        return dispatch({
            type: SET_DELIVERY_SUMMARY,
            value,
        });
    },
    changeMenuSmall: () => {
        return dispatch({
            type: CHANGE_MENU_SMALL,
        });
    },
});
