import { IAccount } from "./accountUser";

export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const REQUEST_CUSTOMERS = "REQUEST_CUSTOMERS";
export const RECEIVE_CUSTOMERS = "RECEIVE_CUSTOMERS";
export const INVALID_REQUEST_CUSTOMERS = "INVALID_REQUEST_CUSTOMERS";
export const UPDATE_CURRENT_CUSTOMER = "UPDATE_CURRENT_CUSTOMER";

interface ICustomerLabel {
    id?: string;
    auxid?: string;
    customerId?: string;
    label?: string;
}

export interface ICustomerEmail extends ICustomerLabel {
    email: string;
    emailConfirmed?: boolean;
}

export interface ICustomerPhone extends ICustomerLabel {
    phoneNumber: string;
    phonePrefix: string;
}
export interface ICustomerAddress extends ICustomerLabel {
    description: string;
    latitude?: number;
    longitude?: number;
    country: string;
    state: string;
    city: string;
    contactName: string;
    phoneNumber: string;
    additionalInformation: string;
    neighborhood: string;
}
export interface ICustomer {
    id?: string;
    accountId: string;
    firstName: string;
    lastName: string;
    email?: string;
    address?: string;
    phoneNumber?: string;
    phonePrefix?: string;
    identificationNumber?: string;
    identificationType?: string;
    dateCreated?: Date;
    status?: string;
    emails: ICustomerEmail[];
    phones: ICustomerPhone[];
    addreses: ICustomerAddress[];
}
export class Customer implements ICustomer {
    id?: string;
    accountId: string;
    firstName: string = "";
    lastName: string = "";
    email?: string;
    address?: string;
    phoneNumber?: string;
    phonePrefix?: string;
    identificationNumber?: string;
    identificationType?: string;
    dateCreated?: Date;
    status?: string;
    emails: ICustomerEmail[] = [];
    phones: ICustomerPhone[] = [];
    addreses: ICustomerAddress[] = [];

    constructor(accountId: string) {
        this.accountId = accountId;
    }
}
export interface ICustomerState {
    items: ICustomer[];
    error: any;
    isFetching: boolean;
    isError: boolean;
}
export interface ICurrentCustomerState {
    customer?: ICustomer;
    account?: IAccount;
    catalogId?: string;
}
export interface RequestCustomers {
    type: typeof REQUEST_CUSTOMERS;
}
export interface ReceiveCustomers {
    type: typeof RECEIVE_CUSTOMERS;
    items: Array<ICustomer>;
}
export interface InvalidRequest {
    type: typeof INVALID_REQUEST_CUSTOMERS;
    value: any;
}
export interface UpdateCustomer {
    type: typeof UPDATE_CUSTOMER;
    value: ICustomer;
}

export interface IUpdateCurrentCustomer {
    type: typeof UPDATE_CURRENT_CUSTOMER;
    value: ICurrentCustomerState;
}

export interface AddCustomer {
    type: typeof ADD_CUSTOMER;
    value: ICustomer;
}

interface DeleteCustomer {
    type: typeof DELETE_CUSTOMER;
    id: string;
}

export interface ICustomerSearch {
    accountId: string;
    email: string;
    identificationNumber: string;
}

export type CustomerTypes =
    | RequestCustomers
    | ReceiveCustomers
    | InvalidRequest
    | UpdateCustomer
    | AddCustomer
    | DeleteCustomer
    | IUpdateCurrentCustomer;
