import { Dispatch } from "react";

import { wahioFetch } from "../../services";
import { productEndpoint } from "../../services/restApiEndpoint";
import { actionFetchInvalid, actionReceive, actionRequest } from "../actions/productCompany";
import { ProductCompanyTypes, REQUEST_PRODUCT_COMPANIES } from "../types/productCompany";

export const applyMiddleware = (dispatch: Dispatch<ProductCompanyTypes>) => (action: ProductCompanyTypes) => {
    switch (action.type) {
        case REQUEST_PRODUCT_COMPANIES:
            dispatch(actionRequest());

            return wahioFetch.get(
                productEndpoint.get.companiesByAccount(""),
                (success) => {
                    dispatch(actionReceive(success.data));
                },
                (error) => {
                    dispatch(actionFetchInvalid(error));
                }
            );
        default:
            dispatch(action);
    }
};
