import React from "react";
import { ICustomerAddress } from "../../store/types/customer";
import { DefaultInput } from "../controls";
import { FlexRow, InputGroup, AddressContainerFormView } from "./styled";
import { colombiaStatesAndCities, ILocationStateValue } from "../../constants/statesWithCities";
import Select from "react-select";
import { SelectBody } from "../controls/selectStyled";
import { SelectBaseOption } from "../../store/types";

interface ICustomerAddressFormProps {
    item: ICustomerAddress;
    onChange: (item: ICustomerAddress) => void;
}

export default function CustomerAddressForm(props: ICustomerAddressFormProps) {
    const { item } = props;

    const stateChange = (locationValue?: ILocationStateValue) => {
        if (locationValue) {
            item.state = locationValue.state;
            item.city = locationValue.cities[0];
            props.onChange({ ...item, state: locationValue.state, city: locationValue.cities[0] });
        }
    };

    const onChange = (e: any) => {
        props.onChange({ ...item, [e.target.name]: e.target.value });
    };

    const getSelectOption = (item: string) => {
        let option: SelectBaseOption = {
            id: item,
            value: item,
            label: item,
        };
        return option;
    };

    return (
        <AddressContainerFormView>
            <FlexRow>
                <InputGroup className="mr-1">
                    <span className="label">Nombre de Contacto*</span>
                    <DefaultInput
                        required
                        type="text"
                        name="contactName"
                        onChange={onChange}
                        value={item.contactName}
                        placeholder="Nombre de Contacto"
                    />
                </InputGroup>
                <InputGroup>
                    <span className="label">Número de teléfono*</span>
                    <DefaultInput name="phoneNumber" onChange={onChange} value={item.phoneNumber} placeholder="Número de teléfono" />
                </InputGroup>
            </FlexRow>

            <FlexRow>
                <InputGroup className="mr-1">
                    <SelectBody>
                        <span className="label">Departamento</span>
                        <Select
                            placeholder={"Departamento"}
                            className="react-select-basic"
                            key={item.state}
                            classNamePrefix="select"
                            defaultValue={item.state ? getSelectOption(item.state) : undefined}
                            isDisabled={false}
                            onChange={(value) => stateChange(colombiaStatesAndCities.find((x) => x.state === value?.id))}
                            isClearable={true}
                            isRtl={false}
                            isSearchable={true}
                            name="seller"
                            options={colombiaStatesAndCities.map((item) => getSelectOption(item.state))}
                        />
                    </SelectBody>
                </InputGroup>

                <InputGroup>
                    <SelectBody>
                        <span className="label">{"Ciudad"}</span>
                        <Select
                            placeholder={"Ciudad"}
                            className="react-select-basic"
                            key={item.state}
                            classNamePrefix="select"
                            defaultValue={item.city ? getSelectOption(item.city) : undefined}
                            isDisabled={false}
                            onChange={(value) => onChange({ target: { name: "city", value: value?.id } })}
                            isClearable={true}
                            isRtl={false}
                            isSearchable={true}
                            name="seller"
                            options={
                                !item.state
                                    ? []
                                    : colombiaStatesAndCities
                                          .find((x) => x.state.toUpperCase() === item.state.toUpperCase())
                                          ?.cities.map((item) => getSelectOption(item))
                            }
                        />
                    </SelectBody>
                </InputGroup>
            </FlexRow>
            <InputGroup>
                <span className="label">Dirección</span>
                <DefaultInput required name="description" onChange={onChange} value={item.description} placeholder="Dirección" />
            </InputGroup>

            <FlexRow>
                <InputGroup className="mr-1">
                    <span className="label">Datos adiciones</span>
                    <DefaultInput
                        name="additionalInformation"
                        onChange={onChange}
                        value={item.additionalInformation}
                        placeholder="Torre, Piso..."
                    />
                </InputGroup>

                <InputGroup>
                    <span className="label">Barrio</span>
                    <DefaultInput name="neighborhood" onChange={onChange} value={item.neighborhood} placeholder="Barrio" />
                </InputGroup>
            </FlexRow>
        </AddressContainerFormView>
    );
}
