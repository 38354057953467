import { ProductCategory, IProductCategory } from "./productCategory";
import { IPaginationResponse, IPaginationRequest, WahioFile } from ".";
import { IProductCompany } from "./productCompany";
import { getRandomString } from "../../utils";
import { v4 } from "uuid";

export const REQUEST_PRODUCTS = "REQUEST_PRODUCTS";
export const RECEIVE_PRODUCTS = "RECEIVE_PRODUCTS";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const INVALID_REQUEST_PRODUCTS = "INVALID_REQUEST_PRODUCTS";

export type ProductPriceTypes =
    | "basePrice"
    | "price"
    | "price2"
    | "price3"
    | "price4"
    | "price5"
    | "price6"
    | "price7"
    | "price8"
    | "price9"
    | "price10";

export interface IProduct {
    id: string;
    accountId: string;
    name: string;
    description: string;
    characteristics: string;
    categoryId?: string;
    category: IProductCategory;
    customCategoryId?: string;
    customCategory: IProductCategory;
    basePrice: number;
    controlInventory: boolean;
    profilePicture: string;
    code: string;
    codeType: number;
    sku: string;
    brandId?: string;
    brand?: IProductCompany;
    manufacturerId?: string;
    manufacturer?: IProductCompany;
    globalStock: number;
    dateCreated: Date;
    dateUpdated: Date;
    status: string;
    reference: string;

    reviewRating: number;
    reviewCount: number;
    itemWidth: number;
    itemLength: number;
    itemHeight: number;
    itemWeight: number;

    //COSTS
    totalPurchaseTotalPriceSum: number;
    totalPurchasePriceSum: number;
    totalPurchaseCount: number;
    totalPurchaseStockSum: number;
    purchaseWeightedAverage: number;
    purchaseAverageCost: number;
    lastPurchasePrice: number;
    lastPurchaseDate?: Date;

    //SALES
    totalSaleTotalPriceSum: number;
    totalSalePriceSum: number;
    totalSaleCount: number;
    totalSaleStockSum: number;
    saleWeightedAverage: number;
    saleAverageCost: number;
    lastSalePrice: number;
    lastSaleDate?: Date;

    price: number;
    price2: number;
    price3: number;
    price4: number;
    price5: number;
    price6: number;
    price7: number;
    price8: number;
    price9: number;
    price10: number;

    isParent: boolean;
    hasTaxes: boolean;
    availableOnline: boolean;
    fastShipping: boolean;
    childs: IProductChild[];
    images?: WahioFile[];
    files?: WahioFile[];
    productCategories: IProductSubCategory[];

    variants: IProductVariant[];
    variantOptions?: VariantOption[];
    variantOptionsLine: string;

    includesVariants: boolean;
}

export interface IProductVariant {
    id?: string;
    productId: string;
    code: string;
    sku: string;
    image: string;
    basePrice: number;
    price: number;
    price2: number;
    price3: number;
    price4: number;
    price5: number;
    price6: number;
    price7: number;
    price8: number;
    price9: number;
    price10: number;
    dateCreated?: Date;
    valuesLine: string;
    variantsLine: string;

    reference: string;
    itemWidth: number;
    itemLength: number;
    itemHeight: number;
    itemWeight: number;

    options: VariantOptionValue[];

    images?: WahioFile[];
    files?: WahioFile[];
}

export class ProductVariantImp implements IProductVariant {
    id?: string;
    productId: string = "";
    code: string = "";
    sku: string = "";
    image: string = "";
    price: number = 1;
    basePrice: number = 0;
    price2: number = 0;
    price3: number = 0;
    price4: number = 0;
    price5: number = 0;
    price6: number = 0;
    price7: number = 0;
    price8: number = 0;
    price9: number = 0;
    price10: number = 0;
    dateCreated?: Date | undefined;
    options: VariantOptionValue[] = [];
    valuesLine: string = "";
    variantsLine: string = "";
    reference: string = "";
    itemWidth: number = 0;
    itemLength: number = 0;
    itemHeight: number = 0;
    itemWeight: number = 0;
    files?: WahioFile[];

    constructor(options: VariantOptionValue[], product: IProduct) {
        this.options = options;
        this.basePrice = product.basePrice;
        this.price = product.price;
        this.price2 = product.price2;
        this.price3 = product.price3;
        this.price4 = product.price4;
        this.price5 = product.price5;
        this.price6 = product.price6;
        this.price7 = product.price7;
        this.price8 = product.price8;
        this.price9 = product.price9;
        this.price10 = product.price10;
    }
}

export interface VariantOption {
    key: string;
    name: string;
    index: number;
    dateCreated?: Date;
    values: string[];
}

export class VariantOptionImpl implements VariantOption {
    name: string;
    index: number;
    dateCreated?: Date | undefined;
    values: string[];
    key: string;

    constructor() {
        this.name = "";
        this.index = 0;
        this.values = [];
        this.key = getRandomString();
    }
}

export interface VariantOptionValue {
    key: string;
    variantName: string;
    value: string;
    index: number;
}

export class VariantOptionValueImpl implements VariantOptionValue {
    variantName: string = "";
    key: string = "";
    value: string = "";
    index: number = 0;

    constructor(value: string, variantName: string) {
        this.value = value;
        this.variantName = variantName;
        this.key = getRandomString();
    }
}

export interface IProductChild {
    id?: string;
    parentId?: string;
    product?: IProduct;
    childId: string;
    child?: IProduct;
    quantity: number;
}

export interface IProductSubCategory {
    id?: string;
    productId: string;
    customCategoryId: string;
    customCategory: IProductCategory;
    dateCreated?: Date;
}

export class ProductSubCategory implements IProductSubCategory {
    id?: string;
    productId: string;
    customCategoryId: string;
    customCategory: IProductCategory;
    dateCreated?: Date | undefined;

    constructor(productId: string, customCategory: IProductCategory) {
        this.productId = productId;
        this.customCategoryId = customCategory.id ?? "";
        this.customCategory = customCategory;
    }
}

export interface IProductOrderImport {
    productId?: string;
    codeOrSku: string;
    product?: IProduct;
    quantity: number;
    price: number;
    message: string;
}

export interface IProductState {
    pagination: IPaginationResponse<IProduct>;
    error: any;
    isFetching: boolean;
    isError: boolean;
}

export interface IProductCreationState {
    product: IProduct;
}

export class Product implements IProduct {
    id: string = "";
    accountId: string = "";
    name: string = "";
    description: string = "";
    categoryId?: string;
    category: IProductCategory = new ProductCategory("");
    customCategoryId?: string;
    customCategory: IProductCategory = new ProductCategory("");
    basePrice: number = 0;
    controlInventory: boolean = true;
    profilePicture: string = "";
    code: string = "";
    codeType: number = 0;
    sku: string = "";
    globalStock: number = 0;
    dateCreated: Date = new Date();
    dateUpdated: Date = new Date();
    status: string = "";
    reference: string = "";
    fastShipping: boolean = false;
    price: number = 0;
    price2: number = 0;
    price3: number = 0;
    price4: number = 0;
    price5: number = 0;
    price6: number = 0;
    price7: number = 0;
    price8: number = 0;
    price9: number = 0;
    price10: number = 0;
    isParent: boolean = false;
    hasTaxes: boolean = false;
    availableOnline: boolean = false;
    childs: IProductChild[] = [];
    images?: WahioFile[] = [];
    files?: WahioFile[] = [];
    productCategories: IProductSubCategory[] = [];

    brandId?: string | undefined;
    brand?: IProductCompany | undefined;
    manufacturerId?: string | undefined;
    manufacturer?: IProductCompany | undefined;

    characteristics: string = "";
    reviewRating: number = 0;
    reviewCount: number = 0;
    itemWidth: number = 0;
    itemLength: number = 0;
    itemHeight: number = 0;
    itemWeight: number = 0;

    totalPurchaseTotalPriceSum: number = 0;
    totalPurchasePriceSum: number = 0;
    totalPurchaseCount: number = 0;
    totalPurchaseStockSum: number = 0;
    purchaseWeightedAverage: number = 0;
    purchaseAverageCost: number = 0;
    lastPurchasePrice: number = 0;
    lastPurchaseDate?: Date;

    totalSaleTotalPriceSum: number = 0;
    totalSalePriceSum: number = 0;
    totalSaleCount: number = 0;
    totalSaleStockSum: number = 0;
    saleWeightedAverage: number = 0;
    saleAverageCost: number = 0;
    lastSalePrice: number = 0;
    lastSaleDate?: Date;

    includesVariants: boolean = false;
    variantOptions?: VariantOption[] | undefined;
    variants: IProductVariant[] = [];
    variantOptionsLine: string = "";

    constructor(accountId: string) {
        this.accountId = accountId;
        this.reference = v4().substring(0, 4).toUpperCase();
    }
}

export type ProductSortOption = "default" | "price" | "date" | "stock" | "name" | "category";
export interface ISearchProductSort {
    sortDesc: boolean;
    sort: ProductSortOption;
}
export interface IProductSearchModel extends IPaginationRequest, ISearchProductSort {
    accountId: string;
    brandId?: string;
    manufacturerId?: string;
    categoryId?: string;
    customCategoryId?: string;
    searchValue?: string;
    status?: string;
    onlyInventoryProducts?: boolean;
    onlyProductWithImage?: boolean;
    onlyProductWithoutImage?: boolean;
    minPrice?: number;
    maxPrice?: number;
    customCategoryIds: string[];
}

export interface IRequestProduct {
    type: typeof REQUEST_PRODUCTS;
    value: IProductSearchModel;
    combine?: boolean;
}

export interface IAddProduct {
    type: typeof ADD_PRODUCT;
    value: IProduct;
}

export interface IReceiveProducts {
    type: typeof RECEIVE_PRODUCTS;
    value: IPaginationResponse<IProduct>;
    combine?: boolean;
}
export interface IFetchInvalid {
    type: typeof INVALID_REQUEST_PRODUCTS;
    value: any;
}

export type ProductTypes = IRequestProduct | IReceiveProducts | IFetchInvalid | IAddProduct;

export interface IProductSort extends ISearchProductSort {
    name: string;
}

export const productSortOptions: IProductSort[] = [
    {
        name: "Más vendido",
        sortDesc: true,
        sort: "default",
    },
    {
        name: "Menos vendido",
        sortDesc: false,
        sort: "default",
    },
    {
        name: "Precio menor a mayor",
        sortDesc: false,
        sort: "price",
    },
    {
        name: "Precio mayor a menor",
        sortDesc: true,
        sort: "price",
    },
    {
        name: "Mayor Stock",
        sortDesc: true,
        sort: "stock",
    },
    {
        name: "Menor Stock",
        sortDesc: false,
        sort: "stock",
    },
    {
        name: "Nombre Descendente",
        sortDesc: true,
        sort: "name",
    },
    {
        name: "Nombre Ascendente",
        sortDesc: false,
        sort: "name",
    },
    {
        name: "Categoria Descendente",
        sortDesc: true,
        sort: "category",
    },
    {
        name: "Categoria Ascendente",
        sortDesc: false,
        sort: "category",
    },
];
