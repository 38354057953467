import React, { createContext, useReducer, useEffect } from "react";
import { saveData, StoreKey } from "..";
import { IProductCompanyActions, useActions } from "../actions/productCompany";
import { applyMiddleware } from "../middlewares/productCompany";
import { productCompanyReducer, initState } from "../reducers/productCompany";
import { IProductCompanyState } from "../types/productCompany";

interface IContextProps {
    productCompanyState: IProductCompanyState;
    productCompanyActions: IProductCompanyActions;
}

export const ProductCompanyContext = createContext({} as IContextProps);

const ProductCompanyContextProvider = (props: any) => {
    const [productCompanyState, dispatch] = useReducer(productCompanyReducer, initState);

    useEffect(() => {
        saveData(StoreKey.productCompany, productCompanyState);
    }, [productCompanyState]);

    const productCompanyActions = useActions(productCompanyState, applyMiddleware(dispatch));

    return <ProductCompanyContext.Provider value={{ productCompanyState, productCompanyActions }}>{props.children}</ProductCompanyContext.Provider>;
};

export default ProductCompanyContextProvider;
