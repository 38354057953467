import React, { useState, useEffect, InputHTMLAttributes } from "react";
import customNumeral from "../../utils/numberFormat/moneyFormat";
import { InputCustom } from "./styled";

type Formats = "number" | "money" | "percentage";

interface ITextBoxMoneyProps extends InputHTMLAttributes<HTMLInputElement> {
    onNumberChange: (value: number) => void;
    value?: string | number;
    format: Formats;
}

const formatValues = {
    number: "",
    money: "$0,0.[00]",
    percentage: "0%",
};

export default function TextBoxNumeral(props: ITextBoxMoneyProps) {
    const format = formatValues[props.format];
    const [priceFormat, setPriceFormat] = useState({
        value: props.value ?? "0",
        show: false,
    });
    useEffect(() => {
        setPriceFormat({ ...priceFormat, value: customNumeral(props.value).format(format) });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);

    const onChangePrice = (e: any) => {
        var number = customNumeral(e.target.value);
        let value = e.target.value;

        setPriceFormat({ value: value, show: true });
        props.onNumberChange(number.value());
    };
    const onBlurPrice = (e: any) => {
        let number = customNumeral(e.target.value);
        if (props.format === "percentage") {
            number.set(number.value() / 100);
        }
        setPriceFormat({ value: number.format(format), show: false });
        props.onNumberChange(number.value());
    };
    const onFocusPrice = (e: any) => {
        var number = customNumeral(e.target.value);
        if (props.format === "percentage") {
            number.set(number.value() * 100);
        }
        let value = number.value();
        value = value.toString().replace(".", ",");
        setPriceFormat({ value, show: true });
    };
    return (
        <InputCustom
            type="text"
            className="textbox-numeral"
            name={props.name ? props.name : "price"}
            onBlur={onBlurPrice}
            onFocus={onFocusPrice}
            value={priceFormat.value}
            onChange={onChangePrice}
            placeholder="Add a value"
        />
    );
}
