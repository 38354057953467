import React, { createContext, useReducer, useEffect } from "react";
import { accountUserReducer, IAccountUserState, initState } from "../reducers/accountUser";
import { saveData, StoreKey } from "..";
import { IAccountUserActions, useActions } from "../actions/accountUser";
import { applyMiddleware } from "../middlewares/accountUser";

interface IContextProps {
    accountUserState: IAccountUserState;
    accountUserActions: IAccountUserActions;
}

export const AccountUserContext = createContext({} as IContextProps);

const AccountUserContextProvider = (props: any) => {
    const [accountUserState, dispatch] = useReducer(accountUserReducer, initState);

    useEffect(() => {
        saveData(StoreKey.accountUser, accountUserState);
    }, [accountUserState]);

    const accountUserActions = useActions(accountUserState, applyMiddleware(dispatch));

    return <AccountUserContext.Provider value={{ accountUserState, accountUserActions }}>{props.children}</AccountUserContext.Provider>;
};

export default AccountUserContextProvider;
 