import { Dispatch } from "react";
import {
    ICurrentProductCatalogState,
    IProductCatalog,
    IUpdateCurrentProductCatalog,
    ProductCatalogTypes,
    UPDATE_CURRENT_PRODUCT_CATALOG,
} from "../types/productCatalog";

export interface IProductCatalogActions {
    update: (productCatalog: IProductCatalog) => void;
}

export const actionUpdate = (productCatalog: IProductCatalog): IUpdateCurrentProductCatalog => {
    return {
        type: UPDATE_CURRENT_PRODUCT_CATALOG,
        value: productCatalog,
    };
};

export const useActions = (state: ICurrentProductCatalogState, dispatch: Dispatch<ProductCatalogTypes>): IProductCatalogActions => ({
    update: (productCatalog: IProductCatalog) => {
        dispatch(actionUpdate(productCatalog));
    },
});
