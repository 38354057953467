import styled from "styled-components";
import SearchInputTimer from "../inputs/SearchInputTimer";

export const RelativeContainer = styled.div`
    position: relative;
`;

export const FlexContainer = styled.div`
    display: flex;
    grid-gap: 10px;
    align-items: center;
    justify-content: flex-end;
`;

export const TopView = styled(FlexContainer)`
    position: sticky;
    top: 55px;
    padding: 10px 20px;
    background: ${(props) => props.theme.palette.background};
    justify-content: space-between;
    border-bottom: 1px solid ${(props) => props.theme.palette.backgroundLight};
    flex-wrap: wrap;
    z-index: 2;
`;

export const SearchCustom = styled(SearchInputTimer)`
    border-radius: 50px;
`;

export const AllContainer = styled.div`
    .btn-container {
        button {
            min-width: 25px;
            width: 25px;
            height: 25px;
        }
    }
    .dropbtn {
        border-radius: 50px;
    }
    .light {
        font-size: 0.95rem;
        color: ${(props) => props.theme.palette.textLight};
    }
    .pright {
        padding-left: 10px;
        padding-right: 15px;
    }
    .style-neutral {
        background: ${(props) => props.theme.palette.backgroundDark};
        color: ${(props) => props.theme.palette.textLight};
        border-color: transparent;
    }
    .style-orange {
        background: #ff99002d;
        color: #ff9800 !important;
        border-color: transparent;
    }
    .style-blue {
        background: #2962ff2c;
        color: #2962ff;
        border-color: transparent;
    }
    .style-red {
        background: #f443362d;
        color: #f44336;
        border-color: transparent;
    }
    .style-green {
        background: #00968838;
        color: #009688;
        border-color: transparent;
    }
    .res-active {
        background: transparent;
        color: ${(props) => props.theme.palette.textLight};
        border-color: transparent;
    }

    .decorator {
        border-radius: 22px;
        color: ${(props) => props.theme.palette.textLight};
        display: inline-flex;
        padding: 1px 11px;
        align-items: center;
        font-size: 0.9rem;
        grid-gap: 6px;
        &.active {
            background: ${(props) => props.theme.palette.backgroundLight};
            span {
                color: #2962ff;
            }
        }
        span {
            font-size: 0.8rem;
        }
    }
`;
