import React, { useContext } from "react";
import { CurrentProductCatalogContext } from "../../store/contexts/CurrentProductCatalogContext";
import { ISaleItem } from "../../store/types/sale";
import { formatMoney } from "../../utils";
import { SquareButton } from "../controls";
import TextBoxNumeral from "../TextBoxNumeral";
import {
    IconButton,
    QuantityContainer,
    IconQuantityButton,
    ImageContainer,
    RowContainer,
    PriceContainer,
    CardContainer,
    ImageCardContainer,
    FlexContainer,
    CardBody,
    FlexSpaceBetween,
} from "./styled";
//import "../../animations/fadeEffects/fadeInColor.css";

export interface SaleItemActionsProps {
    onUpdateItem: (item: ISaleItem) => void;
    onRemoveItem: (item: ISaleItem) => void;
}

interface SaleItemViewProps {
    saleItem: ISaleItem;
}

interface SaleItemRowProps extends SaleItemActionsProps {
    saleItem: ISaleItem;
}

const QuantityCard = (props: SaleItemRowProps) => {
    const increaseQuantity = (value: number) => {
        const newquantity = props.saleItem.quantity + value;
        if (newquantity < 1) return;
        props.onUpdateItem({ ...props.saleItem, quantity: newquantity });
    };

    const updateQuantity = (value: number) => {
        if (value <= 0) return;
        props.onUpdateItem({ ...props.saleItem, quantity: value });
    };

    return (
        <QuantityContainer>
            <IconQuantityButton onClick={() => increaseQuantity(-1)}>
                <span className="wahioicon-minus"></span>
            </IconQuantityButton>
            <TextBoxNumeral format="number" type="text" value={props.saleItem.quantity} onNumberChange={updateQuantity} />
            <IconQuantityButton onClick={() => increaseQuantity(1)}>
                <span className="wahioicon-plus"></span>
            </IconQuantityButton>
        </QuantityContainer>
    );
};

export default function SaleItemRow(props: SaleItemRowProps) {
    const { saleItem } = props;
    const { product } = saleItem;
    const { currentProductCatalogState } = useContext(CurrentProductCatalogContext);
    const { productCatalog } = currentProductCatalogState;

    return (
        <RowContainer key={saleItem.quantity} className="fade-in-background">
            <td className="min-width">
                <IconButton className="delete" onClick={() => props.onRemoveItem(saleItem)}>
                    <span className="wahioicon-trash-alt"></span>
                </IconButton>
            </td>
            <td className="min-width">
                <ImageContainer>
                    <img src={product.profilePicture} alt="" />
                </ImageContainer>
            </td>
            <td>
                <span>{product.name}</span>
                <br />
                {saleItem.warehouse && <span className="light">{saleItem.warehouse.name}</span>}
            </td>
            <td>
                <PriceContainer>
                    <span>{formatMoney(saleItem.unitPrice)}</span>
                </PriceContainer>
            </td>
            <td>
                <QuantityCard {...props} />
            </td>
            <td>{productCatalog?.showPrice ? <span>{formatMoney(saleItem.unitPrice * saleItem.quantity)}</span> : "-"}</td>
            <td>
                <IconButton>
                    <span className="wahioicon-ellipsis-h"></span>
                </IconButton>
            </td>
        </RowContainer>
    );
}

export function SaleItemRowView(props: SaleItemViewProps) {
    const { saleItem } = props;
    const { product } = saleItem;

    return (
        <RowContainer>
            <td className="min-width">
                <ImageContainer>
                    <img src={product.profilePicture} alt="" />
                </ImageContainer>
            </td>
            <td>
                <span>{product.name}</span>
                <br />
                {saleItem.warehouse && <span className="light">{saleItem.warehouse.name}</span>}
            </td>
            <td>{formatMoney(saleItem.unitPrice)}</td>
            <td>{saleItem.quantity}</td>
            <td>
                <span>{formatMoney(saleItem.unitPrice * saleItem.quantity)}</span>
            </td>
        </RowContainer>
    );
}

export function SaleItemCard(props: SaleItemRowProps) {
    const { saleItem } = props;
    const { product } = saleItem;

    const { currentProductCatalogState } = useContext(CurrentProductCatalogContext);
    const { productCatalog } = currentProductCatalogState;

    return (
        <CardContainer key={saleItem.quantity} className="fade-in-background">
            <ImageCardContainer>
                <img src={product.profilePicture} alt="" />
            </ImageCardContainer>
            <CardBody>
                <div>
                    <span>{product.name}</span>
                    <br />
                    {saleItem.warehouse && <span className="light">{saleItem.warehouse.name}</span>}
                </div>
                <FlexSpaceBetween>
                    <PriceContainer>
                        {productCatalog?.showPrice ? <span>{formatMoney(saleItem.unitPrice)}</span> : <span>$ Precio Oculto</span>}
                    </PriceContainer>
                    <QuantityCard {...props} />
                </FlexSpaceBetween>
                <FlexSpaceBetween>
                    <FlexContainer>
                        <span className="total">Total:</span>{" "}
                        {productCatalog?.showPrice ? <span>{formatMoney(saleItem.unitPrice * saleItem.quantity)}</span> : "-"}
                    </FlexContainer>
                    <FlexContainer>
                        <SquareButton className="action delete" onClick={() => props.onRemoveItem(saleItem)}>
                            <span className="wahioicon-trash-alt"></span>
                        </SquareButton>
                    </FlexContainer>
                </FlexSpaceBetween>
            </CardBody>
        </CardContainer>
    );
}
