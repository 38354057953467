import { SOCKETIO_SERVER_ENDPOINT } from "./appconfig";
import socketIOClient from "socket.io-client";

export const EventKey = {
    sessionConnected: "session_connected",
    deviceActived: "deviceActived",
    useHere: "session_device_use_here",
    roomSuscribe: "room_subscribe",
    roomChange: "on_room_changed",
    messageRoom: "message_room",
};

export const socket = socketIOClient(SOCKETIO_SERVER_ENDPOINT, {
    timeout: 5000,
    forceNew: true,
    reconnection: false,
});

export const sendSocketEvent = (eventKey: string, data: any) => {
    socket.emit(eventKey, data);
};

export const receiveSocketEvent = (eventKey: string, listener: any) => {
    socket.on(eventKey, listener);
};
