import { IProduct, Product, IProductSearchModel } from "./product";
import { IPaginationResponse } from ".";

export const REQUEST_WAREHOUSE_PRODUCTS = "REQUEST_WAREHOUSE_PRODUCTS";
export const RECEIVE_WAREHOUSE_PRODUCTS = "RECEIVE_WAREHOUSE_PRODUCTS";
export const INVALID_REQUEST_WAREHOUSE_PRODUCTS = "INVALID_REQUEST_WAREHOUSE_PRODUCTS";

export interface IWarehouseItem {
    id?: string;
    dateUpdate: Date;
    stock: number;
    reserved: number;
    warehouseId: string;
}
export interface IWarehouseProduct extends IWarehouseItem {
    productId: string;
    product: IProduct;
}
export class WarehouseProduct implements IWarehouseProduct {
    id?: string;
    productId: string = "";
    product: IProduct = new Product("");
    dateUpdate: Date = new Date();
    stock: number = 0;
    reserved: number = 0;
    warehouseId: string = "";
}

export interface IWarehouseProductState {
    pagination: IPaginationResponse<IWarehouseProduct>;
    error: any;
    isFetching: boolean;
    isError: boolean;
}
export interface IWarehouseProductSearchModel extends IProductSearchModel {
    warehouseId?: string;
    inStockOnly?: boolean;
}
export interface RequestWarehouseProducts {
    type: typeof REQUEST_WAREHOUSE_PRODUCTS;
    value: IWarehouseProductSearchModel;
    combine?: boolean;
}
export interface ReceiveWarehouseProductsType {
    type: typeof RECEIVE_WAREHOUSE_PRODUCTS;
    value: IPaginationResponse<IWarehouseProduct>;
    combine?: boolean;
}
export interface InvalidRequest {
    type: typeof INVALID_REQUEST_WAREHOUSE_PRODUCTS;
    value: any;
}

export type WarehouseProductTypes = RequestWarehouseProducts | ReceiveWarehouseProductsType | InvalidRequest;
