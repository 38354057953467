import React, { useState, useEffect } from "react";
import EmptyImage from "../../assets/images/picture-image.png";
import ProductDetails from "./ProductDetails";
import LoadingDualRing from "../LoadingDualRing";
import { IProduct, IProductVariant } from "../../store/types/product";
import { useParams } from "react-router-dom";
import { wahioFetch } from "../../services";
import { productEndpoint } from "../../services/restApiEndpoint";
import { ContainerBody, HeaderImage, ImageContainer } from "./styled";
import ProductImagesSlider from "./ProductImagesSlider";
import { SquareButton } from "../controls";
import { WahioFile, WahioFileImpl } from "../../store/types";

export enum Stage {
    Detail,
    Images,
    Activity,
}
export interface IProductProfile {
    product: IProduct;
}

interface IProductParams {
    productId?: string;
}

interface ProductProfileProps {
    product?: IProduct;
    onCloseProduct?: () => void;
}

const ProductProfile = (props: ProductProfileProps) => {
    const [product, setProduct] = useState<IProduct | undefined>(props.product);
    const [productVariant] = useState<IProductVariant | undefined>();
    const [isFetching, setIsFetching] = useState(false);
    const params = useParams<IProductParams>();
    const productId = props.product ? props.product.id : params.productId;

    useEffect(() => {
        if (productId) {
            fetchProductById(productId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productId]);

    const fetchProductById = (id: string) => {
        setIsFetching(true);
        return wahioFetch.get(
            productEndpoint.get.product(id),
            (success) => {
                const product: IProduct = success.data;
                setProduct(product);
                setIsFetching(false);
            },
            (error) => {
                setIsFetching(false);
            }
        );
    };

    if (!product) return <LoadingDualRing center={true} />;

    const getImages = (): WahioFile[] => {
        if (productVariant) {
            if (productVariant.images && productVariant.images.length > 0) {
                return productVariant.images;
            } else {
                return [];
            }
        }
        if (product.includesVariants && product.variants && product.variants.length > 0) {
            return product.variants.map((item) => new WahioFileImpl(item.image, "image"));
        }
        return product.images ?? [];
    };
    const images = getImages();

    return (
        <>
            <ContainerBody>
                {props.onCloseProduct && (
                    <SquareButton onClick={props.onCloseProduct} className="close-product">
                        <span className="wahioicon-times"></span>
                    </SquareButton>
                )}
                {images && images.length > 1 ? (
                    <ProductImagesSlider images={images} />
                ) : (
                    <HeaderImage>
                        <ImageContainer>
                            <img className="object-fit-cover" src={images.length > 0 ? images[0].url : EmptyImage} alt={product.name} />
                        </ImageContainer>
                    </HeaderImage>
                )}
                <ProductDetails product={product} />
            </ContainerBody>
            {isFetching && <LoadingDualRing center={true} />}
        </>
    );
};
export default ProductProfile;
