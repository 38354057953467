import { Dispatch } from "react";
import { wahioFetch } from "../../services";
import { getFetchError } from "../../services/fetchApi";
import { accountEndpoint } from "../../services/restApiEndpoint";
import { actionFetchInvalid, actionReceiveAccountUser } from "../actions/accountUser";
import { AccountUserTypes, IAccountUser, REQUEST_ACCOUNT_USER } from "../types/accountUser";

export const applyMiddleware = (dispatch: Dispatch<AccountUserTypes>) => (action: AccountUserTypes) => {
    switch (action.type) {
        case REQUEST_ACCOUNT_USER:
            dispatch(action);

            wahioFetch.get(
                accountEndpoint.get.accountUserById(action.accountUserId),
                (res) => {
                    const user: IAccountUser = res.data;
                    dispatch(actionReceiveAccountUser(user));
                },
                (error) => {
                    dispatch(actionFetchInvalid(getFetchError(error)));
                }
            );

            return;
        default:
            dispatch(action);
    }
};
