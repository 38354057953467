import { Dispatch } from "react";
import { ProductTypes, REQUEST_PRODUCTS } from "../types/product";
import { wahioFetch } from "../../services";
import { actionFetchInvalid, actionReceive, actionRequest } from "../actions/product";
import { productEndpoint } from "../../services/restApiEndpoint";

export const applyMiddleware = (dispatch: Dispatch<ProductTypes>) => (action: ProductTypes) => {
    switch (action.type) {
        case REQUEST_PRODUCTS:
            dispatch(actionRequest(action.value));
            return wahioFetch.post(
                productEndpoint.post.productSearchQuery,
                action.value,
                (success) => {
                    dispatch(actionReceive(success.data, action.combine));
                },
                (error) => {
                    dispatch(actionFetchInvalid(error));
                }
            );
        default:
            dispatch(action);
    }
};