import { Dispatch } from "react";
import {
    AddSeller,
    ADD_SELLER,
    InvalidRequest,
    INVALID_REQUEST_SELLERS,
    ISeller,
    ISellerState,
    ReceiveSellers,
    RECEIVE_SELLERS,
    RequestSellers,
    REQUEST_SELLERS,
    SellerTypes,
    UpdateSeller,
    UPDATE_SELLER,
} from "../types/seller";

export interface ISellerActions {
    request: () => void;
    receive: (items: ISeller[]) => void;
    fetchInvalid: (error: any) => void;
    add: (seller: ISeller) => void;
    update: (seller: ISeller) => void;
}

export const actionRequest = (): RequestSellers => {
    return {
        type: REQUEST_SELLERS,
    };
};

export const actionReceive = (items: ISeller[]): ReceiveSellers => {
    return {
        type: RECEIVE_SELLERS,
        items,
    };
};

export const actionFetchInvalid = (er: any): InvalidRequest => {
    return {
        type: INVALID_REQUEST_SELLERS,
        value: er,
    };
};

export const ActionAdd = (seller: ISeller): AddSeller => {
    return {
        type: ADD_SELLER,
        value: seller,
    };
};

export const ActionUpdate = (seller: ISeller): UpdateSeller => {
    return {
        type: UPDATE_SELLER,
        value: seller,
    };
};

export const useActions = (state: ISellerState, dispatch: Dispatch<SellerTypes>): ISellerActions => ({
    request: () => {
        dispatch(actionRequest());
    },
    receive: (items: ISeller[]) => {
        dispatch(actionReceive(items));
    },
    fetchInvalid: (error: any) => {
        dispatch(actionFetchInvalid(error));
    },
    add: (value: ISeller) => {
        dispatch(ActionAdd(value));
    },
    update: (value: ISeller) => {
        dispatch(ActionUpdate(value));
    },
});
