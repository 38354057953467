import styled from "styled-components";
import { DefaultButton } from "../controls";
import { breakpoint } from "../../theme/themeProvider";
import SearchInputTimer from "../controls/inputs/SearchInputTimer";

interface IContainerProps {
    topPosition: number;
}
export const MainContainer = styled.div`
    position: relative;
    overflow: auto;
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: calc(100vh - 55px);

    .categories {
        display: flex;
        flex-direction: row;

        &.show {
            display: flex;
            flex-direction: column;
            .category-card {
                padding: 10px;
            }
        }
    }

    .filter {
        display: flex;
    }

    ${breakpoint("md")`
        flex-direction: row;
       
        .categories {
            display: flex;
            flex-direction: column;
        }
        .filter {
            display: none;
        }
    `}

    .title {
        font-size: 1.3rem;
        font-weight: 500;
    }

    .icon-view {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        .icon {
            width: 25px;
            min-width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: ${(props) => props.theme.palette.primary}20;
            color: ${(props) => props.theme.palette.primary};
            border-radius: 50px;
            margin-right: 10px;
        }
    }

    .header {
        padding-bottom: 5px;
        .title {
            margin: 0px;
        }
    }

    .menu-container {
        display: flex;
        flex-direction: column;
        position: sticky;
        top: 0px;
        min-width: 300px;
        padding: 15px;
        padding-left: 25px;
        padding-bottom: 0px;
        height: auto;
        z-index: 4;
        background: ${(props) => props.theme.palette.background};
    }

    .social-line {
        display: flex;
        margin-bottom: 10px;
    }
    ${breakpoint("md")`
    .social-line { 
        display: none;
    }
    `}
    .product-container {
        display: flex;
        flex-direction: column;
        width: 100%;

        &.hideproduct {
            display: none;
        }
        ${breakpoint("md")`
        &.hideproduct {
            display: flex;
        }
    `}
    }
`;

export const CategoriesContainer = styled.div`
    display: flex;
    flex-direction: row;
    overflow: auto;
    padding-right: 10px;
    margin-top: 10px;

    .card-category {
        display: flex;
        align-items: center;
        padding: 3px 10px;
        border-radius: 15px;
        cursor: pointer;

        &.active {
            background: ${(props) => props.theme.palette.primary};
            color: white;
        }

        .remove {
            margin-left: auto;
            background: ${(props) => props.theme.palette.primaryDark};
            color: white;
        }
        &:hover {
            background: ${(props) => props.theme.palette.backgroundLight};
            &.active {
                background: ${(props) => props.theme.palette.primaryDark};
                color: white;
            }
        }
        .text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 200px;
            padding-right: 10px;
        }
        .image {
            width: 35px;
            min-width: 35px;
            height: 35px;
            overflow: hidden;
            border-radius: 40px;
            margin-right: 10px;
            background: ${(props) => props.theme.palette.backgroundLight};
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }

    ${breakpoint("md")`
    flex-direction: column;

    .card-category{ 
        padding: 10px;
    }
    `}
`;

export const ProductCategoryHeader = styled.div`
    display: flex;
    align-items: center;
    padding-left: 15px;
    padding: 5px 10px;
    padding-top: 30px;
    .title-category {
        margin: 0px;
        font-weight: 300;
    }
    .icon {
        background: transparent;
        margin-right: 15px;
        &:hover {
            background: ${(props) => props.theme.palette.backgroundDark};
        }
    }
`;

export const ProductSquareContainer = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    grid-auto-rows: 1fr;
    padding: 1rem;

    ${breakpoint("sm")`
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    grid-gap: 10px;
    `}

    ${breakpoint("md")`
    grid-template-columns: repeat(auto-fill, minmax(215px, 1fr));
    grid-gap: 20px;
    `}

    ${breakpoint("xxl2")`
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    grid-gap: 20px;
    `}
`;

export const ProductRowContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FlexContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const WarehouseCard = styled(DefaultButton)`
    font-size: 0.9rem;
    color: ${(props) => props.theme.palette.textLight};
    font-weight: 500;
    margin-right: 10px;
    display: inline-flex;
    padding: 5px 10px;
    background: ${(props) => props.theme.palette.backgroundLight};

    border-radius: 15px;
    border: 0px;
    font-weight: 400;
`;

export const ControlsContainer = styled.div`
    display: flex;
    padding-top: 2px;
    flex-direction: column;
    background: ${(props) => props.theme.palette.background};

    .search-input {
        input {
            border-radius: 40px;
        }
    }
    .barcode {
        color: white;
        background: ${(props) => props.theme.palette.primary};
    }
`;

export const SearchContainerView = styled.div`
    display: flex;
    width: 100%;
    background: pink;
    margin-right: 10px;
    background: ${(props) => props.theme.palette.backgroundLight};
    border-radius: 30px;
    .search-input {
        margin: 0px;
    }

    input {
        border: 0px;
        background: transparent;
    }
    .dropdownvalue {
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
    }
    .dropbtn {
        border: 0px;
        background: transparent;
        font-size: 0.9rem;
        padding-right: 7px;
        height: 100%;
        border-right: 1px solid ${(props) => props.theme.palette.backgroundDark};
        border-radius: 0;
        .icon {
            margin-left: 6px;
            margin-right: 0px;
        }
    }
`;
export const SearchCustom = styled(SearchInputTimer)`
    border-radius: 0px;
`;

export const ResultLabel = styled.span`
    font-size: 0.9rem;
    color: ${(props) => props.theme.palette.textLight};
    display: block;
    white-space: nowrap;
    padding: 10px 0px;
`;

export const LoadingContainer = styled.div`
    text-align: center;
    padding-top: 1.5rem;
    padding-bottom: 1rem;
    position: absolute;
    width: calc(100% - 25px);
    top: 96px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
`;

export const LoadingBody = styled.div`
    background: ${(props) => props.theme.palette.background};
    padding: 10px;
    display: flex;
    border-radius: 50px;
    box-shadow: ${(props) => props.theme.palette.shadow};
`;

export const MoreItemsContainer = styled.div`
    text-align: center;
    padding-top: 1.5rem;
    padding-bottom: 1rem;
    display: flex;
    justify-content: center;
    button {
        border-radius: 50px;
        color: ${(props) => props.theme.palette.textLight};
    }
`;

export const WarehouseContainer = styled.div`
    display: flex;
    flex-direction: column;

    padding-left: 21px;
    padding-right: 21px;
    padding-bottom: 20px;
    .selected {
        background: ${(props) => props.theme.palette.primary};
        border-color: transparent;
        color: white;
        span {
            color: white;
        }
    }

    button {
        border-radius: 50px;
        border: 0;
        justify-content: start;
        font-weight: 400;
        margin-bottom: 5px;
        span {
            color: ${(props) => props.theme.palette.textLight};
            font-size: 0.8rem;
        }
    }
`;

export const FilterContainerView = styled.div`
    display: none;
    flex-direction: column;

    .prices-container {
        display: flex;
        margin-top: 15px;
        align-items: end;
        button {
            padding: 9px;
            font-size: 0.9rem;
            color: ${(props) => props.theme.palette.textLight};
            background: ${(props) => props.theme.palette.backgroundLight};
        }
        label {
            color: ${(props) => props.theme.palette.textLight};
            font-size: 0.9rem;
            margin-bottom: 3px;
        }
        input {
            border-radius: 50px;
        }
        .price-group {
            display: flex;
            flex-direction: column;
        }
    }

    &.show {
        display: flex;
    }

    ${breakpoint("md")`
        display: flex;
    `}
`;
