import { Dispatch } from "react";
import { IPaginationResponse } from "../types";
import {
    AddPurchase,
    ADD_PURCHASE,
    PurchaseTypes,
    IPurchase,
    IPurchaseState,
    InvalidRequest,
    INVALID_REQUEST_PURCHASES,
    ReceivePurchases,
    RECEIVE_PURCHASES,
    RequestPurchases,
    REQUEST_PURCHASES,
    UpdatePurchase,
    UPDATE_PURCHASE,
    IPurchaseSearchModel,
} from "../types/purchase";

export interface IPurchaseActions {
    request: (search: IPurchaseSearchModel) => void;
    receive: (value: IPaginationResponse<IPurchase>) => void;
    fetchInvalid: (error: any) => void;
    add: (customer: IPurchase) => void;
    update: (customer: IPurchase) => void;
}

export const actionRequest = (search: IPurchaseSearchModel): RequestPurchases => {
    return {
        type: REQUEST_PURCHASES,
        value: search,
    };
};

export const actionReceive = (value: IPaginationResponse<IPurchase>): ReceivePurchases => {
    return {
        type: RECEIVE_PURCHASES,
        value,
    };
};

export const actionFetchInvalid = (er: any): InvalidRequest => {
    return {
        type: INVALID_REQUEST_PURCHASES,
        value: er,
    };
};

export const ActionAdd = (customer: IPurchase): AddPurchase => {
    return {
        type: ADD_PURCHASE,
        value: customer,
    };
};

export const ActionUpdate = (customer: IPurchase): UpdatePurchase => {
    return {
        type: UPDATE_PURCHASE,
        value: customer,
    };
};

export const useActions = (state: IPurchaseState, dispatch: Dispatch<PurchaseTypes>): IPurchaseActions => ({
    request: (search: IPurchaseSearchModel) => {
        dispatch(actionRequest(search));
    },
    receive: (value: IPaginationResponse<IPurchase>) => {
        dispatch(actionReceive(value));
    },
    fetchInvalid: (error: any) => {
        dispatch(actionFetchInvalid(error));
    },
    add: (value: IPurchase) => {
        dispatch(ActionAdd(value));
    },
    update: (value: IPurchase) => {
        dispatch(ActionUpdate(value));
    },
});
