import { getStoredData, StoreKey } from "..";
import { OrderStatusOptionsType } from "../types/order";

import { ICurrentPurchaseState, IPurchase, Purchase, PurchaseTypes, UPDATE_CURRENT_PURCHASE } from "../types/purchase";

export const emptyPurchase = (status?: OrderStatusOptionsType) => {
    return new Purchase("", "", "");
};

export class InitStateBase implements ICurrentPurchaseState {
    currentPurchase: IPurchase = emptyPurchase();
}

export const initState = getStoredData<ICurrentPurchaseState>(new InitStateBase(), StoreKey.currentPurchase);

export const currentPurchaseReducer = (state: ICurrentPurchaseState, action: PurchaseTypes): ICurrentPurchaseState => {
    switch (action.type) {
        case UPDATE_CURRENT_PURCHASE:
            return {
                ...state,
                currentPurchase: action.value,
            };

        default:
            return state;
    }
};
