import styled from "styled-components";
import { breakpoint } from "../../theme/themeProvider";
import { DefaultButton, SquareButton, PrimaryButton } from "../../components/controls";
import { NavLink } from "react-router-dom";

export const horMenuHeightx1 = "55px";
export const horMenuHeightx2 = "111px";
const menuWidthMinus = "-220px";
export const menuWidth = "220px";
export const smallMenu = "80px";

export const HorizontalMenu = styled.div`
    width: 100%;
    height: ${horMenuHeightx1};
    background: ${(props) => props.theme.palette.background};
    display: flex;
    border-bottom: 1px solid ${(props) => props.theme.palette.backgroundDark};
    padding: 0px 28px;
    box-sizing: border-box;
    align-items: center;
    position: fixed;
    z-index: 10;
    .btn-delivery {
        border-radius: 50px;
        background: #ff57221c;
        color: #ff5722;
        border: 0px;
    }
    .hidemobile {
        display: none;
    }
    ${breakpoint("md")`
    .hidemobile { 
        display: flex;
    }
    `}
`;

interface IVerticalProps {
    menuOpen: boolean;
}

export const VerticalMenuContainer = styled.div`
    position: relative;
    width: 100%;
    max-width: ${menuWidth};
    &.small-menu {
        max-width: ${smallMenu};
        transition: max-width 0.3s;
    }
    height: calc(100vh - ${horMenuHeightx1});
    border-right: 1px solid transparent;
    position: fixed;
    background: ${(props) => props.theme.palette.background};
    top: ${horMenuHeightx1};
    z-index: 10;
    left: ${(props: IVerticalProps) => (props.menuOpen ? 0 : menuWidthMinus)};
    ${breakpoint("md")`
    left: 0;
    `}
    .btn-size {
        visibility: hidden;
    }
    &:hover {
        .btn-size {
            visibility: visible;
        }
    }
`;

export const ButtonMenuSize = styled(DefaultButton)`
    position: absolute;
    right: -15px;
    top: 16px;
    width: 30px;
    border-radius: 50px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const AppSubContainer = styled.div`
    display: flex;
`;

export const CustomIcon = styled.svg``;

export const AppChildrenContainer = styled.div`
    width: 100%;
    box-sizing: border-box;
    margin-left: 0px;
    margin-top: 55px;
    transition: all 0.3s;
`;

export const AppHeader = styled(NavLink)`
    display: flex;
    align-items: center;

    .image {
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 50px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .social-line {
        display: none;
        margin-left: 15px;
    }
    ${breakpoint("md")`
    .social-line { 
      
        display: block;     
    }
    `}
`;

export const AppTitle = styled.span`
    font-weight: 500;
    margin-left: 0.5rem;
    color: ${(props) => props.theme.palette.text};
    display: flex;
    flex-direction: column;
    .subtitle {
        font-size: 0.9rem;
        color: ${(props) => props.theme.palette.textLight};
        font-weight: 400;
    }
`;

export const RightPanel = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;

    .cart {
        margin-right: 10px;
    }

    .dropbtn {
        border-radius: 22px;
        width: 33px;
        height: 33px;
    }
`;

export const MenuButton = styled(SquareButton)`
    display: flex;
    ${breakpoint("md")`
     display: none;
        `}
`;

export const UserPanel = styled.div`
    width: 34px;
    height: 34px;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    border-radius: 50px;
    transition: all 0.3s;
    &:hover {
        border-color: transparent;
        box-shadow: 0px 2px 4px ${(props) => props.theme.palette.shadow};
    }
`;

export const UserImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const MenuBackgroundGlobal = styled.div`
    width: 100%;
    height: 100%;
    background: #525252b5;
    position: fixed;
    z-index: 4;
`;

export const UserTitle = styled.p`
    font-weight: 500;
`;

export const OrgContainer = styled(NavLink)`
    display: flex;
    margin: 10px 10px;
    align-items: center;
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 5px;
    border-radius: 9px;
    span {
        color: ${(props) => props.theme.palette.text};
    }
    &.active {
        background: ${(props) => props.theme.palette.backgroundLight};
        color: ${(props) => props.theme.palette.primary};
    }
    &:hover {
        background: ${(props) => props.theme.palette.backgroundDark};
    }
`;

export const OrgImageContainer = styled.div`
    width: 35px;
    height: 35px;
    min-width: 35px;
    border-radius: 50px;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

export const OrgTitle = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    margin: 0;
    padding-left: 15px;
`;

export const OfflineContainer = styled.div`
    background: #ff5722;
    color: #ffffff;
    padding: 5px 20px;
    z-index: 100;
    position: fixed;
    bottom: 0;
    width: 100%;
`;

export const ContactCard = styled.div`
    display: flex;
    align-items: center;

    .icon {
        width: 30px;
        height: 30px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${(props) => props.theme.palette.backgroundLight};
        color: ${(props) => props.theme.palette.textLight};
    }
`;

export const ButtonCarCustom = styled(PrimaryButton)`
    background: ${(props) => props.theme.palette.primary};
    position: relative;
    margin-right: 15px;

    &.fade-in {
        right: 0;
        background: ${(props) => props.theme.palette.primary};
        animation: fadeInLeft ease 1s;
    }

    @keyframes fadeInLeft {
        0% {
            right: 0;
            background: ${(props) => props.theme.palette.primary};
        }
        20% {
            right: 15px;
            background: ${(props) => props.theme.palette.primaryDark};
        }
        80% {
            right: 15px;
            background: ${(props) => props.theme.palette.primaryDark};
        }
        100% {
            right: 0;
            background: ${(props) => props.theme.palette.primary};
        }
    }
`;
