import styled from "styled-components";

export const HomeContainer = styled.div`
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    box-sizing: border-box;
`;

export const LoginContainer = styled.div`
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 10px;
    padding-top: 25px;
    box-sizing: border-box;
`;
export const SubContainer = styled.div`
    padding: 20px;
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    .subtitle {
        font-size: 0.9rem;
        color: ${(props) => props.theme.palette.textLight};
    }
`;

export const MainTitle = styled.div`
    margin: 11px;
    margin-bottom: 0px;
    margin-top: 15px;
    padding: 10px 19px;
    border-radius: 8px;
    .subcontainer {
        display: flex;
        flex-direction: column;
    }
    .title {
        margin: 0;
        margin-bottom: 5px;
    }
    .sub {
        font-size: 0.9rem;
        color: ${(props) => props.theme.palette.textLight};
    }
`;
