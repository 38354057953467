import React, { useState, useEffect, useContext } from "react";
import {
    SubContainer,
    OrderContainer,
    TopView,
    MainContainer,
    OrderContainerParent,
    TopLabelContainer,
    DraftContainer,
    DraftSubcontainer,
    EditHeaderContainer,
} from "./styled";
import { PrimaryButton } from "../controls";
import { lisetenerContainerResize, getInitialWidth, formatMoney } from "../../utils";
import { AppSettingsContext } from "../../appSettings/AppSettingsContext";
import { FormattedMessage, useIntl } from "react-intl";
import messages from "./messages";
import { ISale, ISaleItem } from "../../store/types/sale";
import OrderSummary from "./OrderSummary";
import { CurrentSaleContext } from "../../store/contexts/CurrentSaleContext";
import { calculateTotal, getAbsoluteAmount, getTotalItemsCounter, updateSaleItemsDefaultPrices } from "./helpers";
import TotalLabel from "./TotalLabel";
import ModalFinalizeOrder from "./ModalFinalizeOrder";
import OrderDetails from "./OrderDetails";
import LoadingDualRing from "../LoadingDualRing";
import { wahioFetch } from "../../services";
import { localorderEndpoint } from "../../services/restApiEndpoint";
import { emptySale } from "../../store/reducers/currentSale";
import { ISaveResponse } from "./FinalizeOrderForm";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { CurrentCustomerContext } from "../../store/contexts/CurrentCustomerContext";
import { CurrentProductCatalogContext } from "../../store/contexts/CurrentProductCatalogContext";
import { useAlert } from "react-alert";
import { DeliveryOrder } from "../../store/types/deliveryOrder";
import { appEventPushOrigin } from "../../App";
import { OrderStatusOptionsType } from "../../store/types/order";
import { EventKey, sendSocketEvent } from "../../services/sessionManager";

const CONTAINERID = "order-container-key";
const limitContainer = 800;

interface ICurrentParam {
    orderId: string;
}

interface IEditResult {
    isFetching: boolean;
    isError: boolean;
    value: ISale;
}

export interface OrderNotificationProps {
    userImage?: string;
    userName: string;
    orderId: string;
    orderStatus: OrderStatusOptionsType;
    orderNumber: string;
    address: string;
    origin: appEventPushOrigin;
    appOrigin: string;
    catalog?: {
        catalogName?: string;
        contactName?: string;
    };
    totalItems: number;
    firstProduct: {
        name: string;
        quantity: number;
        image?: string;
    };
    close?: () => void;
}

const CreateSaleForm = () => {
    const intl = useIntl();
    const params = useParams<ICurrentParam>();
    const alert = useAlert();

    const { appSettingsState } = useContext(AppSettingsContext);
    const { currentSaleState, currentSaleActions } = useContext(CurrentSaleContext);
    const { currentProductCatalogState } = useContext(CurrentProductCatalogContext);
    const { currentCustomerState } = useContext(CurrentCustomerContext);
    const [containerWidth, setContainerWidth] = useState(1000);
    const [openFinalizeModal, setOpenFinalizeModal] = useState(false);

    const [editResult, setEditResult] = useState<IEditResult>({
        isFetching: false,
        isError: false,
        value: emptySale(),
    });
    const [responseResult, setResponseResult] = useState<ISaveResponse>({
        isFetching: false,
        isError: false,
    });

    useEffect(() => {
        lisetenerContainerResize(setContainerWidth, CONTAINERID);
        setContainerWidth(getInitialWidth(CONTAINERID));
    }, []);

    useEffect(() => {
        if (params.orderId) {
            setEditResult({ ...editResult, isFetching: true });
            wahioFetch.get(
                localorderEndpoint.get.orderById(params.orderId),
                (success) => {
                    setEditResult({ ...responseResult, isFetching: false, isError: false, value: success.data });
                },
                (error) => {
                    setResponseResult({ ...responseResult, isFetching: false, isError: true });
                }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.orderId]);

    const isMobile = containerWidth <= limitContainer;
    const currentSale = params.orderId ? editResult.value : currentSaleState.currentSale;

    useEffect(() => {
        const { productCatalog } = currentProductCatalogState;
        if (productCatalog) {
            const newItems = updateSaleItemsDefaultPrices(currentSale.items, productCatalog.price);
            updateSaleItems(newItems);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSale.items.length]);

    useEffect(() => {
        const { customer } = currentCustomerState;
        const { productCatalog } = currentProductCatalogState;
        if (customer && productCatalog) {
            if (customer.addreses && customer.addreses.length > 0) {
                let value = customer.addreses[0];
                let initialDelivery = currentSale.deliveryOrder
                    ? currentSale.deliveryOrder
                    : new DeliveryOrder(productCatalog.accountId, "");

                const deliveryOrder = {
                    ...initialDelivery,
                    ...(value as any),
                    address: value.description,
                    id: undefined,
                    accountId: productCatalog.accountId,
                };
                onUpdateCurrentSale({ ...currentSale, deliveryOrder, customerId: customer.id, customer });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCustomerState.customer]);

    const updateSaleItems = (items: ISaleItem[]) => {
        const totalAmountItems = calculateTotal(items);
        const percentageDiscount = currentSale.percentageDiscount ?? 0;
        const totalDiscount = totalAmountItems * (percentageDiscount / 100);
        onUpdateCurrentSale({
            ...currentSale,
            items,
            totalAmountItems,
            percentageDiscount,
            totalDiscount,
            organizationId: appSettingsState.defaultOrganizationId,
        });
    };

    const onUpdateCurrentSale = (value: ISale) => {
        const { account } = currentCustomerState;
        if (params.orderId && editResult.value) {
            setEditResult({ ...editResult, value });
        } else currentSaleActions.update({ ...value, account });
    };

    const onClickFinalize = () => {
        setOpenFinalizeModal(true);
        onUpdateCurrentSale({
            ...currentSale,
            status: "queued",
            organizationId: appSettingsState.defaultOrganizationId,
        });
    };
    const onClickCancel = () => {
        setOpenFinalizeModal(false);
    };

    const totalItems = getTotalItemsCounter(currentSale.items);

    const onRemoveItem = (value: ISaleItem) => {
        let newItems: ISaleItem[] = [];
        currentSale.items.forEach((item) => {
            if (item.productId === value.productId && item.warehouseId === value.warehouseId) {
            } else {
                newItems.push(item);
            }
        });
        updateSaleItems(newItems);
    };

    const sendSockedChanges = (order: ISale) => {
        let item: ISaleItem | undefined = undefined;
        if (order.items.length > 0) {
            item = order.items[0];
        }
        let data: OrderNotificationProps = {
            userName: "",
            userImage: "",
            orderNumber: order.number.toString(),
            orderStatus: order.status,
            orderId: order.id ?? "notfound",
            address: order.deliveryOrder?.address ?? "",
            origin: "newOrder",
            appOrigin: "catalog",
            catalog: {
                contactName: order.customer?.firstName + " " + order.customer?.lastName,
                catalogName: currentProductCatalogState.productCatalog?.name,
            },
            firstProduct: {
                name: item?.product?.name ?? "Not product",
                image: item?.product?.profilePicture,
                quantity: item?.quantity ?? 0,
            },

            totalItems: order.items.length,
        };
        sendSocketEvent(EventKey.messageRoom, {
            roomId: "NEWORDER_" + currentCustomerState.account?.id,
            data,
        });
    };

    const onFinish = () => {
        let order = currentSale;

        if (!order.deliveryOrder) {
            alert.error("Los datos de envío son obligatorios");
            return;
        } else if (order.deliveryOrder) {
            let value = order.deliveryOrder;
            if (!value.address || !value.contactName || !value.state || !value.city || !value.phoneNumber) {
                alert.error("Falta información en los datos de envío.");
                return;
            }
        }

        setResponseResult({ ...responseResult, isFetching: true });
        const { account } = currentCustomerState;
        const accountId = account?.id;

        order.status = "queued";
        order.source = "catalog";
        order.organizationId = "queued";
        order.allowLogisticsService = !!account?.allowLogisticsService;
        order.accountId = accountId ?? "";
        if (!order.customer) {
            order.customer = currentCustomerState.customer;

            if (!order.customer) {
                alert.error("El cliente en requerido");
                return;
            }
        }

        wahioFetch.post(
            localorderEndpoint.post.orderUpdateCreateAll,
            order,
            (success) => {
                onUpdateCurrentSale(emptySale());
                setOpenFinalizeModal(false);
                setResponseResult({
                    isFetching: false,
                    isError: false,
                    value: success.data,
                    isSave: true,
                });
                sendSockedChanges(success.data);
            },
            (error) => {
                setResponseResult({
                    isFetching: false,
                    isError: true,
                    value: undefined,
                    isSave: false,
                    error,
                });
            }
        );
    };
    const onUpdateItem = (value: ISaleItem) => {
        let newItems: ISaleItem[] = [];
        currentSale.items.forEach((item) => {
            if (item.productId === value.productId && item.warehouseId === value.warehouseId) {
                newItems.push(value);
            } else {
                newItems.push(item);
            }
        });
        updateSaleItems(newItems);
    };

    const orderProps = {
        onRemoveItem,
        onUpdateItem,
        isMobile,
    };
    const onFinalizeClose = () => {
        setOpenFinalizeModal(false);
    };
    const ViewDraftResult = () => {
        if (responseResult.isSave && responseResult.value) {
            return (
                <DraftContainer className="mb-1">
                    <DraftSubcontainer>
                        <span>La orden fue enviada con éxito.</span>
                        <strong className="number">#{responseResult.value.number}</strong>
                    </DraftSubcontainer>
                </DraftContainer>
            );
        }
        return null;
    };
    if (editResult.isFetching) {
        return <LoadingDualRing center={true} />;
    }

    const getWhatsappMessage = () => {
        let message = "Hola, Estoy interesado en estos productos.%0a%0a";
        let lines = currentSale.items.map((item) => {
            let line = `${item.product.name} Sku: ${item.product.sku}%0a`;
            line += "Cantidad: " + item.quantity;
            line += "%0a";
            line += "%0a";
            return line;
        });
        message += lines;

        if (currentSale.deliveryOrder) {
            let delivery = currentSale.deliveryOrder;
            message += `Contacto: ${delivery.contactName}`;
            message += "%0a";
            let address = `Dirección: ${delivery.address}, ${delivery.state}, ${delivery.city}`;
            var encodedURL = encodeURIComponent(address);
            message += encodedURL;
        }

        return message;
    };

    return (
        <MainContainer>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    {params.orderId
                        ? `${intl.formatMessage(messages.editOrden)} #${currentSale.number}`
                        : intl.formatMessage(messages.createOrder)}{" "}
                    - Wahio
                </title>
            </Helmet>
            {params.orderId && (
                <EditHeaderContainer>
                    <h3>Editando orden: #{currentSale.number}</h3>
                </EditHeaderContainer>
            )}
            {openFinalizeModal && (
                <ModalFinalizeOrder
                    {...{ responseResult, setResponseResult, currentSale, onFinalizeClose }}
                    updateSale={onUpdateCurrentSale}
                    onFinalize={onFinish}
                    onCancel={onClickCancel}
                    show={openFinalizeModal}
                    setShow={setOpenFinalizeModal}
                />
            )}
            {<ViewDraftResult />}
            <>
                <TopView>
                    {currentCustomerState.customer && (
                        <PrimaryButton className="space" disabled={totalItems === 0} onClick={onClickFinalize}>
                            <FormattedMessage {...messages.finish} />
                        </PrimaryButton>
                    )}
                    {currentProductCatalogState.productCatalog?.allowOrderWithWhatsapp && (
                        <a
                            target="blank"
                            href={`https://wa.me/${
                                currentProductCatalogState.productCatalog?.phoneNumber ?? ""
                            }?text=${getWhatsappMessage()}`}
                        >
                            <PrimaryButton className="space" disabled={totalItems === 0}>
                                <span className="wahioicon-whatsapp"></span> Enviar por Whatsapp
                            </PrimaryButton>
                        </a>
                    )}
                    {responseResult.isFetching && <LoadingDualRing small={true} />}

                    {currentProductCatalogState.productCatalog?.showPrice && (
                        <TopLabelContainer>
                            <TotalLabel title={intl.formatMessage(messages.total)} value={formatMoney(getAbsoluteAmount(currentSale))} />
                        </TopLabelContainer>
                    )}
                </TopView>
                <OrderDetails onUpdateSale={onUpdateCurrentSale} currentSale={currentSale} isMobile={isMobile} hide={false} />
            </>
            <SubContainer id={CONTAINERID}>
                <OrderContainerParent>
                    <OrderContainer>
                        <OrderSummary {...orderProps} items={currentSale.items} />
                    </OrderContainer>
                </OrderContainerParent>
            </SubContainer>
        </MainContainer>
    );
};

export default CreateSaleForm;
