import { Dispatch } from "react";
import { wahioFetch } from "../../services";
import { productEndpoint } from "../../services/restApiEndpoint";
import { actionFetchInvalid, actionReceive, actionRequest } from "../actions/customCategory";
import { CustomCategoryTypes, REQUEST_CUSTOM_CATEGORIES } from "../types/customCategory";

export const applyMiddleware = (dispatch: Dispatch<CustomCategoryTypes>) => (action: CustomCategoryTypes) => {
    switch (action.type) {
        case REQUEST_CUSTOM_CATEGORIES:
            dispatch(actionRequest(action.accountId));
            return wahioFetch.get(
                productEndpoint.get.customCategories(action.accountId),
                (success) => {
                    dispatch(actionReceive(success.data));
                },
                (error) => {
                    dispatch(actionFetchInvalid(error));
                }
            );
        default:
            dispatch(action);
    }
};
