import React from "react";
import { IOrganization } from "../../store/types/organizationTypes";
import { IProductCatalog } from "../../store/types/productCatalog";
import { SocialContainerView } from "./styled";

interface SocialMediaIconsProps {
    organization: IOrganization;
    productCatalog: IProductCatalog;
    className?: string;
}
export default function SocialMediaIcons(props: SocialMediaIconsProps) {
    const { organization, productCatalog } = props;

    const handleClick = (url: string) => {
        window.open(url);
    };

    return (
        <SocialContainerView className={props.className ?? ""}>
            {organization.socialFacebookLink && (
                <a
                    className="icon-link"
                    target="blank"
                    onClick={() => handleClick(organization.socialFacebookLink)}
                    href={organization.socialFacebookLink}
                >
                    <span className="wahioicon-facebook-f"></span>
                </a>
            )}
            {organization.socialInstagramLink && (
                <a
                    className="icon-link"
                    target="blank"
                    onClick={() => handleClick(organization.socialInstagramLink)}
                    href={organization.socialInstagramLink}
                >
                    <span className="wahioicon-instagram"></span>
                </a>
            )}
            {organization.socialTwitterLink && (
                <a
                    className="icon-link"
                    target="blank"
                    onClick={() => handleClick(organization.socialTwitterLink)}
                    href={organization.socialTwitterLink}
                >
                    <span className="wahioicon-twitter"></span>
                </a>
            )}
            {organization.socialTiktokLink && (
                <a
                    className="icon-link"
                    target="blank"
                    href={organization.socialTiktokLink}
                    onClick={() => handleClick(organization.socialTiktokLink)}
                >
                    <span className="wahioicon-tiktok"></span>
                </a>
            )}
            {organization.socialYoutubeLink && (
                <a
                    target="blank"
                    href={organization.socialYoutubeLink}
                    onClick={() => handleClick(organization.socialYoutubeLink)}
                    className="icon-link"
                    rel="noreferrer noopener"
                >
                    <span className="wahioicon-youtube"></span>
                </a>
            )}
            {productCatalog.phoneNumber && (
                <a
                    className="icon-link"
                    target="blank"
                    onClick={() => handleClick(`https://wa.me/${productCatalog.phoneNumber}`)}
                    href={`https://wa.me/${productCatalog.phoneNumber}`}
                >
                    <span className="wahioicon-whatsapp"></span>
                </a>
            )}
        </SocialContainerView>
    );
}
