import { defineMessages } from "react-intl";

const base = "pages.createSale";

export default defineMessages({
    createOrder: {
        id: `${base}.createOrder`,
        defaultMessage: "Crear venta",
    },
    editOrden: {
        id: `${base}.editOrden`,
        defaultMessage: "Editar orden",
    },
    finish: {
        id: `${base}.finish`,
        defaultMessage: "Finalizar",
    },
    price: {
        id: `${base}.price`,
        defaultMessage: "Precio",
    },
    seller: {
        id: `${base}.seller`,
        defaultMessage: "Vendedor",
    },
    customer: {
        id: `${base}.customer`,
        defaultMessage: "Cliente",
    },
    delivery: {
        id: `${base}.delivery`,
        defaultMessage: "Domicilio",
    },
    deliveryCost: {
        id: `${base}.deliveryCost`,
        defaultMessage: "Costo de envío",
    },
    total: {
        id: `${base}.total`,
        defaultMessage: "Total",
    },
    subTotal: {
        id: `${base}.subTotal`,
        defaultMessage: "SubTotal",
    },
    addCustomerToContinue: {
        id: `${base}.addCustomerToContinue`,
        defaultMessage: "Agrega un cliente para continuar",
    },
    noProductSelected: {
        id: `${base}.noProductSelected`,
        defaultMessage: "Seleccione un producto para crear una nueva orden",
    },
    finishPaid: {
        id: `${base}.finishPaid`,
        defaultMessage: "Finalizar Pagado",
    },
    finishPartial: {
        id: `${base}.finishPartial`,
        defaultMessage: "Finalizar con pago partial",
    },
    finishCredit: {
        id: `${base}.finishCredit`,
        defaultMessage: "Finalizar como credito",
    },
    payments: {
        id: `${base}.payments`,
        defaultMessage: "Pagos",
    },
    addPayment: {
        id: `${base}.addPayment`,
        defaultMessage: "Agregar pago",
    },
    addOtherPayment: {
        id: `${base}.addOtherPayment`,
        defaultMessage: "Agregar otro pago",
    },
    paymentExceedsOrderValue: {
        id: `${base}.paymentExceedsOrderValue`,
        defaultMessage: "El pago supera el valor de la orden",
    },
    cancelModal: {
        id: `${base}.cancelModal`,
        defaultMessage: "Cancelar",
    },
    amount: {
        id: `${base}.amount`,
        defaultMessage: "Valor",
    },
    discount: {
        id: `${base}.discount`,
        defaultMessage: "Descuento",
    },
    tip: {
        id: `${base}.tip`,
        defaultMessage: "Propina",
    },
});
