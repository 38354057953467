import React, { createContext, useReducer, useEffect } from "react";
import { saveData, StoreKey } from "..";
import { IOrganizationActions, useActions } from "../actions/organization";
import { applyMiddleware } from "../middlewares/organization";
import { initState, organizationReducer } from "../reducers/organization";
import { IOrganizationState } from "../types/organizationTypes";

interface IContextProps {
    organizationState: IOrganizationState;
    organizationActions: IOrganizationActions;
}

export const OrganizationContext = createContext({} as IContextProps);

const OrganizationContextProvider = (props: any) => {
    const [organizationState, dispatch] = useReducer(organizationReducer, initState);

    useEffect(() => {
        saveData(StoreKey.organization, organizationState);
    }, [organizationState]);

    const organizationActions = useActions(organizationState, applyMiddleware(dispatch));

    return <OrganizationContext.Provider value={{ organizationState, organizationActions }}>{props.children}</OrganizationContext.Provider>;
};

export default OrganizationContextProvider;
