import { Dispatch } from "react";
import {
    AddCustomer,
    ADD_CUSTOMER,
    CustomerTypes,
    ICustomer,
    ICustomerState,
    InvalidRequest,
    INVALID_REQUEST_CUSTOMERS,
    ReceiveCustomers,
    RECEIVE_CUSTOMERS,
    RequestCustomers,
    REQUEST_CUSTOMERS,
    UpdateCustomer,
    UPDATE_CUSTOMER,
} from "../types/customer";

export interface ICustomerActions {
    request: () => void;
    receive: (items: ICustomer[]) => void;
    fetchInvalid: (error: any) => void;
    add: (customer: ICustomer) => void;
    update: (customer: ICustomer) => void;
}

export const actionRequest = (): RequestCustomers => {
    return {
        type: REQUEST_CUSTOMERS,
    };
};

export const actionReceive = (items: ICustomer[]): ReceiveCustomers => {
    return {
        type: RECEIVE_CUSTOMERS,
        items,
    };
};

export const actionFetchInvalid = (er: any): InvalidRequest => {
    return {
        type: INVALID_REQUEST_CUSTOMERS,
        value: er,
    };
};

export const ActionAdd = (customer: ICustomer): AddCustomer => {
    return {
        type: ADD_CUSTOMER,
        value: customer,
    };
};

export const ActionUpdate = (customer: ICustomer): UpdateCustomer => {
    return {
        type: UPDATE_CUSTOMER,
        value: customer,
    };
};

export const useActions = (state: ICustomerState, dispatch: Dispatch<CustomerTypes>): ICustomerActions => ({
    request: () => {
        dispatch(actionRequest());
    },
    receive: (items: ICustomer[]) => {
        dispatch(actionReceive(items));
    },
    fetchInvalid: (error: any) => {
        dispatch(actionFetchInvalid(error));
    },
    add: (value: ICustomer) => {
        dispatch(ActionAdd(value));
    },
    update: (value: ICustomer) => {
        dispatch(ActionUpdate(value));
    },
});
