import { Dispatch } from "react";
import { IPaginationResponse } from "../types";
import {
    AddSale,
    ADD_SALE,
    SaleTypes,
    ISale,
    ISaleState,
    InvalidRequest,
    INVALID_REQUEST_SALES,
    ReceiveSales,
    RECEIVE_SALES,
    RequestSales,
    REQUEST_SALES,
    UpdateSale,
    UPDATE_SALE,
    ISaleSearchModel,
} from "../types/sale";

export interface ISaleActions {
    request: (search: ISaleSearchModel) => void;
    receive: (value: IPaginationResponse<ISale>) => void;
    fetchInvalid: (error: any) => void;
    add: (customer: ISale) => void;
    update: (customer: ISale) => void;
}

export const actionRequest = (search: ISaleSearchModel): RequestSales => {
    return {
        type: REQUEST_SALES,
        value: search,
    };
};

export const actionReceive = (value: IPaginationResponse<ISale>): ReceiveSales => {
    return {
        type: RECEIVE_SALES,
        value,
    };
};

export const actionFetchInvalid = (er: any): InvalidRequest => {
    return {
        type: INVALID_REQUEST_SALES,
        value: er,
    };
};

export const ActionAdd = (customer: ISale): AddSale => {
    return {
        type: ADD_SALE,
        value: customer,
    };
};

export const ActionUpdate = (customer: ISale): UpdateSale => {
    return {
        type: UPDATE_SALE,
        value: customer,
    };
};

export const useActions = (state: ISaleState, dispatch: Dispatch<SaleTypes>): ISaleActions => ({
    request: (search: ISaleSearchModel) => {
        dispatch(actionRequest(search));
    },
    receive: (value: IPaginationResponse<ISale>) => {
        dispatch(actionReceive(value));
    },
    fetchInvalid: (error: any) => {
        dispatch(actionFetchInvalid(error));
    },
    add: (value: ISale) => {
        dispatch(ActionAdd(value));
    },
    update: (value: ISale) => {
        dispatch(ActionUpdate(value));
    },
});
