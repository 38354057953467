import styled from "styled-components";

export const SocialContainerView = styled.div`
    display: flex;
    .icon-link {
        width: 30px;
        height: 30px;
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 40px;
        margin-right: 10px;

        &:hover {
            background: ${(props) => props.theme.palette.backgroundDark};
        }
    }
`;
