import { getStoredData, StoreKey } from "..";
import { IAccount } from "../types/accountUser";

import { ICurrentProductCatalogState, IProductCatalog, ProductCatalogTypes, UPDATE_CURRENT_PRODUCT_CATALOG } from "../types/productCatalog";

export class InitStateBase implements ICurrentProductCatalogState {
    productCatalog?: IProductCatalog = undefined;
    account?: IAccount = undefined;
}

export const initState = getStoredData<ICurrentProductCatalogState>(new InitStateBase(), StoreKey.currentProductCatalog);

export const currentProductCatalogReducer = (state: ICurrentProductCatalogState, action: ProductCatalogTypes): ICurrentProductCatalogState => {
    switch (action.type) {
        case UPDATE_CURRENT_PRODUCT_CATALOG:
            return {
                productCatalog: action.value,
            };

        default:
            return state;
    }
};
