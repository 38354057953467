import { Dispatch } from "react";
import { IPaginationResponse } from "../types";
import {
    InvalidRequest,
    INVALID_REQUEST_WAREHOUSE_PRODUCTS,
    IWarehouseProduct,
    IWarehouseProductSearchModel,
    IWarehouseProductState,
    ReceiveWarehouseProductsType,
    RECEIVE_WAREHOUSE_PRODUCTS,
    RequestWarehouseProducts,
    REQUEST_WAREHOUSE_PRODUCTS,
    WarehouseProductTypes,
} from "../types/warehouseProduct";

export interface IWarehouseProductActions {
    requestWarehouseProductsByAccount: (search: IWarehouseProductSearchModel, combine?: boolean) => void;
    receiveWarehouseProducts: (pagination: IPaginationResponse<IWarehouseProduct>, combine?: boolean) => void;
    fetchInvalid: (error: any) => void;
}

export const actionRequestProducts = (search: IWarehouseProductSearchModel, combine?: boolean): RequestWarehouseProducts => {
    return {
        type: REQUEST_WAREHOUSE_PRODUCTS,
        value: search,
        combine,
    };
};

export const actionReceiveProducts = (
    pagination: IPaginationResponse<IWarehouseProduct>,
    combine?: boolean
): ReceiveWarehouseProductsType => {
    return {
        type: RECEIVE_WAREHOUSE_PRODUCTS,
        value: pagination,
        combine,
    };
};

export const actionFetchInvalid = (er: any): InvalidRequest => {
    return {
        type: INVALID_REQUEST_WAREHOUSE_PRODUCTS,
        value: er,
    };
};

export const useActions = (state: IWarehouseProductState, dispatch: Dispatch<WarehouseProductTypes>): IWarehouseProductActions => ({
    requestWarehouseProductsByAccount: (search: IWarehouseProductSearchModel) => {
        dispatch(actionRequestProducts(search));
    },
    receiveWarehouseProducts: (pagination: IPaginationResponse<IWarehouseProduct>) => {
        dispatch(actionReceiveProducts(pagination));
    },
    fetchInvalid: (error: any) => {
        dispatch(actionFetchInvalid(error));
    },
});
