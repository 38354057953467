import React, { createContext, useReducer, useEffect } from "react";
import { saveData, StoreKey } from "..";
import { IProductCategoryActions, useActions } from "../actions/productCategory";
import { applyMiddleware } from "../middlewares/productCategory";
import { initState, productCategoryReducer } from "../reducers/productCategory";
import { IProductCategoryState } from "../types/productCategory";

interface IContextProps {
    productCategoryState: IProductCategoryState;
    productCategoryActions: IProductCategoryActions;
}

export const ProductCategoryContext = createContext({} as IContextProps);

const ProductCategoryContextProvider = (props: any) => {
    const [productCategoryState, dispatch] = useReducer(productCategoryReducer, initState);

    useEffect(() => {
        saveData(StoreKey.productCategory, productCategoryState);
    }, [productCategoryState]);

    const productCategoryActions = useActions(productCategoryState, applyMiddleware(dispatch));

    return (
        <ProductCategoryContext.Provider value={{ productCategoryState, productCategoryActions }}>
            {props.children}
        </ProductCategoryContext.Provider>
    );
};

export default ProductCategoryContextProvider;
