import React, { Suspense } from "react";
import { BrowserRouter, Switch, Route, RouteProps } from "react-router-dom";
import { I18nProvider } from "./i18n";
import AppSettingsProvider from "./appSettings/AppSettingsContext";
import StyledThemeProvider from "./theme/themeProvider";
import AccountUserContextProvider from "./store/contexts/AccountUserContext";
import ProductContextProvider from "./store/contexts/ProductContext";
import OrganizationContextProvider from "./store/contexts/OrganizationContext";
import WarehouseProductContextProvider from "./store/contexts/WarehouseProductContext";
import CurrentPurchaseContextProvider from "./store/contexts/CurrentPurchaseContext";
import ProductCategoryContextProvider from "./store/contexts/ProductCategoryContext";
import SellerContextProvider from "./store/contexts/SellerContext";
import CustomerContextProvider from "./store/contexts/CustomerContext";
import WarehouseContextProvider from "./store/contexts/WarehouseContext";
import SaleContextProvider from "./store/contexts/SaleContext";
import DeliveryOrderContextProvider from "./store/contexts/DeliveryOrderContext";
import PurchaseContextProvider from "./store/contexts/PurchaseContext";
import SupplierContextProvider from "./store/contexts/SupplierContext";
import CustomCategoryContextProvider from "./store/contexts/CustomCategoryContext";
import ProductCompanyContextProvider from "./store/contexts/ProductCompanyContext";
import CurrentSaleContextProvider from "./store/contexts/CurrentSaleContext";
import CurrentCustomerContextProvider from "./store/contexts/CurrentCustomerContext";
import CurrentProductCatalogContextProvider from "./store/contexts/CurrentProductCatalogContext";
import GlobalStyled from "./globalStyled";
import LoadingDualRing from "./components/LoadingDualRing";
import Home from "./pages/Home";
import AppContainer from "./pages/AppContainer";
import { positions, Provider as AlertProvider, transitions } from "react-alert";
import AlertTemplate from "./components/AlertTemplate";
import { QueryClient, QueryClientProvider } from "react-query";
import PATHS from "./constants/paths";
import { ReactQueryDevtools } from "react-query/devtools";

const queryClient = new QueryClient();

interface IContextProviders {
    children: React.ReactNode;
}

export type appEventPushOrigin = "userPermission" | "accountPermission" | "newOrder";

export const alertOptions = {
    timeout: 5000,
    position: positions.BOTTOM_CENTER,
    transition: transitions.SCALE,
};

const ContextProviders = ({ children }: IContextProviders) => {
    return (
        <AppSettingsProvider>
            <I18nProvider>
                <StyledThemeProvider>
                    <AccountUserContextProvider>
                        <ProductContextProvider>
                            <OrganizationContextProvider>
                                <WarehouseProductContextProvider>
                                    <CurrentPurchaseContextProvider>
                                        <CurrentSaleContextProvider>
                                            <ProductCategoryContextProvider>
                                                <CustomerContextProvider>
                                                    <WarehouseContextProvider>
                                                        <SaleContextProvider>
                                                            <DeliveryOrderContextProvider>
                                                                <PurchaseContextProvider>
                                                                    <SupplierContextProvider>
                                                                        <CustomCategoryContextProvider>
                                                                            <ProductCompanyContextProvider>
                                                                                <SellerContextProvider>
                                                                                    <CurrentCustomerContextProvider>
                                                                                        <CurrentProductCatalogContextProvider>
                                                                                            {children}
                                                                                        </CurrentProductCatalogContextProvider>
                                                                                    </CurrentCustomerContextProvider>
                                                                                </SellerContextProvider>
                                                                            </ProductCompanyContextProvider>
                                                                        </CustomCategoryContextProvider>
                                                                    </SupplierContextProvider>
                                                                </PurchaseContextProvider>
                                                            </DeliveryOrderContextProvider>
                                                        </SaleContextProvider>
                                                    </WarehouseContextProvider>
                                                </CustomerContextProvider>
                                            </ProductCategoryContextProvider>
                                        </CurrentSaleContextProvider>
                                    </CurrentPurchaseContextProvider>
                                </WarehouseProductContextProvider>
                            </OrganizationContextProvider>
                        </ProductContextProvider>
                    </AccountUserContextProvider>
                </StyledThemeProvider>
            </I18nProvider>
        </AppSettingsProvider>
    );
};

interface IRouteValidation extends RouteProps {
    requiredAuth?: boolean;
}

const RouteValidation = (props: IRouteValidation) => {
    return <Route {...props} />;
};

export const App = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <ContextProviders>
                <AlertProvider template={AlertTemplate} {...alertOptions}>
                    <Suspense fallback={<LoadingDualRing center={true} />}>
                        <BrowserRouter>
                            <AppContainer>
                                <Switch>
                                    <RouteValidation path={PATHS.homeCatalog} component={Home} />
                                    <RouteValidation path={PATHS.home} component={Home} />
                                </Switch>
                            </AppContainer>
                            <GlobalStyled />
                        </BrowserRouter>
                    </Suspense>
                </AlertProvider>
            </ContextProviders>
            <ReactQueryDevtools />
        </QueryClientProvider>
    );
};
export default App;
