import { IPaginationResponse } from ".";
import { IProduct } from "./product";
import { IOrderRefund, OrderPayment, PurchasePayment } from "./orderPayment";
import { ISupplier } from "./supplier";

import { IOrderItem, OrderItemStatus, OrderStatusOptionsType, IOrder } from "./order";
import { IWarehouse } from "./warehouse";

export const UPDATE_CURRENT_PURCHASE = "UPDATE_CURRENT_PURCHASE";
export const ADD_PURCHASE = "ADD_PURCHASE";
export const UPDATE_PURCHASE = "UPDATE_PURCHASE";
export const REQUEST_PURCHASES = "REQUEST_PURCHASES";
export const RECEIVE_PURCHASES = "RECEIVE_PURCHASES";
export const INVALID_REQUEST_PURCHASES = "INVALID_REQUEST_PURCHASES";

export interface ICurrentPurchaseState {
    currentPurchase: IPurchase;
}

export interface IPurchaseState {
    pagination: IPaginationResponse<IPurchase>;
    error: any;
    isFetching: boolean;
    isError: boolean;
}

export interface IPurchaseItem extends IOrderItem {
    purchaseId?: string;
    quantityReceived: number;
    quantityReceivedTemp: number;
    warehouse?: IWarehouse;
}

export class PurchaseItem implements IPurchaseItem {
    id?: string;
    productId: string;
    product: IProduct;
    warehouseId: string = "";
    warehouse?: IWarehouse;
    purchaseId?: string;
    quantity: number = 1;
    quantityReceived: number = 0;
    quantityReceivedTemp: number = 0;
    unitPrice: number;
    comment?: string;
    position: number = 0;
    status: OrderItemStatus = "empty";
    constructor(product: IProduct, quantity: number, warehouse?: IWarehouse) {
        this.product = product;
        this.productId = product.id;
        this.unitPrice = product.basePrice;
        this.quantity = quantity;

        if (warehouse && warehouse.id) {
            this.warehouseId = warehouse.id;
            this.warehouse = warehouse;
        }
    }
}

export interface ICurrentSaleState {
    currentPurchase: IPurchase;
}

export interface IStatusHistory {
    id: string;
    purchaseId: string;
    status: OrderStatusOptionsType;
    dateCreated: Date;
}
export interface IItemHistory {
    id: string;
    purchaseId: string;
    productId: string;
    product?: IProduct;
    quantity: number;
    groupId: string;
    dateCreated: Date;
}
export interface IPurchase extends IOrder {
    totalQuantity: number;
    auxid?: string;
    totalQuantityReceived: number;
    deliveryAmount: number;
    supplierId: string;
    supplier?: ISupplier;
    warehouseId: string;
    warehouse?: IWarehouse;
    statusHistory: IStatusHistory[];
    items: IPurchaseItem[];
    payments: PurchasePayment[];
    refunds: IOrderRefund[];
    itemsHistory: IItemHistory[];
}
export class Purchase implements IPurchase {
    id?: string;
    accountId: string;
    auxid?: string = "";
    dateCreated?: Date;
    number: number = 0;
    accountUserId: string;
    totalAmount: number = 0;
    totalPaid: number = 0;
    totalPayment: number = 0;
    totalRefund: number = 0;
    totalQuantity: number = 0;
    totalQuantityReceived: number = 0;
    organizationId: string;
    warehouseId: string = "";
    warehouse?: IWarehouse;
    supplierId: string = "";
    supplier?: ISupplier;
    costCenterId: string = "";
    comment: string = "";
    deliveryAmount: number = 0;
    dateUpdated?: Date | undefined;
    totalAmountItems: number = 0;
    totalItemsDiscount: number = 0;
    totalDiscount: number = 0;
    totalTip: number = 0;
    percentageDiscount: number = 0;
    percentageTip: number = 0;
    moneyReceived: number = 0;
    moneyReturned: number = 0;

    status: OrderStatusOptionsType = "paid";
    statusHistory: IStatusHistory[] = [];
    items: IPurchaseItem[] = [];
    payments: OrderPayment[] = [];
    refunds: IOrderRefund[] = [];
    itemsHistory: IItemHistory[] = [];

    constructor(accountId: string, accountUserId: string, organizationId: string, warehouse?: IWarehouse) {
        this.accountId = accountId;
        this.accountUserId = accountUserId;
        this.organizationId = organizationId;

        if (warehouse) {
            this.warehouseId = warehouse.id ? warehouse.id : "";
            this.warehouse = warehouse;
        }
    }
}

export interface IPurchaseSearchModel {
    accountId: string;
    page: number;
    limit: number;
    organizationId?: string;
    warehouseId?: string;
    supplierId?: string;
    status?: OrderStatusOptionsType;
    searchValue?: string;
}
export interface IUpdateCurrentPurchase {
    type: typeof UPDATE_CURRENT_PURCHASE;
    value: IPurchase;
}

export interface RequestPurchases {
    type: typeof REQUEST_PURCHASES;
    value: IPurchaseSearchModel;
}
export interface ReceivePurchases {
    type: typeof RECEIVE_PURCHASES;
    value: IPaginationResponse<IPurchase>;
}
export interface InvalidRequest {
    type: typeof INVALID_REQUEST_PURCHASES;
    value: any;
}
export interface UpdatePurchase {
    type: typeof UPDATE_PURCHASE;
    value: IPurchase;
}

export interface AddPurchase {
    type: typeof ADD_PURCHASE;
    value: IPurchase;
}

export type PurchaseTypes = RequestPurchases | ReceivePurchases | InvalidRequest | UpdatePurchase | AddPurchase | IUpdateCurrentPurchase;
