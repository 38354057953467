import { Dispatch } from "react";
import { wahioFetch } from "../../services";
import { productEndpoint } from "../../services/restApiEndpoint";
import { actionFetchInvalid, actionReceive, actionRequest } from "../actions/productCategory";
import { ProductCategoryTypes, REQUEST_PRODUCT_CATEGORIES } from "../types/productCategory";

export const applyMiddleware = (dispatch: Dispatch<ProductCategoryTypes>) => (action: ProductCategoryTypes) => {
    switch (action.type) {
        case REQUEST_PRODUCT_CATEGORIES:
            dispatch(actionRequest());
            return wahioFetch.get(
                productEndpoint.get.categories,
                (success) => {
                    dispatch(actionReceive(success.data));
                },
                (error) => {
                    dispatch(actionFetchInvalid(error));
                }
            );
        default:
            dispatch(action);
    }
};
