import { getStoredData, StoreKey } from "..";
import {
    INVALID_REQUEST_CUSTOM_CATEGORIES,
    ICustomCategoryState,
    CustomCategoryTypes,
    RECEIVE_CUSTOM_CATEGORIES,
    REQUEST_CUSTOM_CATEGORIES,
    ADD_CUSTOM_CATEGORY,
} from "../types/customCategory";
import { IProductCategory } from "../types/productCategory";

export class InitStateBase implements ICustomCategoryState {
    items: IProductCategory[] = [];
    error: any = undefined;
    isFetching: boolean = false;
    isError: boolean = false;
}

export const initState = getStoredData<ICustomCategoryState>(new InitStateBase(), StoreKey.customCategory);

export const customCategoryReducer = (state: ICustomCategoryState, action: CustomCategoryTypes): ICustomCategoryState => {
    switch (action.type) {
        case ADD_CUSTOM_CATEGORY:
            return {
                ...state,
                isFetching: false,
                isError: false,
                items: [...state.items, action.value],
            };
        case RECEIVE_CUSTOM_CATEGORIES:
            return {
                ...state,
                isFetching: false,
                isError: false,
                items: action.items,
            };
        case REQUEST_CUSTOM_CATEGORIES:
            return {
                ...state,
                isFetching: true,
                isError: false,
            };
        case INVALID_REQUEST_CUSTOM_CATEGORIES:
            return {
                ...state,
                isFetching: false,
                isError: true,
                error: action.value,
            };
        default:
            return state;
    }
};
