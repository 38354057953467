import { IProduct, ProductPriceTypes } from "../../store/types/product";
import { ISale, ISaleItem, SaleItem } from "../../store/types/sale";
import { IWarehouse } from "../../store/types/warehouse";

interface IInserProductOrderList {
    items: ISaleItem[];
    value: IProduct;
    defaultPrice: ProductPriceTypes;
    warehouse?: IWarehouse;
}

interface ICustomerLabel {
    id?: string;
    auxid?: string;
    customerId?: string;
    label?: string;
}
export interface IContactAddress extends ICustomerLabel {
    description: string;
    latitude?: number;
    longitude?: number;
    country: string;
    state: string;
    city: string;
    contactName: string;
    phoneNumber: string;
    additionalInformation: string;
    neighborhood: string;
}

export const getAbsoluteAmount = (currentSale: ISale) => {
    let deliveryAmount = 0;
    if (currentSale.deliveryOrder && currentSale.isDelivery) {
        deliveryAmount = currentSale.deliveryOrder.amount ?? 0;
    }

    return currentSale.totalAmountItems + deliveryAmount;
};

export const insertProductInOrderList = (props: IInserProductOrderList) => {
    const { items, value, defaultPrice, warehouse } = props;
    let copy: ISaleItem[] = [];
    let exists = false;
    items.forEach((element) => {
        if (element.product?.id === value.id && element.warehouseId === warehouse?.id) {
            exists = true;
            copy.push({ ...element, quantity: element.quantity + 1 });
        } else {
            copy.push(element);
        }
    });
    let newItems: ISaleItem[];
    if (exists) {
        newItems = copy;
    } else {
        const newItem = new SaleItem(value, 1, defaultPrice, warehouse);
        newItems = [...items, newItem];
    }
    return newItems;
};

export const calculateTotal = (items: ISaleItem[]) => {
    let sum = 0;
    items.forEach((element) => {
        sum += element.quantity * element.unitPrice;
    });
    return sum;
};

export const updateSaleItemsDefaultPrices = (items: ISaleItem[], defaultPrice: ProductPriceTypes) => {
    let itemsCopy: ISaleItem[] = [];
    items.forEach((item) => {
        const currenPrice = item.product[item.defaultPrice];
        if (currenPrice === item.unitPrice) {
            item.unitPrice = item.product[defaultPrice];
        }
        item.defaultPrice = defaultPrice;
        itemsCopy.push(item);
    });
    return itemsCopy;
};

export const getTotalItemsCounter = (items?: ISaleItem[]) => {
    if (!items) return 0;
    let value = 0;
    items.forEach((element) => {
        value += element.quantity;
    });
    return value;
};

export const getServiceMessageError = (error: any) => {
    if (error.data && error.data.message) {
        return error.data.message;
    }
    const list: any[] = error.data;

    try {
        if (list && list.length > 0 && typeof list[0] === "string" && typeof list.map === "function") {
            return list.join(", ");
        }

        if (list && list.length > 0 && typeof list.map === "function") {
            const values = list.map((item: any) => (item.message ? item.message : `Error Desconocido`));
            return values.join(", ");
        }

        if (typeof error.data === "string") {
            return error.data;
        }
    } catch (e) {
        console.log(e);
    }
    return "Hubo un error desconocido";
};

export const getContactAddressText = (value?: IContactAddress, short?: boolean) => {
    if (value) {
        let lines: string[] = [];
        if (value.state) {
            lines.push(value.state);
        }
        if (value.city) {
            lines.push(value.city);
        }
        if (!short) {
            if (value.description) {
                lines.push(value.description);
            }
        }
        return lines.join(", ");
    }
    return "...";
};
