import { IGenericState } from ".";
import { IAccount } from "./accountUser";
import { IWarehouse } from "./warehouse";

export const REQUEST_ORGANIZATIONS = "REQUEST_ORGANIZATIONS";
export const RECEIVE_ORGANIZATIONS = "RECEIVE_ORGANIZATIONS";
export const INVALID_REQUEST_ORGANIZATIONS = "INVALID_REQUEST_ORGANIZATIONS";

export interface IOrganization {
    id: string;
    accountId: string;
    defaultWarehouseId: string;
    warehouse: IWarehouse;
    account: IAccount;
    name: string;
    address: string;
    image: string;

    storePhone: string;

    socialFacebookLink: string;
    socialInstagramLink: string;
    socialTwitterLink: string;
    socialYoutubeLink: string;
    socialTiktokLink: string;
}

export interface IOrganizationState extends IGenericState<IOrganization> {}
export interface RequestOrganizations {
    type: typeof REQUEST_ORGANIZATIONS;
}
export interface ReceiveOrganizations {
    type: typeof RECEIVE_ORGANIZATIONS;
    items: IOrganization[];
}
export interface InvalidRequest {
    type: typeof INVALID_REQUEST_ORGANIZATIONS;
    value: any;
}

export type OrganizationTypes = RequestOrganizations | ReceiveOrganizations | InvalidRequest;
