export const ADD_PRODUCT_COMPANY = "ADD_PRODUCT_COMPANY";
export const DELETE_PRODUCT_COMPANY = "DELETE_PRODUCT_COMPANY";
export const UPDATE_PRODUCT_COMPANY = "UPDATE_PRODUCT_COMPANY";
export const REQUEST_PRODUCT_COMPANIES = "REQUEST_PRODUCT_COMPANIES";
export const RECEIVE_PRODUCT_COMPANIES = "RECEIVE_PRODUCT_COMPANIES";
export const INVALID_REQUEST_PRODUCT_COMPANIES = "INVALID_REQUEST_PRODUCT_COMPANIES";

export interface IProductCompany {
    id?: string;
    accountId: string;
    name: string;
    verified: boolean;
}
export class ProductCompany implements IProductCompany {
    accountId: string = "";
    name: string = "";
    verified: boolean = false;
}

export interface IProductCompanyState {
    items: IProductCompany[];
    error: any;
    isFetching: boolean;
    isError: boolean;
}

export interface RequestProductCompanies {
    type: typeof REQUEST_PRODUCT_COMPANIES;
}
export interface ReceiveProductCompanies {
    type: typeof RECEIVE_PRODUCT_COMPANIES;
    items: Array<IProductCompany>;
}
export interface InvalidRequest {
    type: typeof INVALID_REQUEST_PRODUCT_COMPANIES;
    value: any;
}
export interface UpdateProductCompany {
    type: typeof UPDATE_PRODUCT_COMPANY;
    value: IProductCompany;
}

export interface AddProductCompany {
    type: typeof ADD_PRODUCT_COMPANY;
    value: IProductCompany;
}

interface DeleteProductCompany {
    type: typeof DELETE_PRODUCT_COMPANY;
    id: string;
}

export type ProductCompanyTypes =
    | RequestProductCompanies
    | ReceiveProductCompanies
    | InvalidRequest
    | UpdateProductCompany
    | AddProductCompany
    | DeleteProductCompany;
