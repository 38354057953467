import styled from "styled-components";
import { breakpoint } from "../../theme/themeProvider";

export const ContainerBody = styled.div`
    padding: 15px;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    position: relative;

    .close-product {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 3;
        background: ${(props) => props.theme.palette.background};

        &:hover {
            background: ${(props) => props.theme.palette.backgroundDark};
        }
    }

    ${breakpoint("md")`
        padding: 20px 40px;
    `}

    .edit {
        background: ${(props) => props.theme.palette.backgroundDark};
        border-radius: 50px;
    }

    h1 {
        margin: 0px;
        margin-top: 1rem;
    }

    .mb-1 {
        margin-bottom: 10px;
    }

    .dates {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        span {
            color: ${(props) => props.theme.palette.textLight};
            font-size: 0.9rem;
        }
    }
    .wahioicon-arrow-left {
        color: gray;
    }
`;

export const ProductGlobalContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border: 0px;
    padding-bottom: 2rem;
    margin-bottom: 1rem;
    .light {
        font-size: 0.9rem;
        color: ${(props) => props.theme.palette.textLight};
    }
    ${breakpoint("md")`
    margin-top: 15px;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    `}

    ${breakpoint("sm")`
        padding: 20px 25px;
        padding-top: 10px;
    `}
`;

export const HeaderImage = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
    padding-top: 12px;
    padding-bottom: 0px;
    margin-right: 0px;
    align-items: center;
    ${breakpoint("lg")`
    margin-right: 20px;
    align-items: baseline;
`}
`;

export const ImageContainer = styled.div`
    height: 400px;
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
    max-width: 450px;
    margin: auto;

    ${breakpoint("lg")`
    height: 400px;
    width: 400px;
  
       
    `}

    ${breakpoint("xl")`
        height: 400px;
        width: 400px;
    `}

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
`;

export const ImageListContainer = styled.div`
    display: flex;
    margin-top: 20px;

    max-width: 100%;
    overflow: auto;
    ${breakpoint("lg")`
        max-width: 250px;
    `}

    ${breakpoint("xl")`
        max-width: 400px;
    `}

    .img-body {
        border: 2px solid transparent;
        border-radius: 8px;
        width: 50px;
        min-width: 50px;
        height: 50px;
        overflow: hidden;
        margin-right: 10px;
        margin-bottom: 10px;
        cursor: pointer;
        &.active {
            border-color: ${(props) => props.theme.palette.backgroundDark};
        }
        &:hover {
            border-color: ${(props) => props.theme.palette.backgroundDark};
        }
    }
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
`;

export const UlCustom = styled.ul`
    margin: 0px;
    padding: 0;
    margin-bottom: 15px;
    color: ${(props) => props.theme.palette.textLight};
    .title {
        color: ${(props) => props.theme.palette.text};
    }
`;

export const FlexContainerWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;

    .labelpair {
        margin-right: 15px;
        margin-bottom: 10px;
    }
`;

export const TaxesContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    color: ${(props) => props.theme.palette.textLight};
    ul {
        padding: 0px;
        margin: 0px;
        margin-top: 4px;
        li {
            list-style: circle;
        }
        color: ${(props) => props.theme.palette.text};
    }
`;

export const TaxItemView = styled.li`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    list-style: circle;
    .header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        button {
            width: 23px;
            height: 23px;
            min-width: 20px;
        }
    }

    .info {
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        background: ${(props) => props.theme.palette.backgroundLight};
        margin: 9px;
        border-radius: 10px;
        padding: 10px 15px;
        font-size: 0.9rem;
        color: ${(props) => props.theme.palette.textLight};
        display: flex;
        flex-direction: column;
        transition: padding 0.4s;
        &.hide {
            display: none;
            padding: 0px;
            transition: padding 0.4s;
        }
    }
`;

export const DetailsHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 6px;
    button {
        font-size: 0.9rem;
        padding: 4px 13px;
    }
    .title {
        margin: 10px 0px;
        margin-right: 15px;
    }
`;

export const DescriptionContainer = styled.div`
    .title {
        font-weight: 500;
        margin: 0px;
        margin-bottom: 5px;
        display: block;
        font-size: 1rem;
    }
    .content {
        margin: 0px;
    }
`;

export const DetailContainer = styled.div`
    width: 100%;
    .header {
        display: flex;
        .header-title {
            width: 100%;
            display: flex;
            flex-direction: column;
        }
        .title-container {
            margin-bottom: 3px;
        }
    }
    .wahioicon-arrow-left {
        color: grey;
    }
`;

export const BannerSliderContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
    padding-top: 12px;
    padding-bottom: 0px;
    margin-right: 0px;
    align-items: center;

    .swiper-container {
        border-radius: 11px;
        width: 100%;
        max-width: 500px;
    }
    .swiper-wrapper {
        width: 100%;
        height: auto;
        max-height: 480px;
    }

    ${breakpoint("md")`
    .swiper-wrapper {
        width: 400px;
        height: 400px;
    }
    `}

    iframe {
        width: 100%;
        height: 100%;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .list-images {
        .swiper-slide {
            width: 100% !important;
            height: auto;
        }
    }

    .thumbs {
        margin-top: 15px;
        max-width: 300px;
        ${breakpoint("md")`
        max-width: auto;
        `}
        .swiper-container {
            border-radius: 0px;
        }
        .swiper-wrapper {
            height: auto;
        }
        .swiper-slide {
            border: 1px solid ${(props) => props.theme.palette.backgroundDark};
            height: 65px;
            width: 65px;
            border-radius: 8px;
            margin: 4px;
            overflow: hidden;
        }
        .swiper-slide-active {
            border: 1px solid ${(props) => props.theme.palette.primary};
        }
    }
`;
